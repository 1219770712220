import { Component, OnInit, ViewChild, ElementRef, HostListener, TemplateRef, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { authManager } from '../../service/authManager';
import { ApiService } from '../../service/apiCall.service'
import { ToastrService } from 'ngx-toastr';
import { MenuItem } from 'primeng/primeng';
import { AuthService } from '../../service/auth.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SaveFinalDataService } from '../../service/save-final-data.service'
import { Subscription } from 'rxjs';
import { PublishAllAlertService } from '../../service/PublishAllAlert.service'
import HttpStatusCode from '../../modules/shared/Models/HttpStatusCode';

@Component({
  selector: 'ngbd-publish-modal',
  template: `
    <div id="myModal2" class="modal-header">
     <label style="margin-right:400px">Alert</label>
     <button type="button"  class="glyphicon glyphicon-remove" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
     </button>    
    </div>
    <div class="modal-body">                
    <h6 class="modal-title">Are you sure you want to Publish?</h6>
    </div>
    <div class="modal-footer">
    <button type="button" class="btn btn-success" routerLinkActive="active" (click)="clickFilter()">Yes</button>      
      <button type="button" class="btn btn-danger" routerLinkActive="active" (click)="activeModal.dismiss('Cancel')">No</button>
    </div> 
  `,
})


export class NgbdPublishModal {
  @Input() name;

  constructor(public activeModal: NgbActiveModal,
    private apiService: ApiService,
    private _publishService: PublishAllAlertService,
    private toastr: ToastrService) {
    this._publishService.listen().subscribe((t: any) => {
      if (t == 'ClosePopup') {
        activeModal.dismiss('Yes');
      }
    })
  }
  clickFilter() {
    this._publishService.filter('Yes');
  }
}

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {
  @Input() isAdmin;
  @Input() device;
  @Input() isSignOut;
  @Input() displayName;
  isShow = false;
  items: MenuItem[];
  showLogoutDialogPopup: boolean = false;
  publish: Subscription;
  constructor(private dialog: MatDialog, private authService: AuthService, private modalService: NgbModal, private _publishService: PublishAllAlertService, private AuthManager: authManager, private apiService: ApiService, private toastr: ToastrService) {
    this.publish = this._publishService.listen().subscribe((m: any) => {
      if (m == 'Yes') {
        this.Publish();

      }
    });
  }

  ngOnInit() {

    this.items = [
      {
        label: 'Reporting',
        items: [
          { label: 'Edit Flash Report', routerLink: '/flashreport', routerLinkActiveOptions: "active" },
          { label: 'View Flash Report', routerLink: '/viewflashreport', routerLinkActiveOptions: "active" },
          { label: 'Slate Summary', routerLink: '/slatesummary', routerLinkActiveOptions: "active" },
          { label: 'Media Summary', routerLink: '/mediasummary', routerLinkActiveOptions: "active" },
          { label: 'Flash Summary', routerLink: '/flashsummary', routerLinkActiveOptions: "active" },
          { label: 'Department Summary', routerLink: '/departmentsummary', routerLinkActiveOptions: "active" },
          { label: 'Publish All', command: (onclick) => { this.alertPublish() } }
        ]
      },
      {
        label: 'Security Setup',
        icon: '<i class="fas fa-user-shield"></i>',
        items: [
          { label: 'Manage Users', routerLink: '/adduser', routerLinkActiveOptions: "active" },
          { label: 'Manage Title Permission', routerLink: '/manageusers', routerLinkActiveOptions: "active" },
          { label: 'Manage Group Permission', routerLink: '/managegroups', routerLinkActiveOptions: "active" }
        ]
      },
      // {
      //   label: 'Adhoc Reports',
      //   icon: '<i class="fas fa-user-shield"></i>',
      //   items: [
      //     { label: 'Flash Report', routerLink: '/flash-report/adhoc-dashboard', routerLinkActiveOptions: "active" },
      //     { label: 'Flash Summary', routerLink: '/adhocflashsummaryreport', routerLinkActiveOptions: "active" },
      //     { label: 'Department Summary', routerLink: '/adhocdepartmentsummaryreport', routerLinkActiveOptions: "active" },  

      //   ]
      // },
      {
        label: 'View Reports',
        routerLink: '/viewreports', routerLinkActiveOptions: "active",
        icon: '<i class="fas fa-user- shield"></i>'
      }
    ];

  }

  showLogoutPopup() {
    this.showLogoutDialogPopup = true;
  }
  logout() {
    this.AuthManager.logoutUser();
  }

  alertPublish() {
    const modalRef = this.modalService.open(NgbdPublishModal, { centered: true });
  }

  Publish() {
    this._publishService.filter('ClosePopup');
    this.isShow = true;
    this.apiService.publishAll().subscribe(x => {
      if (x.statusCode == HttpStatusCode.OK)
        this.apiService.publishSlateNMediaFiles().subscribe(x => {
          this.toastr.success('All Reports Published Successfully');

         });
      else
        this.toastr.error(x.message);
    },
      err => {
        this.toastr.error('Something went wrong!');
      },
      () => {
        this.isShow = false;
      }
    );
  }
}

