import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Directive({
  selector: '[NumericScrollLock]'
})
export class NumericScrollLockDirective {

  // To verify, if it is valid mathematical formula or not
  private regexFinalCheck: RegExp = new RegExp(/^\-?[0-9]+(([-+/*][0-9]+)?([.,][0-9]+)?)*?$/gm);
  // To verify, if it is valid mathematical formula or not
  private regexInputCheck: RegExp = new RegExp(/[0-9.(/*)+-]/);

  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Delete'];

  constructor(private el: ElementRef, private ngModel: NgModel, private renderer: Renderer2, private toastr: ToastrService) {

  }

  @HostListener('wheel', ['$event'])
  @HostListener('scroll', ['$event'])
  onWheel(event: Event) {
    event.preventDefault();
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return true;
    }
    else if (event.key && !this.regexInputCheck.test(event.key)) {
      event.stopPropagation();
      event.preventDefault();
      return false;
    }
  }
  @HostListener('blur')
  // @HostListener('input')
  @HostListener('change', ['$event'])
  onChange(event) {
    let ele = this.el.nativeElement as HTMLInputElement;

    let inputText = this.ngModel.model;
    let value;
   
    if (ele.style.background)
    this.renderer.removeStyle(ele, "background");

    try {
      value = eval(inputText);
      value=Math.round(value);
      if(value==="NaN" || value===NaN || isNaN(value) || value===undefined || value==='undefined')
      {
        this.renderer.setStyle(ele, "background", "red");
		this.toastr.error("Not a valid value!");
        ele.focus();
        return;
      }
    }
    catch (error) {
      if(value==="NaN" || value===NaN || isNaN(value) || value===undefined || value==='undefined') {
        this.renderer.setStyle(ele, "background", "red");
        this.toastr.error("Not a valid value!");
        ele.focus();
        return;
      }
    }
    this.ngModel.update.emit(value);

  }
  @HostListener('paste', ['$event']) onPaste(event) {
    // Don't allow pasted text that contains non-numerics
    const pastedText = (event.originalEvent || event).clipboardData.getData('text/plain');
    if (pastedText) {
      if (!this.regexFinalCheck.test(pastedText)) {
        event.stopPropagation();
        event.preventDefault();
      }
    }
  }
  @HostListener('click', ['$event']) onClick(event) {

    let ele = this.el.nativeElement as HTMLInputElement;
    ele.select();
  }
}
