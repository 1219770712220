import { Component, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../service/apiCall.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ngbd-modal',
  template: `
    <div id="myModal" class="modal-header" style="height:50px;margin-left:425px">     
      <button type="button"  class="glyphicon glyphicon-remove" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      </button>      
      
    </div>
    <div class="modal-body">                
    <h6 class="modal-title">Click Save to save the users to respective titles and cancel to go back.</h6>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-success" routerLinkActive="active" (click)="activeModal.dismiss('Save')">Save</button>
      <button type="button" class="btn btn-danger" routerLinkActive="active" (click)="activeModal.dismiss('Cancel')">Cancel</button>
    </div> 
  `
})

export class NgbdModalContent2 {
  @Input() name;

  constructor(public activeModal: NgbActiveModal) { }
}

@Component({
  selector: 'manageusers',
  templateUrl: './manageusers.component.html',
  styleUrls: ['./manageusers.component.scss']
})
export class ManageUsersComponent {
  @Input() name;
  searchValue = '';
  users: any;
  titles: any;
  finalResult: any;
  titlePermission: any;
  validationMsg: any = [];
  userTitles: any;
  showErrorDialogPopup: boolean = false;
  clearSearch() {
    this.searchValue = '';
  }
  constructor(private modalService: NgbModal, private apiService: ApiService, private toastr: ToastrService) {

    this.getUsers();

  }
  save() {
    //
    this.validationMsg = [];

    if (this.finalResult === undefined) {
      this.validationMsg.push(' Please Select atleast One Title ');
    } else {
      const res = this.finalResult.find(x => x.activeFlag === 1);
      if (res === undefined && this.titlePermission === undefined) {
        this.validationMsg.push(' Please Select atleast One Title ');
      }
    }

    if (this.validationMsg.length > 0) {
      return true;
    }
    this.saveTitlePermission();
  }
  getUsers() {

    this.apiService.getUsers().subscribe(res => {
      this.users = res;
      this.getTitles();
    },
    err => {      
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }

  revert() {
    this.getTitles();
  }
  getTitles() {

    this.apiService.getTitles().subscribe(res => {
      this.titles = res;
      this.getTitlePermission();

    },
    err => {      
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }
  usertitle(val: any) {

    const dd = val;

  }
  trackElement(val: any) {

    const ddd = val;

  }
  displayCondition(titleUserId, userId, tuser) {

    if (titleUserId === userId) {
      tuser.show = true;
      return true;
    }
  }
  displayInnerCondition(titleUserId, userId, tuser: any, tituserlist: any) {

    if (titleUserId === userId) {
      tuser.show = true;
    } else {
      tuser.show = false;
    }
    const res = tituserlist.filter(x => x.show === true && x.userId === userId);
    if (res.length === 0) {
      tuser.show = false;
      return true;
    } else {
      tuser.show = true;
      return true;
    }
  }
  getTitlePermission() {
    this.apiService.getTitlesPermission().subscribe(res => {
      this.titlePermission = res;
      this.userTitles = [];
      this.titles.forEach(element => {
        let dd = this.titlePermission.filter(x => x.titleId === element.titleId && x.activeFlag === 1);

        element['users'] = dd;

      });

    },
    err => {      
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }
  saveTitlePermission() {
    this.apiService.saveTitlePermission(this.finalResult).subscribe(res => {
      this.toastr.success('Title Permission Added Successfully');
    },
    err => {      
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }
  getTitleUserID(title: any, user: any, event: any) {
    const status = event.target.checked === true ? 1 : 0;
    if (this.finalResult === undefined) {

      this.finalResult = [{
        userId: user.userId,
        titleId: title.titleId,
        activeFlag: status
      }];
    } else {

      const res = this.finalResult.find(x => x.userId === user.userId && x.titleId === title.titleId);
      if (res === undefined) {
        this.finalResult.push({
          userId: user.userId,
          titleId: title.titleId,
          activeFlag: status
        });
      } else {
        const index = this.finalResult.findIndex(x => x.userId === user.userId && x.titleId === title.titleId);
        this.finalResult[index]['activeFlag'] = status;
      }

    }

  }
}
