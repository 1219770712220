import { Injectable, Inject } from '@angular/core';
import { Http, Response, Headers, RequestOptions,URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import {environment} from '../../environments/environment';
import * as AWS from 'aws-sdk';
import {CognitoIdentity, Credentials} from 'aws-sdk';
import { HttpParams } from '@angular/common/http';



@Injectable()
export class AuthService {
    private authUrl:string;
    private access_token:string;
    private token: any;

    identityPool:string = environment.identitypool;
    region: 'us-west-2';

constructor( @Inject(Http) private http: Http) {
    }
      
    getUserDetails(token:string){        
           
          let headers = new Headers();
          headers.set('Content-Type', 'application/json')       
            let options = new RequestOptions({ headers: headers });
        return this.http.post(environment.apiUrl+'Report/userdetail', JSON.stringify(token), options);        
    }

    authenticateIdentityPool(id_token)
    {        
        // Instantiate the CognitoIdentity
        const cognitoIdentity = new CognitoIdentity({
            region: 'us-west-2'
        });        
        // Parameters for getId()
        var getIdParams = {
            IdentityPoolId: this.identityPool,
            Logins:{
                'fss.inbcu.com/fss': id_token
            }            
        };        
        // Get the Cognito ID
        return cognitoIdentity.getId(getIdParams).promise().then((cognitoId) =>
        {            
            // Parameters for getCredentialsForIdentity()
            const getCredsForIdParams = {
                IdentityId: cognitoId.IdentityId,
                Logins:{
                    'fss.inbcu.com/fss': id_token
                }              
            };            
            return cognitoIdentity.getCredentialsForIdentity(getCredsForIdParams).promise();
        }).then ((cognitoCredentials) =>
        {                                    
            return cognitoCredentials;
        }).catch((error) =>
        {
            console.log(error);
            return false;
        });

    }   
    
}


function handleError(error: any) {
// log error
// could be something more sofisticated
let errorMsg = error.message || ``
console.error(errorMsg);

// throw an application level error
return Observable.throw(errorMsg);
} 
