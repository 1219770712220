import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { AuthService } from './auth.service';
import { AwsService } from '../service/aws.service';
import { environment } from '../../environments/environment';
import { AwsCommonService } from '../service/aws-common.service';
import { takeUntil } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

declare var $: any;

@Injectable({
    providedIn: 'root'
})

export class authManager implements CanActivate {
    accessToken: string;
    idToken: string;
    tokenType: string;
    storedAccessTokenIssueDate = sessionStorage.getItem('access_token_issuedate');
    accessTokenIssueDate = new Date(this.storedAccessTokenIssueDate);
    accessTokenTimeoutDate: Date = this.accessTokenIssueDate;
    tokenTimeoutMins = 120;
    public dynSSOId = '-1';
    currentDate = new Date();
    state_key: any;
    nonce_key: any;
    code: string;
    from: string;
    emailurl: string;    
    isValidUser: boolean = false;
    destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private authService: AuthService, private awsService: AwsService, private http: HttpClient, private awsCommonService: AwsCommonService,
        private cookieService: CookieService) {        
        this.accessToken = this.getUrlVars('#')['access_token'];
        this.tokenType = this.getUrlVars('#')['token_type'];
        this.idToken = this.getUrlVars('#')['id_token'];
        this.code = this.getUrlVars('#')['code'];
        this.from = this.getUrlVars('?')['from'];       
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {        
        if (!(window.location.href.indexOf('localhost') > 0)) {
            if (this.idToken !== null && this.idToken !== undefined) {                                
                if (!sessionStorage.getItem('sessionToken')) {
                    sessionStorage.setItem('access_token', this.accessToken);
                    sessionStorage.setItem('id_token', this.idToken);
                    return this.awsService.authenticatefss(environment.authURL, sessionStorage.getItem('id_token')).then(result => {                                                 
                       console.log(result);
                        this.awsCommonService.postRequest(`${environment.apiUrl}Report/userdetail`, 'POST', sessionStorage.getItem('access_token')).pipe(takeUntil(this.destroy$)).subscribe((userresult: any) => {                                                        
                            userresult = JSON.parse(userresult);                            
                            if (userresult == "ERROR") {
                                //this.router.navigate(['/error']);
                                return false;
                            } else {
                                  
                                sessionStorage.setItem('SSO', userresult['access_token']['uid']);
                                sessionStorage.setItem('FirstName', userresult['access_token']['FirstName']);
                                this.isUserAdmin();
                                return true;

                                // else
                                // {
                                //     this.router.navigate(['/autherror']);
                                // }
                            }
                        });
                    }).catch((error) => {
                        console.log(error); //this.router.navigate(['/error']); 
                    });
                } else {
                    return true;
                }
            } else if (sessionStorage.getItem('access_token') && !sessionStorage.getItem('sessionToken')) {                
                return this.awsService.authenticatefss(environment.authURL, sessionStorage.getItem('id_token')).then(result => {                                         
                    this.awsCommonService.postRequest(`${environment.apiUrl}Report/userdetail`, 'POST', sessionStorage.getItem('access_token')).pipe(takeUntil(this.destroy$)).subscribe((userresult: any) => {                        
                        userresult = JSON.parse(userresult);
                        if (userresult == 1) {
                            //this.router.navigate(['/autherror']);
                            return false;
                        } else {
                            sessionStorage.setItem('SSO', userresult['access_token']['uid']);
                            sessionStorage.setItem('FirstName', userresult['access_token']['FirstName']);
                        }
                    });
                }).catch((error) => { console.log(error); this.router.navigate(['/error']); });
            } else if (sessionStorage.getItem('sessionToken') && !sessionStorage.getItem('SSO')) {                                
                this.awsCommonService.postRequest(`${environment.apiUrl}Report/userdetail`, 'POST', sessionStorage.getItem('access_token')).pipe(takeUntil(this.destroy$)).subscribe((userresult: any) => {                      
                    userresult = JSON.parse(userresult);
                    if (userresult == "ERROR") {
                        //this.router.navigate(['/autherror']);
                        return false;
                    } else {
                        sessionStorage.setItem('SSO', userresult['access_token']['uid']);
                        sessionStorage.setItem('FirstName', userresult['access_token']['FirstName']);
                    }
                });
            } else if (sessionStorage.getItem('sessionToken') && sessionStorage.getItem('SSO')) {                                  
                if((sessionStorage.getItem('IsAdminAccess') == '0' && !(window.location.href.indexOf('viewreports') > 0)) 
                ) {
                    this.router.navigate(['/autherror']);
                    return false;
                }
                else if (sessionStorage.getItem('IsAdminAccess') == 'null')
                {
                    this.router.navigate(['/autherror']);
                    return false;
                }
                else {
                    return true;
                }
            } else if (!sessionStorage.getItem('access_token')) {
                  
                sessionStorage.setItem('referenceUrl', window.location.href);                
                window.location.replace('https://' + environment.authURL + '/as/authorization.oauth2?client_id=' + environment.clientId + '&response_type=token id_token' + '&redirect_uri=' + environment.appurl + '&scope=email openid MktFlashReportExtAdmMFAEnabled&nonce=123&access_token_manager_id=GroupReferenceTokensAP');
            } else {
                return new Observable<any>();
            }
        }
    }
    getUrlVars(code) {        
        var vars = [];
        var hash;
        var hashes = window.location.href.slice(window.location.href.indexOf(code) + 1).split('&'); 
        if(window.location.href == environment.appurl + "viewreports?from=email")
        {        
        this.cookieService.set( 'emailURL', environment.appurl + "viewreports?from=email" );
        } 
        else if(window.location.href == environment.appurl + "publishreportstest")
        {
            this.cookieService.set('testURL', environment.appurl + "publishreportstest");
        }
        for (let i = 0; i < hashes.length; i++) {
            hash = hashes[i].split('=');
            vars.push(hash[0]);
            vars[hash[0]] = hash[1];
        }
        return vars;
    }

    logoutUser() {
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = environment.logoutUrl;
    }

    isUserAdmin() {          
        return this.awsCommonService.getApiRequest(`${environment.apiUrl}Report/User/IsAdmin/` + sessionStorage.getItem('SSO'), 'GET').pipe(takeUntil(this.destroy$)).subscribe((userresult: any) => {            
            sessionStorage.setItem('IsAdminAccess', userresult);
            if (sessionStorage.getItem('IsAdminAccess') == '1') { 
                if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
                    this.router.navigate(['viewreports']);
                    sessionStorage.setItem('MobileView', '0');
                   }   
                   else if (this.cookieService.get('emailURL') == environment.appurl + "viewreports?from=email")
                   {
                    this.router.navigate(['viewreports']);                    
                   }
                   else if (this.cookieService.get('testURL') == environment.appurl + "publishreportstest")
                   {
                    this.router.navigate(['publishreportstest']);                    
                   }
                   else{                                                  
                this.router.navigate(['flashreport']); 
                sessionStorage.setItem('MobileView', '1'); 
                   }               
            }
            else if (sessionStorage.getItem('IsAdminAccess') == '0') {                                       
                this.router.navigate(['viewreports']);                                     
            }  
            else
            {
                this.router.navigate(['autherror']);
            }          
        });
    }
  
}
