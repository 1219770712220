import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import { CognitoIdentity, Credentials,STS } from 'aws-sdk';
import * as aws4 from 'ngx-aws4';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AwsService {

  token: any;
  userData: any;

  constructor(private http: HttpClient) {}

  authenticatefss(authURL: string, idToken: string) {          
    const cognitoIdentity = new CognitoIdentity({
      region: 'us-west-2'
    });
    var sts = new STS();
    // Parameters for getId();
    const getIdParams = {
      IdentityPoolId: environment.identitypool,
      Logins: {
        'fss.inbcu.com/fss': idToken,
        /* '<IdentityProviderName>': ... */
      },
    };
    // debugger
    
    // Get the Cognito ID
    return cognitoIdentity.getId(getIdParams).promise()
      .then((cognitoId) => {          
        var params = {
          IdentityId: cognitoId.IdentityId,
          Logins: {
            'fss.inbcu.com/fss': idToken,
            /* '<IdentityProviderName>': ... */
          },         
        };

        return cognitoIdentity.getOpenIdToken(params).promise()
      }).then((cognito) => {                  
        var params = {
          IdentityId: cognito.IdentityId,
          Logins: {
            'fss.inbcu.com/fss': cognito.Token,
            /* '<IdentityProviderName>': ... */
          },
        };  
        var param = {
          DurationSeconds: 14400,                     
          RoleArn: environment.CognitoRoleARN, 
          RoleSessionName: "MFR", 
          WebIdentityToken: cognito.Token
         };
        //return cognitoId;
        
        return sts.assumeRoleWithWebIdentity(param).promise()
      }).then((sts) => {                    
          console.log(sts);  
          sessionStorage.setItem('secretAccessKey', sts.Credentials.SecretAccessKey);
          sessionStorage.setItem('accessKeyId', sts.Credentials.AccessKeyId);
          sessionStorage.setItem('sessionToken', sts.Credentials.SessionToken);
          return sts;          
        });  
              
      //   return cognitoIdentity.getCredentialsForIdentity(params).promise()
      // }).then((cognitoCredentials) => {           
      //   const awsCredentials = new Credentials({accessKeyId: cognitoCredentials.Credentials.AccessKeyId, secretAccessKey: cognitoCredentials.Credentials.SecretKey, sessionToken: cognitoCredentials.Credentials.SessionToken });
      //   awsCredentials.expireTime = cognitoCredentials.Credentials.Expiration;        
      //   awsCredentials.expired = false;

      //   return awsCredentials;
      // });
  }
}
