import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { AwsSignonService } from './aws-signon.service';
import { Observable, Subject } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AwsCommonService {
    public httpOptions: any;

    constructor(private httpClient: HttpClient, private awsSignonService: AwsSignonService, private authService: AuthService) {
        this.httpOptions = {
          headers: new HttpHeaders().
            set('content-type', 'application/json').
            set('Accept', 'application/json').
            set('Access-Control-Allow-Origin', '*')
        };
      }

      public postRequest(url: any, method: any, options): Observable<any>  {
          if(!sessionStorage.getItem('access_token') && !sessionStorage.getItem('sessionToken')) {
            this.logoutUser();
          }
          else {
            return this.awsSignonService.apiRequest(url, method, options).pipe(
              retry(3)
            )
          }
     }

     public getApiRequest(url: any , method: any, params?,options?): Observable<any> {         
        if(!sessionStorage.getItem('access_token') && !sessionStorage.getItem('sessionToken')) {
          this.logoutUser();
        }
        else {          
          return this.awsSignonService.apiGetRequest(url, method, params,options).pipe(
            retry(3)
          )
        }
      
    }

     logoutUser() {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = environment.logoutUrl;
  }
}