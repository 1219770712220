import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpModule } from '@angular/http';

import { MaterialModule } from './material.module';
import { PrimeNgModule } from './primeng.module';
import { NGXModule } from './ngx.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

//Directives
import {FixedHeaderDirective} from '../../directives/cdkFixedHeader';
import {NumericScrollLockDirective} from '../../directives/numeric-scroll-lock.directive';
import {PAddRowDirective} from '../../directives/p-add-row.directive';

//Pipes
import {MathematicalFormulaPipe} from '../../pipes/mathematical-formula.pipe';

//Models
import HttpStatusCode  from './Models/HttpStatusCode';

const THIRD_MODULES = [
  MaterialModule,
  FlexLayoutModule,
  NgbModule,
  PrimeNgModule,
  NGXModule
];
const COMPONENTS = [];
const Models = [HttpStatusCode];
const COMPONENTS_DYNAMIC = [];
const DIRECTIVES = [FixedHeaderDirective,NumericScrollLockDirective,PAddRowDirective];
const PIPES = [MathematicalFormulaPipe];

@NgModule({
 declarations: [...COMPONENTS, ...DIRECTIVES, ...PIPES, ...COMPONENTS_DYNAMIC ] ,
 imports: [CommonModule, FormsModule, ReactiveFormsModule , 
  FormsModule,
  HttpClientModule,
  HttpModule,
  ReactiveFormsModule,...THIRD_MODULES],
  
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ...THIRD_MODULES,
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES
  ],
  providers: [
    FormBuilder, DatePipe ],
  entryComponents: COMPONENTS_DYNAMIC,
})
export class SharedModule {}
