import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../service/apiCall.service'

@Component({
  selector: 'ngbd-modal-content',
  template: `
    <div id="myModal" class="modal-header" style="height:50px">    
    <label style="margin-left:-450px !important;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; New Group</label>  
      <button type="button" style="margin-right: -80%;" class="glyphicon glyphicon-remove" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      </button>    
    </div>
    <div class="modal-body">
    <label for="Group">Group Name &nbsp; </label>                
    <input class="form-control" placeholder="Groupname">
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary">Create</button>
    </div>
  `
})


export class NgbdModalContent {
  @Input() name;

  constructor(public activeModal: NgbActiveModal) { }
}


@Component({
  selector: 'managegroups',
  templateUrl: './managegroups.component.html',
  styleUrls: ['./managegroups.component.scss']
})
export class ManageGroupsComponent {
  closeResult: string;
  groups: any;
  showErrorDialogPopup: boolean = false;
  constructor(private router: Router, private modalService: NgbModal, private apiService: ApiService) {
    this.getGroup();
  }
  getGroup() {
    this.apiService.getGroup().subscribe(x => {
      this.groups = x;

    },
    err => {      
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }
  gridClick(row: any) {
    this.router.navigate(['/popupgroups'], { queryParams: { groupName: row.groupName, groupId: row.groupId } })

  }
  open() {
    const modalRef = this.modalService.open(NgbdModalContent, { centered: true });
  }
}
