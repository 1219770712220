import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgModule } from '@angular/core';
import { SharedModule} from './modules/shared/shared.module';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { Ng5SliderModule } from 'ng5-slider';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { AngularDualListBoxModule } from 'angular-dual-listbox';

import { AgGridModule } from "ag-grid-angular";

import { RouterModule } from '@angular/router';
import { routes } from './app.route';

import { AppComponent } from './app.component';
import { AppFooterComponent } from './components/app-footer/app-footer.component';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import {GenredashboardResultComponent} from '../app/modules/genredashboardResult/genredashboardResult.component';
import { AddUserComponent } from '../app/modules/adduser/adduser.component';
import { ManageUsersComponent} from '../app/modules/manageusers/manageusers.component';
import { ManageGroupsComponent} from '../app/modules/managegroups/managegroups.component';
import { PopupGroupsComponent} from '../app/modules/popupgroups/popupgroups.component';
import { ReportGenerationComponent } from '../app/modules/Reportgeneration/reportgeneration.component'
import { NgbdModalContent } from '../app/modules/managegroups/managegroups.component'
import { NgbdModal } from '../app/modules/genredashboardResult/genredashboardResult.component'
import { NgbdModalContent1 } from '../app/modules/Reportgeneration/reportgeneration.component'
import { NgbdModalContent2 } from '../app/modules/manageusers/manageusers.component'
import { NgbdModalContentMediaSummary } from '../app/modules/mediasummaryreport/mediasummaryreport.component';
import { NgbdModalTitleSummary } from '../app/modules/executivereport/executivereport.component';
import { NgbdPublishModal } from '../app/components/app-header/app-header.component';
import { AddUserClickComponent } from '../app/modules/adduserclick/adduserclick.component'
import { MediaSummaryReportComponent } from '../app/modules/mediasummaryreport/mediasummaryreport.component'
import { DepartmentSummaryReportComponent } from '../app/modules/departmentsummary/departmentsummary.component';
import { DomestictheatricalComponent,NgbdModalContentSlateSummary } from './modules/domestictheatrical/domestictheatrical.component';
import { NgbdModalDepartmentSummary } from './modules/departmentsummary/departmentsummary.component';
import { ExecutivereportComponent } from './modules/executivereport/executivereport.component';
import { ApiService } from '../app/service/apiCall.service';
import { SaveFinalDataService } from './service/save-final-data.service';
import { SaveFinalDataGenerateService } from './service/save-final-data.generate.service';
import { PublishAllAlertService } from './service/PublishAllAlert.service';
import { ExportService } from './service/export.service';
import { PublishDataService } from './service/publish-data.service';
import { AuthService } from './service/auth.service';
import { authManager } from './service/authManager';
import { AwsCommonService } from './service/aws-common.service';
import { AwsSignonService } from './service/aws-signon.service';
import { AwsService } from './service/aws.service';
import { SchemacategoryComponent } from './modules/schemacategory/schemacategory.component';
import { CommaSeperatedStr } from './modules/schemacategory/commaSeperate-pipe';
import {ViewAllReportComponent} from './modules/viewallreport/viewallreport.component';
import { UserAuthErrorComponent } from './modules/loginerror/user.autherror.component';
import { PublishReportTestComponent } from './modules/publishreportstest/publishreportstest.component';



@NgModule({
  declarations: [
    AppComponent,
    AppFooterComponent,
    AppHeaderComponent,
     GenredashboardResultComponent,
     AddUserComponent,
     ManageGroupsComponent,
     ManageUsersComponent,
     PopupGroupsComponent,
     ReportGenerationComponent,
     NgbdModalContent,
     NgbdModal,
     NgbdModalContent1,
     NgbdModalContent2,
     NgbdModalContentMediaSummary,
     NgbdModalContentSlateSummary,
     NgbdModalTitleSummary,
     NgbdModalDepartmentSummary,
  	 NgbdPublishModal,
     AddUserClickComponent,
     DomestictheatricalComponent,
     ExecutivereportComponent,
     MediaSummaryReportComponent,
     DepartmentSummaryReportComponent,
     SchemacategoryComponent,     
     CommaSeperatedStr,
     ViewAllReportComponent,
     UserAuthErrorComponent,
     PublishReportTestComponent,
 ],
  imports: [    
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' }),
    SharedModule,
    NgMultiSelectDropDownModule.forRoot(),
    Ng5SliderModule,
    Ng2SmartTableModule,
    AngularDualListBoxModule,
    AgGridModule.withComponents([]),
   
  ],
  exports: [RouterModule],
  providers: [       
        ApiService,
        SaveFinalDataService,
        ExportService,
        SaveFinalDataGenerateService,
		    PublishAllAlertService,
        PublishDataService,
        AuthService,
        authManager,
        AwsCommonService,
        AwsSignonService,
        AwsService,
        GenredashboardResultComponent,
        ExecutivereportComponent,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    NgbdModalContent,
    NgbdModal,
	  NgbdPublishModal,
    NgbdModalContent1,
    NgbdModalContent2,
    NgbdModalContentMediaSummary,
    NgbdModalContentSlateSummary,
    NgbdModalTitleSummary,
    NgbdModalDepartmentSummary

  ],
})
export class AppModule { }
