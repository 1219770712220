import { Component, Input, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Http } from '@angular/http';
import { ApiService } from '../../service/apiCall.service'
import { SaveFinalDataService } from '../../service/save-final-data.service'
import { environment } from '../../../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { PublishDataService } from '../../service/publish-data.service';
import * as FileSaver from 'file-saver';
import { AwsCommonService } from '../../service/aws-common.service';

declare const $;

@Component({
  selector: 'ngbd-modal-department',
  template: `
    <div id="myModal3" class="modal-header">
     <label style="margin-right:250px">Proceed to Generate report</label>
     <button type="button"  class="glyphicon glyphicon-remove" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
     </button>    
    </div>
    <div class="modal-body">                
    <h6 class="modal-title">Click save to save the report and cancel to go back.</h6>
    </div>
    <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="clickFilter1()">Save</button>         
    <button type="button" class="btn btn-danger" routerLinkActive="active" (click)="activeModal.dismiss('Cancel')">Cancel</button>
    </div> 
  `
})

export class NgbdModalDepartmentSummary {
  @Input() name;

  constructor(public activeModal: NgbActiveModal,
    private apiService: ApiService,
    private savedepartmentService: SaveFinalDataService,
    private publishdepartmentService: PublishDataService,
    private toastr: ToastrService) {
    this.savedepartmentService.listen().subscribe((m: any) => {
      if (m == 'ClosePopup') {
        activeModal.dismiss('Save');
        this.toastr.clear();
      }
    })
    this.publishdepartmentService.listen().subscribe((m: any) => {
      if (m == 'ClosePopup') {
        activeModal.dismiss('Publish');
        this.toastr.clear();
      }
    })
  }

  publish() {
    this.publishdepartmentService.filter('Publish');
  }

  clickFilter1() {
    this.savedepartmentService.filter('Save');
  }
}

@Component({
  selector: 'app-departmentsummary',
  templateUrl: './departmentsummary.component.html',
  styleUrls: ['./departmentsummary.component.scss']
})
export class DepartmentSummaryReportComponent implements OnInit {

  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    this.changeHeight();
  }

  @HostListener('window:resize', ['$event'])
  resize(event) {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    this.changeHeight();
  }

  changeHeight() {
    if (this.innerHeight <= 568) {
      this.tableheight = '200px';

    } else if (this.innerHeight > 569 && this.innerHeight <= 667) {
      this.tableheight = '280px'

    } else if (this.innerHeight > 668 && this.innerHeight <= 759) {
      this.tableheight = '270px'

    } else if (this.innerHeight > 760 && this.innerHeight <= 812) {
      this.tableheight = '340px'

    } else if (this.innerHeight > 813 && this.innerHeight <= 896) {
      this.tableheight = '380px'

    } else if (this.innerHeight > 897 && this.innerHeight <= 1100) {
      this.tableheight = '400px'

    } else if (this.innerHeight > 1101) {
      this.tableheight = '400px'

    } else {
      this.tableheight = '350px'
    }
  }

  innerWidth: any;
  innerHeight: any;
  tableheight: string = '350px';
  showErrorDialogPopup: boolean = false;
  departmentValues: any;
  showGrid = false;
  loading: boolean;
  selectedVal: any = '';
  reportValues: any;
  validations: any = [];
  cols: any = [];
  wbsElement: any = '';
  departmentSummary: any;
  isShow = false;


  constructor(private _http: Http, private router: Router, private modalService: NgbModal,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private publishdepartmentService: PublishDataService,
    private toastr: ToastrService,
    private awsCommonService: AwsCommonService) {
  }

  ngOnInit() {

    this.apiService.getDepartment().subscribe(result => {

      this.departmentValues = result;

    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );

    this.loading = true;

    this.cols = [
      { field: 'titleName', header: 'Title' },
      { field: 'groupName', header: 'Description' },
      { field: 'budget', header: 'Plan / Budget Week' },
      { field: 'committedToDate', header: '$ Committed to Date' },
      { field: 'estimationToComplete', header: 'Est to Complete' },
      { field: 'adjustments', header: 'Adjustments' },
      { field: 'ultimate', header: 'Ultimate' },
      { field: 'variance', header: 'Variance' },
      { field: 'comments', header: 'Comments' }

    ];
  }


  open() {
    const modalRef = this.modalService.open(NgbdModalDepartmentSummary, { centered: true });
  }

  searchClick() {
    this.isShow = true;
    this.selectedVal = this.selectedVal.replace('/', "-");

    this.validations = [];
    if (this.selectedVal === 0 || this.selectedVal === "") {
      this.validations.push('Please Select Department');
      this.isShow = false;
    }
    else {

      this.awsCommonService.getApiRequest(environment.departmentreporturl + this.selectedVal, 'GET')
        .subscribe(result => {          
          let title = '';
          result.map((obj)=>
          {
              obj.last = false;
              if((obj.groupName.includes('Total') || obj.groupName.includes('TOTAL'))&& (obj.department.includes('CREATIVE ADVERTISING SUMMARY')|| obj.department.includes('PUBLICITY / PROMOTION SUMMARY')))
              {
                obj.last = true;
              }
              return obj;
          });
          for(let i = 0; i < result.length; i++){
            if(title != result[i]['titleId']){
              if(i!=0)
              {
                result[i-1]['last'] = true;
              }
            }
            if(i==(result.length-1))
              {
                result[i]['last'] = true;
              }
            title = result[i]['titleId'];
          }
          this.reportValues = result;
          this.isShow = false;
          this.saveData();
        },
        err => {
          this.isShow = false;
          this.showErrorDialogPopup = true;          
        },
        () => {
        }
      );
    }
    if (this.validations.length > 0) {
      return;
    }
    this.showGrid = true;
  }

  saveData() {
    this.isShow = true;
    this.departmentSummary = this.reportValues;
    this.apiService.saveDepartmentSummaryReport(this.departmentSummary).subscribe(x => {
      this.isShow = false;
      this.toastr.clear();
    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }
  departmentSave() {

    let activeToast = this.toastr.success('Department Summary Report Saved Successfully');
    sessionStorage.setItem('activeToast', activeToast.toastId + '');
  }
  publishData() {
    this.isShow = true;
    var data = [];
    this.reportValues.forEach(element => {

      data.push(
        {
          titleId: element.titleId,
          titleName: element.titleName,
          adjustments: element.adjustments,
          category: element.category,
          comments: element.comments,
          budget: element.budget,
          committedToDate: element.committedToDate,
          estimationToComplete: element.estimationToComplete,
          groupName: element.groupName,
          groupTotal: element.groupTotal,
          Ultimate: element.ultimate,
          Variance: element.variance,
          displayOrder: element.displayOrder
        });
    });
    this.apiService.publishedDepartmentSummaryReport(this.reportValues).subscribe(x => {
      this.isShow = false;
      this.toastr.clear();
      this.departmentPublish();
    },
      err => {
        this.isShow = false;
        this.toastr.success('Department Summary Report Published Successfully');
      },
      () => {
      }
    );
  }
  departmentPublish() {
    this.toastr.success('Department Summary Report Published Successfully');
  }

  getAdjustmentsTotal(val: any) {

    let adjustments = 0;
    let totalcreativemarketing = 0;
    let totalpublicity = 0;
    let totalpubpromo = 0;
    let totalother = 0;
    let totalmarketing = 0;
    let totalbasics = 0;
    this.reportValues.forEach(element => {
      //
      const result = element;
      if (result.groupTotal == 1) {

        adjustments = adjustments + result.adjustments;
      }
      if (result.category == '' && result.groupName == 'TOTAL MEDIA') {
        element.adjustments = adjustments;
        totalmarketing += adjustments;
        adjustments = 0;
      }

      if (result.groupTotal == 2) {
        adjustments = adjustments + result.adjustments;
      }
      if (result.category == '' && result.groupName == 'TOTAL PRINT CAMPAIGN') {
        result.adjustments = adjustments;
        totalcreativemarketing += adjustments;
        adjustments = 0;
      }
      if (result.groupTotal == 3) {
        adjustments = adjustments + result.adjustments;
      }
      if (result.category == '' && result.groupName == 'TOTAL A/V CAMPAIGN') {
        result.adjustments = adjustments;
        totalcreativemarketing += adjustments;
        adjustments = 0;
      }

      if (result.category == '' && result.groupName == 'TOTAL CREATIVE ADVERTISING') {
        result.adjustments = totalcreativemarketing;
        totalbasics += totalcreativemarketing;
      }

      if (result.groupTotal == 4) {
        adjustments = adjustments + result.adjustments;
      }
      if (result.category == '' && result.groupName == 'TOTAL MARKET RESEARCH') {
        result.adjustments = adjustments;
        totalbasics += adjustments;
        adjustments = 0;
      }
      if (result.groupTotal == 5) {
        adjustments = adjustments + result.adjustments;
      }
      if (result.category == '' && result.groupName == 'TOTAL NATIONAL PUBLICITY') {
        result.adjustments = adjustments;
        totalpublicity += adjustments;
        adjustments = 0;
      }
      if (result.groupTotal == 6) {
        adjustments = adjustments + result.adjustments;
      }
      if (result.category == '' && result.groupName == 'TOTAL PRESS EVENTS') {
        result.adjustments = adjustments;
        totalpublicity += adjustments;
        adjustments = 0;
      }
      if (result.groupTotal == 7) {
        adjustments = adjustments + result.adjustments;
      }
      if (result.category == '' && result.groupName == 'TOTAL PRESS MATERIALS') {
        result.adjustments = adjustments;
        totalpublicity += adjustments;
        adjustments = 0;
      }

      if (result.category == '' && result.groupName == 'TOTAL PUBLICITY') {
        result.adjustments = totalpublicity;
        totalpubpromo += totalpublicity;
      }

      if (result.groupTotal == 8) {
        adjustments = adjustments + result.adjustments;
      }
      if (result.category == '' && result.groupName == 'TOTAL PROMOTION') {
        result.adjustments = adjustments;
        totalpubpromo += adjustments;
        adjustments = 0;
      }
      if (result.groupTotal == 9) {
        adjustments = adjustments + result.adjustments;
      }
      if (result.category == '' && result.groupName == 'TOTAL OTHER1') {
        result.adjustments = adjustments;
        totalpubpromo += adjustments;
        adjustments = 0;
      }

      if (result.category == '' && result.groupName == 'TOTAL PUBLICITY/ PROMOTION') {
        result.adjustments = totalpubpromo;
        totalbasics += totalpubpromo;
      }

      if (result.groupTotal == 10) {

        adjustments = adjustments + result.adjustments;
      }
      if (result.category == '' && result.groupName == 'TOTAL OTHER2') {

        result.adjustments = adjustments;
        totalbasics += adjustments;
        adjustments = 0;
      }

      if (result.category == '' && result.groupName == 'TOTAL BASICS') {

        result.adjustments = totalbasics;
        totalmarketing += totalbasics;
      }

      if (result.category == '' && result.groupName == 'TOTAL MARKETING') {

        result.adjustments = totalmarketing;
      }
    });
  }

  getTotal(val: any) {
    let budget: any = 0;
    let committedToDate: any = 0;
    let estimationToComplete: any = 0;
    let ultimate: any = 0;
    let variance: any = 0;
    let creativemarketingbudget: any = 0;
    let creativemarketingcommitted: any = 0;
    let creativemarketingestimate: any = 0;
    let creativemarketingultimate: any = 0;
    let creativemarketingvariance: any = 0;
    let MRbudget: any = 0;
    let MRcommitted: any = 0;
    let MRestimate: any = 0;
    let MRultimate: any = 0;
    let MRvariance: any = 0;
    let pubBudget: any = 0;
    let pubCommitted: any = 0;
    let pubEstimate: any = 0;
    let pubUltimate: any = 0;
    let pubVariance: any = 0;
    let promoBudget: any = 0;
    let promoCommitted: any = 0;
    let promoEstimate: any = 0;
    let promoUltimate: any = 0;
    let promoVariance: any = 0;
    let pubpromoBudget: any = 0;
    let pubpromoCommitted: any = 0;
    let pubpromoEstimate: any = 0;
    let pubpromoUltimate: any = 0;
    let pubpromoVariance: any = 0;
    let totalBasicsBudget: any = 0;
    let totalBasicsCommitted: any = 0;
    let totalBasicsEstimate: any = 0;
    let totalBasicsUltimate: any = 0;
    let totalBasicsVariance: any = 0;
    let otherbudget: any = 0;
    let othercommitted: any = 0;
    let otherestimate: any = 0;
    let otherultimate: any = 0;
    let othervariance: any = 0;
    let totalmarketingBudget: any = 0;
    let totalmarketingCommitted: any = 0;
    let totalmarketingEstimate: any = 0;
    let totalmarketingUltimate: any = 0;
    let totalmarketingVariance: any = 0;

    this.reportValues.forEach(element => {
      const result = element;
      if (result.groupTotal == 1) {
        //   
        budget = budget + result.budget;
        committedToDate = committedToDate + result.committedToDate;
        estimationToComplete = estimationToComplete + result.estimationToComplete;
        ultimate = result.ultimate + ultimate;
        variance = result.variance + variance;
      }
      if (result.category == '' && result.groupName == 'TOTAL MEDIA') {
        result.budget = budget + result.budget;
        result.committedToDate = committedToDate + result.committedToDate;
        result.estimationToComplete = estimationToComplete + result.estimationToComplete;
        result.ultimate = ultimate + result.ultimate;
        result.variance = variance + result.variance;

        totalmarketingBudget += budget;
        totalmarketingCommitted += committedToDate;
        totalmarketingEstimate += estimationToComplete;
        totalmarketingUltimate += ultimate;
        totalmarketingVariance += variance;
        budget = 0;
        committedToDate = 0;
        estimationToComplete = 0;
        ultimate = 0;
        variance = 0;

      }
      if (result.groupTotal == 2) {
        budget = budget + result.budget;
        committedToDate = committedToDate + result.committedToDate;
        estimationToComplete = estimationToComplete + result.estimationToComplete;
        ultimate = result.ultimate + ultimate;
        variance = result.variance + variance;
      }
      if (result.category == '' && result.groupName == 'TOTAL PRINT CAMPAIGN') {
        result.budget = budget;
        result.committedToDate = committedToDate;
        result.estimationToComplete = estimationToComplete;
        result.ultimate = ultimate;
        result.variance = variance;
        creativemarketingbudget += budget;
        creativemarketingcommitted += committedToDate;
        creativemarketingestimate += estimationToComplete;
        creativemarketingultimate += ultimate;
        creativemarketingvariance += variance;
        budget = 0;
        committedToDate = 0;
        estimationToComplete = 0;
        ultimate = 0;
        variance = 0;
      }
      if (result.groupTotal == 3) {
        budget = budget + result.budget;
        committedToDate = committedToDate + result.committedToDate;
        estimationToComplete = estimationToComplete + result.estimationToComplete;
        ultimate = result.ultimate + ultimate;
        variance = result.variance + variance;
      }
      if (result.category == '' && result.groupName == 'TOTAL A/V CAMPAIGN') {
        result.budget = budget;
        result.committedToDate = committedToDate;
        result.estimationToComplete = estimationToComplete;
        result.ultimate = ultimate;
        result.variance = variance;
        creativemarketingbudget += budget;
        creativemarketingcommitted += committedToDate;
        creativemarketingestimate += estimationToComplete;
        creativemarketingultimate += ultimate;
        creativemarketingvariance += variance;
        budget = 0;
        committedToDate = 0;
        estimationToComplete = 0;
        ultimate = 0;
        variance = 0;
      }
      if (result.category == '' && result.groupName == 'TOTAL CREATIVE ADVERTISING') {

        result.budget = creativemarketingbudget;
        result.committedToDate = creativemarketingcommitted;
        result.estimationToComplete = creativemarketingestimate;
        result.ultimate = creativemarketingultimate;
        result.variance = creativemarketingvariance;
        totalBasicsBudget += creativemarketingbudget;
        totalBasicsCommitted += creativemarketingcommitted;
        totalBasicsEstimate += creativemarketingestimate;
        totalBasicsUltimate += creativemarketingultimate;
        totalBasicsVariance += creativemarketingvariance;

      }
      if (result.groupTotal == 4) {
        //   
        budget = budget + result.budget;
        committedToDate = committedToDate + result.committedToDate;
        estimationToComplete = estimationToComplete + result.estimationToComplete;
        ultimate = result.ultimate + ultimate;
        variance = result.variance + variance;
        MRbudget = budget;
        MRcommitted = committedToDate;
        MRestimate = estimationToComplete;
        MRultimate = ultimate;
        MRvariance = variance;
      }
      if (result.category == '' && result.groupName == 'TOTAL MARKET RESEARCH') {

        result.budget = MRbudget;
        result.committedToDate = MRcommitted;
        result.estimationToComplete = MRestimate;
        result.ultimate = MRultimate;
        result.variance = MRvariance;
        totalBasicsBudget += MRbudget;
        totalBasicsCommitted += MRcommitted;
        totalBasicsEstimate += MRestimate;
        totalBasicsUltimate += MRultimate;
        totalBasicsVariance += MRvariance;
      }

      if (result.groupTotal == 5) {
        budget = budget + result.budget;
        committedToDate = committedToDate + result.committedToDate;
        estimationToComplete = estimationToComplete + result.estimationToComplete;
        ultimate = result.ultimate + ultimate;
        variance = result.variance + variance;
      }

      if (result.category == '' && result.groupName == 'TOTAL NATIONAL PUBLICITY') {
        result.budget = budget;
        result.committedToDate = committedToDate;
        result.estimationToComplete = estimationToComplete;
        result.ultimate = ultimate;
        result.variance = variance;
        pubBudget += budget;
        pubCommitted += committedToDate;
        pubEstimate += estimationToComplete;
        pubUltimate += ultimate;
        pubVariance += variance;
        budget = 0;
        committedToDate = 0;
        estimationToComplete = 0;
        ultimate = 0;
        variance = 0;
      }

      if (result.groupTotal == 6) {
        budget = budget + result.budget;
        committedToDate = committedToDate + result.committedToDate;
        estimationToComplete = estimationToComplete + result.estimationToComplete;
        ultimate = result.ultimate + ultimate;
        variance = result.variance + variance;
      }

      if (result.category == '' && result.groupName == 'TOTAL PRESS EVENTS') {
        result.budget = budget;
        result.committedToDate = committedToDate;
        result.estimationToComplete = estimationToComplete;
        result.ultimate = ultimate;
        result.variance = variance;
        pubBudget += budget;
        pubCommitted += committedToDate;
        pubEstimate += estimationToComplete;
        pubUltimate += ultimate;
        pubVariance += variance;
        budget = 0;
        committedToDate = 0;
        estimationToComplete = 0;
        ultimate = 0;
        variance = 0;
      }

      if (result.groupTotal == 7) {
        budget = budget + result.budget;
        committedToDate = committedToDate + result.committedToDate;
        estimationToComplete = estimationToComplete + result.estimationToComplete;
        ultimate = result.ultimate + ultimate;
        variance = result.variance + variance;
      }

      if (result.category == '' && result.groupName == 'TOTAL PRESS MATERIALS') {
        result.budget = budget;
        result.committedToDate = committedToDate;
        result.estimationToComplete = estimationToComplete;
        result.ultimate = ultimate;
        result.variance = variance;
        pubBudget += budget;
        pubCommitted += committedToDate;
        pubEstimate += estimationToComplete;
        pubUltimate += ultimate;
        pubVariance += variance;
        budget = 0;
        committedToDate = 0;
        estimationToComplete = 0;
        ultimate = 0;
        variance = 0;
      }

      if (result.category == '' && result.groupName == 'TOTAL PUBLICITY') {
        result.budget = pubBudget;
        result.committedToDate = pubCommitted;
        result.estimationToComplete = pubEstimate;
        result.ultimate = pubUltimate;
        result.variance = pubVariance;
        pubpromoBudget += pubBudget;
        pubpromoCommitted += pubCommitted;
        pubpromoEstimate += pubEstimate;
        pubpromoUltimate += pubUltimate;
        pubpromoVariance += pubVariance;
        budget = 0;
        committedToDate = 0;
        estimationToComplete = 0;
        ultimate = 0;
        variance = 0;
      }

      if (result.groupTotal == 8) {
        budget = budget + result.budget;
        committedToDate = committedToDate + result.committedToDate;
        estimationToComplete = estimationToComplete + result.estimationToComplete;
        ultimate = result.ultimate + ultimate;
        variance = result.variance + variance;
      }

      if (result.category == '' && result.groupName == 'TOTAL PROMOTION') {
        result.budget = budget;
        result.committedToDate = committedToDate;
        result.estimationToComplete = estimationToComplete;
        result.ultimate = ultimate;
        result.variance = variance;
        promoBudget += budget;
        promoCommitted += committedToDate;
        promoEstimate += estimationToComplete;
        promoUltimate += ultimate;
        promoVariance += variance;
        budget = 0;
        committedToDate = 0;
        estimationToComplete = 0;
        ultimate = 0;
        variance = 0;
      }

      if (result.groupTotal == 9) {
        budget = budget + result.budget;
        committedToDate = committedToDate + result.committedToDate;
        estimationToComplete = estimationToComplete + result.estimationToComplete;
        ultimate = result.ultimate + ultimate;
        variance = result.variance + variance;
      }

      if (result.category == '' && result.groupName == 'TOTAL OTHER1') {
        result.budget = budget;
        result.committedToDate = committedToDate;
        result.estimationToComplete = estimationToComplete;
        result.ultimate = ultimate;
        result.variance = variance;
        promoBudget += budget;
        promoCommitted += committedToDate;
        promoEstimate += estimationToComplete;
        promoUltimate += ultimate;
        promoVariance += variance;
        pubpromoBudget += promoBudget;
        pubpromoCommitted += promoCommitted;
        pubpromoEstimate += promoEstimate
        pubpromoUltimate += promoUltimate;
        pubpromoVariance += promoVariance;
        budget = 0;
        committedToDate = 0;
        estimationToComplete = 0;
        ultimate = 0;
        variance = 0;
      }

      if (result.category == '' && result.groupName == 'TOTAL PUBLICITY/ PROMOTION') {
        //
        result.budget = pubpromoBudget;
        result.committedToDate = pubpromoCommitted;
        result.estimationToComplete = pubpromoEstimate;
        result.ultimate = pubpromoUltimate;
        result.variance = pubpromoVariance;
        totalBasicsBudget += pubpromoBudget;
        totalBasicsCommitted += pubpromoCommitted;
        totalBasicsEstimate += pubpromoEstimate;
        totalBasicsUltimate += pubpromoUltimate;
        totalBasicsVariance += pubpromoVariance;
      }

      if (result.groupTotal == 10) {
        //   
        budget = budget + result.budget;
        committedToDate = committedToDate + result.committedToDate;
        estimationToComplete = estimationToComplete + result.estimationToComplete;
        ultimate = result.ultimate + ultimate;
        variance = result.variance + variance;
      }
      if (result.category == '' && result.groupName == 'TOTAL OTHER2') {

        result.budget = budget;
        result.committedToDate = committedToDate;
        result.estimationToComplete = estimationToComplete;
        result.ultimate = ultimate;
        result.variance = variance;
        otherbudget = budget;
        othercommitted = committedToDate;
        otherestimate = estimationToComplete;
        otherultimate = ultimate;
        othervariance = variance;
        totalBasicsBudget += otherbudget;
        totalBasicsCommitted += othercommitted;
        totalBasicsEstimate += otherestimate;
        totalBasicsUltimate += otherultimate;
        totalBasicsVariance += othervariance;
        budget = 0;
        committedToDate = 0;
        estimationToComplete = 0;
        ultimate = 0;
        variance = 0;
      }
      if (result.category == '' && result.groupName == 'TOTAL BASICS') {

        result.budget = totalBasicsBudget;
        result.committedToDate = totalBasicsCommitted;
        result.estimationToComplete = totalBasicsEstimate;
        result.ultimate = totalBasicsUltimate;
        result.variance = totalBasicsVariance;
        totalmarketingBudget += totalBasicsBudget;
        totalmarketingCommitted += totalBasicsCommitted;
        totalmarketingEstimate += totalBasicsEstimate;
        totalmarketingUltimate += totalBasicsUltimate;
        totalmarketingVariance += totalBasicsVariance;
      }

      if (result.category == '' && result.groupName == 'TOTAL MARKETING') {

        result.budget = totalmarketingBudget;
        result.committedToDate = totalmarketingCommitted;
        result.estimationToComplete = totalmarketingEstimate;
        result.ultimate = totalmarketingUltimate;
        result.variance = totalmarketingVariance;
      }

    });
  }

  exportexcelClick(fileFormat) {
    this.isShow = true;
    this.apiService.getDepartmentSummaryReportById(fileFormat)
      .subscribe(response => {

        if (fileFormat == 'Excel') {
          const a = document.createElement('a');
          a.href = response.res;
          a.target = "blank";
          a.download = 'DepartmentSummaryReport' + Date.now + '.xlsx';
          a.click();
          this.isShow = false;
          this.toastr.success('Department Summary Downloaded Successfully');
        }
        else {
          const a = document.createElement('a');
          a.href = response.res;
          a.target = "blank";
          a.download = 'DepartmentSummaryReport' + Date.now + '.pdf';
          a.click();
          this.isShow = false;
          this.toastr.success('Department Summary Downloaded Successfully');
        }
      });
  }
}


