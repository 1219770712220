import { Component, OnInit, Input } from '@angular/core';
import { Table } from 'primeng/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../../service/apiCall.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Http } from '@angular/http';
import { ExportService } from '../../service/export.service';
import { ToastrService } from 'ngx-toastr';
import { bool } from 'aws-sdk/clients/signer';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { AwsCommonService } from '../../service/aws-common.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-publishreportstest',
  templateUrl: './publishreportstest.component.html',
  styleUrls: ['./publishreportstest.component.scss']
})

export class PublishReportTestComponent implements OnInit {
  showErrorDialogPopup: boolean = false;
  selectedVal: any;
  reportValues: any;
  selectedAll: any;
  validations: any = [];
  names: any[];
  insertedDate: any[];
  titles: any = [];
  flashReport: any = [];
  flashReportAll: any = [];
  filterExcelName: any = [];
  isShow = false;
  excelNames: any[];
  selectedyear: any;
  selectedmonth: any;
  selecteddate: any;
  message: any;

  constructor(private _http: Http, private router: Router, private modalService: NgbModal,
    private apiService: ApiService, private exportService: ExportService, private toastr: ToastrService,
    private awsCommonService: AwsCommonService, private cookieService: CookieService) {

  }

  dateValues: any;
  yearValues: any;
  monthValues: any;

  ngOnInit() {
    this.isShow = true; 
    this.apiService.testpublishAll().subscribe(x => {        
        this.apiService.testpublishSlateNMedia().subscribe(x => {                   
        });
       this.apiService.testpublishTitle().subscribe(x => {                                    
        });
        // this.apiService.testpublishDepartment().subscribe(x => {           
        //   this.toastr.success('All Preview Reports Published Successfully');
        // });
        // this.apiService.testpublishSlateNMediaFiles().subscribe(x => {         
        // });        
      },
      err => {   
        this.isShow = false;       
      },
      () => 
      {     
        this.isShow = false; 
        this.toastr.success('All Reports Published Successfully'); 
        this.apiService.gettestNonAdminInsertedDateById().subscribe(result => {
            this.yearValues = result;
            if(this.yearValues.length > 0)
            {
            this.selectedyear = this.yearValues[0].year;
            this.getMonth();
            }
          },
          err => {
            this.isShow = false;
            this.showErrorDialogPopup = true;          
          },
          () => {
          }
        );
      }
      );     
  }

  exportpdfClick() {

    this.awsCommonService.getApiRequest(environment.apiUrl + this.selectedVal, 'GET')
      .subscribe(result => {

        this.reportValues = result;
      },
      err => {
        this.isShow = false;
        this.showErrorDialogPopup = true;          
      },
      () => {
      }
    );
  }

  selectAll(event: any) {

    for (var i = 0; i < this.names.length; i++) {
      this.names[i].selected = this.selectedAll;

    }

    for (var i = 0; i < this.names.length; i++) {
      this.titles.push({ titleId: this.names[i].titleId, titleName: this.names[i].titlename });
    }


  }

  checkIfAllSelected(event: any) {

    if (event.selected == true) {
      let titleId = event.titleId;
      this.titles.push({ titleId: event.titleId, titleName: event.titlename });

    }
    else {
      let titleId = event.titleId;
      this.titles = this.titles.filter(obj => obj.titleId !== titleId);

    }
  }

  open() {
    this.validations = [];
    const dd = this.names.find(x => x.selected === true);
    if (dd === undefined) {
      this.validations.push('Please Select Title');
    }
  }

  openDialog() {

    this.validations = [];
    const dd = this.names.find(x => x.selected === true);
    if (dd === undefined) {
      this.validations.push('Please Select Title');
    }
    if (this.validations.length > 0) {
      return;
    }
  }

  formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  getMonth() {
    this.apiService.gettestNonAdminInsertedMonthById(this.selectedyear).subscribe(result => {
      this.monthValues = result;
      this.selectedmonth = this.monthValues[0].month;
      this.getDay();
    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }

  getDay() {
    this.apiService.gettestNonAdminInsertedDayById(this.selectedmonth, this.selectedyear).subscribe(result => {
      this.dateValues = result;
      this.selecteddate = this.dateValues[0].date;
      
      if(this.cookieService.get('URL') == environment.appurl + "/publishreportstest"){
        this.exportPDFFromMail('Pdf');
      }
      
      
    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }

  exportPDFFromMail(fileFormat){
    var DateObj = new Date();

    this.isShow = true;
    this.validations = [];
    this.message = undefined;
    this.selectedVal = this.selectedyear + "-" + this.selectedmonth + "-" + this.selecteddate;
    this.apiService.gettestNonAdminReportById(fileFormat, this.selectedVal)
        .subscribe(response => {
          this.apiService.gettestGeneratedPDFMail(response.res).subscribe(res => {
            this.message = undefined;
            const a = document.createElement('a');
            a.href = res.res;
            a.target ="blank";
            a.download = 'ConsolidatedReport_' + Date.now + '.pdf';
            a.click();
            this.isShow = false;
          }),
            err => {
              this.isShow = false;
              this.message = "No Report Available";
            },
            () => {
            }
        },
          err => {
            this.isShow = false;
            this.message = "No Report Available";
          },
          () => {
          }
        );
        //this.removeItem('testURL');
  }

  removeItem(name) {
    document.cookie = name+'=; Max-Age=-99999999;';  
}

  exportexcelClick(fileFormat) {

    this.isShow = true;
    this.validations = [];
    this.message = undefined;
    this.selectedVal = this.selectedyear + "-" + this.selectedmonth + "-" + this.selecteddate
    if (this.selectedyear == undefined || this.selectedmonth == undefined || this.selecteddate == undefined) {
      this.message = undefined;
      this.isShow = false;
      this.validations.push('Choose Year, Month & Date to download the report');
    }
    else {
      this.apiService.gettestNonAdminReportById(fileFormat, this.selectedVal)
        .subscribe(response => {
          this.apiService.gettestGeneratedPDF(response.res).subscribe(res => {
            this.message = undefined;
            const a = document.createElement('a');
            a.href = res.res;
            a.target ="blank";
            a.download = 'ConsolidatedReport_' + Date.now + '.pdf';
            a.click();
            this.isShow = false;
          }),
            err => {
              this.isShow = false;
              this.message = "No Report Available";
            },
            () => {
            }
        },
          err => {
            this.isShow = false;
            this.message = "No Report Available";
          },
          () => {
          }
        );
    }
  }
}