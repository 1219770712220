import { Component, Input, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Http } from '@angular/http';
import { ApiService } from '../../service/apiCall.service'
import { SaveFinalDataService } from '../../service/save-final-data.service'
import { environment } from '../../../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { PublishDataService } from '../../service/publish-data.service';
import * as FileSaver from 'file-saver';
import { AwsCommonService } from '../../service/aws-common.service';
import { NumericScrollLockDirective } from '../../directives/numeric-scroll-lock.directive';


declare const $;

@Component({
  selector: 'ngbd-modal-title',
  template: `
    <div id="myModal1" class="modal-header">
     <label style="margin-right:250px">Proceed to Generate report</label>
     <button type="button"  class="glyphicon glyphicon-remove" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
     </button>    
    </div>
    <div class="modal-body">                
    <h6 class="modal-title">Click save to save the report and cancel to go back.</h6>
    </div>
    <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="clickFilter2()">Save</button>        
    <button type="button" class="btn btn-danger" routerLinkActive="active" (click)="activeModal.dismiss('Cancel')">Cancel</button>
    </div> 
  `
})

export class NgbdModalTitleSummary {
  @Input() name;

  constructor(public activeModal: NgbActiveModal,
    private apiService: ApiService,
    private savetitleService: SaveFinalDataService,
    private publishtitleService: PublishDataService,
    private toastr: ToastrService) {
    this.savetitleService.listen().subscribe((m: any) => {
      if (m == 'ClosePopup') {
        activeModal.dismiss('Save');
        this.toastr.clear();
      }
    })
    this.publishtitleService.listen().subscribe((m: any) => {
      if (m == 'ClosePopup') {
        activeModal.dismiss('Publish');
        this.toastr.clear();
      }
    })
  }

  publish() {
    this.publishtitleService.filter('Publish');
  }

  clickFilter2() {
    this.savetitleService.filter('Save');
  }
}

@Component({
  selector: 'app-executivereport',
  templateUrl: './executivereport.component.html',
  styleUrls: ['./executivereport.component.scss']
})
export class ExecutivereportComponent implements OnInit {

  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    this.changeHeight();
  }

  @HostListener('window:resize', ['$event'])
  resize(event) {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    this.changeHeight();
  }

  changeHeight() {
    if (this.innerHeight <= 568) {
      this.tableheight = '200px';

    } else if (this.innerHeight > 569 && this.innerHeight <= 667) {
      this.tableheight = '280px'

    } else if (this.innerHeight > 668 && this.innerHeight <= 759) {
      this.tableheight = '270px'

    } else if (this.innerHeight > 760 && this.innerHeight <= 812) {
      this.tableheight = '340px'

    } else if (this.innerHeight > 813 && this.innerHeight <= 896) {
      this.tableheight = '380px'

    } else if (this.innerHeight > 897 && this.innerHeight <= 1100) {
      this.tableheight = '400px'

    } else if (this.innerHeight > 1101) {
      this.tableheight = '400px'

    } else {
      this.tableheight = '350px'
    }
  }

  innerWidth: any;
  innerHeight: any;
  tableheight: string = '350px';
  showErrorDialogPopup: boolean = false;
  titleValues: any;
  showGrid = false;
  loading: boolean;
  selectedVal = 0;
  reportValues: any;
  validations: any = [];
  cols: any = [];
  wbsElement: any = '';
  titleSummary: any = [];
  isShow = false;
lastSavedDate: Date;

  constructor(private _http: Http, private router: Router, private modalService: NgbModal,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private publishtitleService: PublishDataService,
    private toastr: ToastrService,
    private awsCommonService: AwsCommonService) {
    this.titleSummary = [];   
  }

  ngOnInit() {
    this.apiService.getTitles().subscribe(result => {

      this.titleValues = result;

    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );



    this.loading = true;

    this.cols = [
      { field: 'groupName', header: 'Description' },
      { field: 'budget', header: 'Budget / Plan' },
      { field: 'committedToDate', header: '$ Committed to Date' },
      { field: 'estimationToComplete', header: 'Est to Complete' },
      { field: 'adjustments', header: 'Adjustments' },
      { field: 'ultimate', header: 'Ultimate' },
      { field: 'variance', header: 'Variance' },
      { field: 'comments', header: 'Comments' }

    ];
  }

  getWbsElement() {

    this.wbsElement = this.titleValues.find(x => x.titleId == this.selectedVal).wbsElem;
  
  }

  open() {
    const modalRef = this.modalService.open(NgbdModalTitleSummary, { centered: true });
  }

  searchClick() {
    this.isShow = true;
    this.validations = [];
    if (this.selectedVal === 0) {
      this.validations.push('Please Select Title');
      this.isShow = false;
    }
    else {
      this.validations = [];
      this.awsCommonService.getApiRequest(environment.titlereporturl + this.selectedVal + '', 'GET')
        .subscribe(result => {          
          this.reportValues = result;

          if (this.reportValues.length == 0) {
            this.isShow = false;
            this.toastr.error("Please Import selected title in Media Summary");
          }
          else {
            this.isShow = false;
          }
          this.apiService.getFlashSummaryLastSavedDateById(this.selectedVal).subscribe(result => {
            this.lastSavedDate = result;  
          })
        },
        err => {
          this.isShow = false;
          this.showErrorDialogPopup = true;          
        },
        () => {
        }
      );

    }
    if (this.validations.length > 0) {
      return;
    }
    this.showGrid = true;
  }

  searchSaveData() {

    this.isShow = true;
    this.titleSummary = this.reportValues;
    this.apiService.saveTitleSummaryReport(this.titleSummary).subscribe(x => {
      this.isShow = false;
      this.toastr.clear();

    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }

  saveData() {
    this.isShow = true;
    this.titleSummary = this.reportValues;
	 for(let i = 0; i < this.titleSummary.length; i++){
      if(this.titleSummary[i].adjustments == null)
      {
        this.titleSummary[i].adjustments = 0;
      }	
      if(this.titleSummary[i].budget == null)	
      {	
        this.titleSummary[i].budget = 0;	
      }	
      if(this.titleSummary[i].ultimate == null)	
      {	
        this.titleSummary[i].ultimate = 0;	
      }	
      if(this.titleSummary[i].variance == null)	
      {	
        this.titleSummary[i].variance = 0;	
      }	
      if(this.titleSummary[i].committedToDate == null)	
      {	
        this.titleSummary[i].committedToDate = 0;	
      }	
      if(this.titleSummary[i].estimationToComplete == null)	
      {	
        this.titleSummary[i].estimationToComplete = 0;
	  }
    }
    this.apiService.saveTitleSummaryReport(this.titleSummary).subscribe(x => {
      this.isShow = false;
      this.toastr.clear();
      this.titleSave();
      this.apiService.getFlashSummaryLastSavedDateById(this.selectedVal).subscribe(result => {
        this.lastSavedDate = result;  
          
      });
    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }
  titleSave() {
    this.toastr.success('Flash Summary Report Saved Successfully');
  }
  publishData() {
    this.isShow = true;
    var data = [];
    this.reportValues.forEach(element => {

      data.push(
        {
          titleId: element.titleId,
          titleName: element.titleName,
          adjustments: element.adjustments,
          category: element.category,
          comments: element.comments,
          budget: element.budget,
          committedToDate: element.committedToDate,
          estimationToComplete: element.estimationToComplete,
          groupName: element.groupName,
          groupTotal: element.groupTotal,
          Ultimate: element.ultimate,
          Variance: element.variance,
          displayOrder: element.displayOrder
        });
    });

    this.apiService.publishedTitleSummaryReport(this.reportValues).subscribe(x => {

      this.isShow = false;
      this.toastr.clear();
      this.titlePublish();
    },
      err => {
        this.isShow = false;
        this.toastr.success('Title Summary Report Published Successfully');
      },
      () => {
      }
    );
  }

  titlePublish() {
    this.toastr.success('Title Summary Report Published Successfully');
  }
  getAdjustmentsTotal(val: any) {
    let budget = 0;
    let committedtodate = 0;
    let estimatetocomplete = 0;
    let adjustments = 0;
    let ultimate = 0;
    let totalcreativemarketingbud = 0;
    let totalcreativemarketingcom = 0;
    let totalcreativemarketingest = 0;
    let totalcreativemarketingadj = 0;
    let totalcreativemarketingulti = 0;
    let totalcreativemarketingvar = 0;
    let totalpublicitybud = 0;
    let totalpublicitycom = 0;
    let totalpublicityest = 0;
    let totalpublicityadj = 0;
    let totalpublicityulti = 0;
    let totalpublicityvar = 0;
    let totalpubpromobud = 0;
    let totalpubpromocom = 0;
    let totalpubpromoest = 0;
    let totalpubpromoadj = 0;
    let totalpubpromoulti = 0;
    let totalpubpromovar = 0;
    let totalotheradj = 0;
    let totalotherulti = 0;
    let totalothervar = 0;
    let totalmarketingbud = 0;
    let totalmarketingcom = 0;
    let totalmarketingest = 0;
    let totalmarketingadj = 0;
    let totalmarketingulti = 0;
    let totalmarketingvar = 0;
    let totalbasicsbud = 0;
    let totalbasicscom = 0;
    let totalbasicsest = 0;
    let totalbasicsadj = 0;
    let totalbasicsulti = 0;
    let totalbasicsvar = 0;
    this.reportValues.forEach(element => {
      const result = element;
      if (result.groupTotal == 1) {
        adjustments += result.adjustments;
        if (result.groupName == val.groupName) {
          element.ultimate = result.adjustments + result.committedToDate + result.estimationToComplete;
          element.variance = element.budget - element.ultimate;
        }
      }
      if (result.groupName == 'TOTAL MEDIA') {
        element.adjustments = adjustments;
        element.ultimate = element.adjustments + result.committedToDate + result.estimationToComplete;
        element.variance = element.budget - element.ultimate;
        totalmarketingbud += element.budget;
        totalmarketingcom += element.committedToDate;
        totalmarketingest += element.estimationToComplete;
        totalmarketingadj = element.adjustments;
        totalmarketingulti = element.ultimate;
        totalmarketingvar = element.variance;
        adjustments = 0;
      }

      if (result.groupTotal == 2) {
        adjustments += result.adjustments;
        if (result.groupName == val.groupName) {
          element.ultimate = result.adjustments + result.committedToDate + result.estimationToComplete;
          element.variance = element.budget - element.ultimate;
        }
      }
      if (result.groupName == 'TOTAL PRINT CAMPAIGN') {
        element.adjustments = adjustments;
        element.ultimate = element.adjustments + result.committedToDate + result.estimationToComplete;
        element.variance = element.budget - element.ultimate;
        totalcreativemarketingbud += element.budget;
        totalcreativemarketingcom += element.committedToDate;
        totalcreativemarketingest += element.estimationToComplete;
        totalcreativemarketingadj += element.adjustments;
        totalcreativemarketingulti += element.ultimate;
        totalcreativemarketingvar += element.variance;
        adjustments = 0;
      }
      if (result.groupTotal == 3) {
        adjustments += result.adjustments;
        budget += result.budget;
        committedtodate += result.committedToDate;
        estimatetocomplete += result.estimationToComplete;
        if (result.groupName == val.groupName) {
          element.ultimate = result.adjustments + result.committedToDate + result.estimationToComplete;
          element.variance = element.budget - element.ultimate;
        }
      }
      if (result.groupName == 'TOTAL A/V CAMPAIGN') {
        element.budget = budget;
        element.committedToDate = committedtodate;
        element.estimationToComplete = estimatetocomplete;
        element.adjustments = adjustments;
        element.ultimate = result.adjustments + result.committedToDate + result.estimationToComplete;
        element.variance = element.budget - element.ultimate;
        totalcreativemarketingbud += element.budget;
        totalcreativemarketingcom += element.committedToDate;
        totalcreativemarketingest += element.estimationToComplete;
        totalcreativemarketingadj += element.adjustments;
        totalcreativemarketingulti += element.ultimate;
        totalcreativemarketingvar += element.variance;
        adjustments = 0;
        budget = 0;
        estimatetocomplete = 0;
        committedtodate = 0;
      }

      if (result.groupName == 'TOTAL CREATIVE ADVERTISING') {
        result.budget = totalcreativemarketingbud;
        result.committedToDate = totalcreativemarketingcom;
        result.estimationToComplete = totalcreativemarketingest;
        result.adjustments = totalcreativemarketingadj;
        result.ultimate = totalcreativemarketingulti;
        result.variance = totalcreativemarketingvar;
        totalbasicsbud += totalcreativemarketingbud;
        totalbasicscom += totalcreativemarketingcom;
        totalbasicsest += totalcreativemarketingest;
        totalbasicsadj += totalcreativemarketingadj;
        totalbasicsulti += totalcreativemarketingulti;
        totalbasicsvar += totalcreativemarketingvar;
      }

      if (result.groupTotal == 4) {
        // if(result.groupName == val.groupName)
        // {
        // adjustments = adjustments + result.adjustments;        
        // element.ultimate = adjustments + result.committedToDate + result.estimationToComplete;
        // element.variance = element.budget - element.ultimate;
        // }        
        if (result.groupName == 'TOTAL MARKET RESEARCH') {
          adjustments += result.adjustments;
          element.ultimate = result.adjustments + result.committedToDate + result.estimationToComplete;
          element.variance = element.budget - element.ultimate;
          totalbasicsbud += element.budget;
          totalbasicscom += element.committedToDate;
          totalbasicsest += element.estimationToComplete;
          totalbasicsadj += result.adjustments;
          totalbasicsulti += element.ultimate;
          totalbasicsvar += element.variance;
          adjustments = 0;
        }
      }

      if (result.groupTotal == 5) {
        adjustments += result.adjustments;
        if (result.groupName == val.groupName) {
          element.ultimate = result.adjustments + result.committedToDate + result.estimationToComplete;
          element.variance = element.budget - element.ultimate;
        }
      }
      if (result.groupName == 'TOTAL NATIONAL PUBLICITY') {
        result.adjustments = adjustments;
        element.ultimate = result.adjustments + result.committedToDate + result.estimationToComplete;
        element.variance = element.budget - element.ultimate;
        totalpublicitybud += result.budget;
        totalpublicitycom += element.committedToDate;
        totalpublicityest += element.estimationToComplete;
        totalpublicityadj += result.adjustments;
        totalpublicityulti += element.ultimate;
        totalpublicityvar += element.variance;
        adjustments = 0;
      }
      if (result.groupTotal == 6) {
        adjustments += result.adjustments;
        if (result.groupName == val.groupName) {
          element.ultimate = result.adjustments + result.committedToDate + result.estimationToComplete;
          element.variance = element.budget - element.ultimate;
        }
      }
      if (result.groupName == 'TOTAL PRESS EVENTS') {
        result.adjustments = adjustments;
        element.ultimate = result.adjustments + result.committedToDate + result.estimationToComplete;
        element.variance = element.budget - element.ultimate;
        totalpublicitybud += result.budget;
        totalpublicitycom += element.committedToDate;
        totalpublicityest += element.estimationToComplete;
        totalpublicityadj += result.adjustments;
        totalpublicityulti += element.ultimate;
        totalpublicityvar += element.variance;
        adjustments = 0;
      }
      if (result.groupTotal == 7) {
        adjustments += result.adjustments;
        if (result.groupName == val.groupName) {
          element.ultimate = result.adjustments + result.committedToDate + result.estimationToComplete;
          element.variance = element.budget - element.ultimate;
        }
      }
      if (result.groupName == 'TOTAL PRESS MATERIALS') {
        result.adjustments = adjustments;
        element.ultimate = result.adjustments + result.committedToDate + result.estimationToComplete;
        element.variance = element.budget - element.ultimate;
        totalpublicitybud += result.budget;
        totalpublicitycom += element.committedToDate;
        totalpublicityest += element.estimationToComplete;
        totalpublicityadj += result.adjustments;
        totalpublicityulti += element.ultimate;
        totalpublicityvar += element.variance;
        adjustments = 0;
      }

      if (result.groupName == 'TOTAL PUBLICITY') {
        result.adjustments = totalpublicityadj;
        result.ultimate = totalpublicityulti;
        result.variance = totalpublicityvar;
        totalpubpromobud += totalpublicitybud;
        totalpubpromocom += totalpublicitycom;
        totalpubpromoest += totalpublicityest;
        totalpubpromoadj += totalpublicityadj;
        totalpubpromoulti += totalpublicityulti;
        totalpubpromovar += totalpublicityvar;
      }

      if (result.groupTotal == 8) {
        adjustments += result.adjustments;
        if (result.groupName == val.groupName) {
          element.ultimate = result.adjustments + result.committedToDate + result.estimationToComplete;
          element.variance = element.budget - element.ultimate;
        }
      }
      if (result.groupName == 'TOTAL PROMOTION') {
        result.adjustments = adjustments;
        element.ultimate = result.adjustments + result.committedToDate + result.estimationToComplete;
        element.variance = element.budget - element.ultimate;
        totalpubpromobud += result.budget;
        totalpubpromocom += result.committedToDate;
        totalpubpromoest += result.estimationToComplete;
        totalpubpromoadj += result.adjustments;
        totalpubpromoulti += element.ultimate;
        totalpubpromovar += element.variance;
        adjustments = 0;
      }
      if (result.groupTotal == 9) {
        adjustments += result.adjustments;
        if (result.groupName == val.groupName) {
          element.ultimate = result.adjustments + result.committedToDate + result.estimationToComplete;
          element.variance = element.budget - element.ultimate;
        }
      }
      if (result.groupName == 'TOTAL OTHER') {
        result.adjustments = adjustments;
        element.ultimate = result.adjustments + result.committedToDate + result.estimationToComplete;
        element.variance = element.budget - element.ultimate;
        totalpubpromobud += result.budget;
        totalpubpromocom += result.committedToDate;
        totalpubpromoest += result.estimationToComplete;
        totalpubpromoadj += result.adjustments;
        totalpubpromoulti += element.ultimate;
        totalpubpromovar += element.variance;
        adjustments = 0;
      }

      if (result.groupName == 'TOTAL PUBLICITY/ PROMOTION') {
        result.adjustments = totalpubpromoadj;
        result.ultimate = totalpubpromoulti;
        result.variance = totalpubpromovar;
        totalbasicsbud += totalpubpromobud;
        totalbasicscom += totalpubpromocom;
        totalbasicsest += totalpubpromoest;
        totalbasicsadj += totalpubpromoadj;
        totalbasicsulti += totalpubpromoulti;
        totalbasicsvar += totalpubpromovar;
      }

      if (result.groupTotal == 10) {
        adjustments += result.adjustments;
        budget += result.budget;
        committedtodate += result.committedToDate;
        estimatetocomplete += result.estimationToComplete;
        if (result.groupName == val.groupName) {
          element.ultimate = result.adjustments + result.committedToDate + result.estimationToComplete;
          element.variance = element.budget - element.ultimate;
        }
      }
      if (result.groupName == 'TOTAL OTHER ') {
        element.budget = budget;
        element.committedToDate = committedtodate;
        element.estimationToComplete = estimatetocomplete;
        element.adjustments = adjustments;
        element.ultimate = result.adjustments + result.committedToDate + result.estimationToComplete;
        element.variance = element.budget - element.ultimate;
        totalbasicsbud += element.budget;
        totalbasicscom += element.committedToDate;
        totalbasicsest += element.estimationToComplete;
        totalbasicsadj += element.adjustments;
        totalbasicsulti += element.ultimate;
        totalbasicsvar += element.variance;
        budget = 0;
        estimatetocomplete = 0;
        committedtodate = 0;
      }

      if (result.groupName == 'TOTAL BASICS') {
        result.budget = totalbasicsbud;
        result.committedToDate = totalbasicscom;
        result.estimationToComplete = totalbasicsest;
        result.adjustments = totalbasicsadj;
        result.ultimate = totalbasicsulti;
        result.variance = totalbasicsvar;
        totalmarketingbud += totalbasicsbud;
        totalmarketingcom += totalbasicscom;
        totalmarketingest += totalbasicsest;
        totalmarketingadj += totalbasicsadj;
        totalmarketingulti += totalbasicsulti;
        totalmarketingvar += totalbasicsvar;
      }

      if (result.groupName == 'TOTAL MARKETING') {
        result.budget = totalmarketingbud;
        result.committedToDate = totalmarketingcom;
        result.estimationToComplete = totalmarketingest;
        result.adjustments = totalmarketingadj;
        result.ultimate = totalmarketingulti;
        result.variance = totalmarketingvar;
      }
    });
  }

  getTotal(val: any) {
    let budget: any = 0;
    let committedToDate: any = 0;
    let estimationToComplete: any = 0;
    let ultimate: any = 0;
    let variance: any = 0;
    let creativemarketingbudget: any = 0;
    let creativemarketingcommitted: any = 0;
    let creativemarketingestimate: any = 0;
    let creativemarketingultimate: any = 0;
    let creativemarketingvariance: any = 0;
    let MRbudget: any = 0;
    let MRcommitted: any = 0;
    let MRestimate: any = 0;
    let MRultimate: any = 0;
    let MRvariance: any = 0;
    let pubBudget: any = 0;
    let pubCommitted: any = 0;
    let pubEstimate: any = 0;
    let pubUltimate: any = 0;
    let pubVariance: any = 0;
    let promoBudget: any = 0;
    let promoCommitted: any = 0;
    let promoEstimate: any = 0;
    let promoUltimate: any = 0;
    let promoVariance: any = 0;
    let pubpromoBudget: any = 0;
    let pubpromoCommitted: any = 0;
    let pubpromoEstimate: any = 0;
    let pubpromoUltimate: any = 0;
    let pubpromoVariance: any = 0;
    let totalBasicsBudget: any = 0;
    let totalBasicsCommitted: any = 0;
    let totalBasicsEstimate: any = 0;
    let totalBasicsUltimate: any = 0;
    let totalBasicsVariance: any = 0;
    let otherbudget: any = 0;
    let othercommitted: any = 0;
    let otherestimate: any = 0;
    let otherultimate: any = 0;
    let othervariance: any = 0;
    let totalmarketingBudget: any = 0;
    let totalmarketingCommitted: any = 0;
    let totalmarketingEstimate: any = 0;
    let totalmarketingUltimate: any = 0;
    let totalmarketingVariance: any = 0;

    this.reportValues.forEach(element => {

      const result = element;
      if (result.groupTotal == 1) {

        budget = budget + result.budget;
        committedToDate = committedToDate + result.committedToDate;
        estimationToComplete = estimationToComplete + result.estimationToComplete;
        ultimate = result.ultimate + ultimate;
        variance = result.variance + variance;
      }
      if (result.groupName == 'TOTAL MEDIA') {

        result.budget = budget;
        result.committedToDate = committedToDate;
        result.estimationToComplete = estimationToComplete;
        result.ultimate = ultimate;
        result.variance = variance;

        totalmarketingBudget += budget;
        totalmarketingCommitted += committedToDate;
        totalmarketingEstimate += estimationToComplete;
        totalmarketingUltimate += ultimate;
        totalmarketingVariance += variance;
        budget = 0;
        committedToDate = 0;
        estimationToComplete = 0;
        ultimate = 0;
        variance = 0;

      }
      if (result.groupTotal == 2) {
        budget = budget + result.budget;
        committedToDate = committedToDate + result.committedToDate;
        estimationToComplete = estimationToComplete + result.estimationToComplete;
        ultimate = result.ultimate + ultimate;
        variance = result.variance + variance;
      }
      if (result.groupName == 'TOTAL PRINT CAMPAIGN') {
        result.budget = budget;
        result.committedToDate = committedToDate;
        result.estimationToComplete = estimationToComplete;
        result.ultimate = ultimate;
        result.variance = variance;
        creativemarketingbudget += budget;
        creativemarketingcommitted += committedToDate;
        creativemarketingestimate += estimationToComplete;
        creativemarketingultimate += ultimate;
        creativemarketingvariance += variance;
        budget = 0;
        committedToDate = 0;
        estimationToComplete = 0;
        ultimate = 0;
        variance = 0;
      }
      if (result.groupTotal == 3) {
        //              
        budget = budget + result.budget;
        committedToDate = committedToDate + result.committedToDate;
        estimationToComplete = estimationToComplete + result.estimationToComplete;
        ultimate = result.ultimate + ultimate;
        variance = result.variance + variance;
      }
      if (result.groupName == 'TOTAL A/V CAMPAIGN') {
        result.budget = budget;
        result.committedToDate = committedToDate;
        result.estimationToComplete = estimationToComplete;
        result.ultimate = ultimate;
        result.variance = variance;
        creativemarketingbudget += budget;
        creativemarketingcommitted += committedToDate;
        creativemarketingestimate += estimationToComplete;
        creativemarketingultimate += ultimate;
        creativemarketingvariance += variance;
        budget = 0;
        committedToDate = 0;
        estimationToComplete = 0;
        ultimate = 0;
        variance = 0;
      }
      if (result.groupName == 'TOTAL CREATIVE ADVERTISING') {
        //
        result.budget = creativemarketingbudget;
        result.committedToDate = creativemarketingcommitted;
        result.estimationToComplete = creativemarketingestimate;
        result.ultimate = creativemarketingultimate;
        result.variance = creativemarketingvariance;
        totalBasicsBudget += creativemarketingbudget;
        totalBasicsCommitted += creativemarketingcommitted;
        totalBasicsEstimate += creativemarketingestimate;
        totalBasicsUltimate += creativemarketingultimate;
        totalBasicsVariance += creativemarketingvariance;

      }
      if (result.groupTotal == 4) {
        //   
        budget = budget + result.budget;
        committedToDate = committedToDate + result.committedToDate;
        estimationToComplete = estimationToComplete + result.estimationToComplete;
        ultimate = result.ultimate + ultimate;
        variance = result.variance + variance;
        MRbudget = budget;
        MRcommitted = committedToDate;
        MRestimate = estimationToComplete;
        MRultimate = ultimate;
        MRvariance = variance;
        budget = 0;
        committedToDate = 0;
        estimationToComplete = 0;
        ultimate = 0;
        variance = 0;
      }
      if (result.groupName == 'TOTAL MARKET RESEARCH') {
        //
        result.budget = MRbudget;
        result.committedToDate = MRcommitted;
        result.estimationToComplete = MRestimate;
        result.ultimate = MRultimate;
        result.variance = MRvariance;
        totalBasicsBudget += MRbudget;
        totalBasicsCommitted += MRcommitted;
        totalBasicsEstimate += MRestimate;
        totalBasicsUltimate += MRultimate;
        totalBasicsVariance += MRvariance;
      }

      if (result.groupTotal == 5) {
        budget = budget + result.budget;
        committedToDate = committedToDate + result.committedToDate;
        estimationToComplete = estimationToComplete + result.estimationToComplete;
        ultimate = result.ultimate + ultimate;
        variance = result.variance + variance;
      }

      if (result.groupName == 'TOTAL NATIONAL PUBLICITY') {

        result.budget = budget;
        result.committedToDate = committedToDate;
        result.estimationToComplete = estimationToComplete;
        result.ultimate = ultimate;
        result.variance = variance;
        pubBudget += budget;
        pubCommitted += committedToDate;
        pubEstimate += estimationToComplete;
        pubUltimate += ultimate;
        pubVariance += variance;
        budget = 0;
        committedToDate = 0;
        estimationToComplete = 0;
        ultimate = 0;
        variance = 0;
      }

      if (result.groupTotal == 6) {
        budget = budget + result.budget;
        committedToDate = committedToDate + result.committedToDate;
        estimationToComplete = estimationToComplete + result.estimationToComplete;
        ultimate = result.ultimate + ultimate;
        variance = result.variance + variance;
      }

      if (result.groupName == 'TOTAL PRESS EVENTS') {
        result.budget = budget;
        result.committedToDate = committedToDate;
        result.estimationToComplete = estimationToComplete;
        result.ultimate = ultimate;
        result.variance = variance;
        pubBudget += budget;
        pubCommitted += committedToDate;
        pubEstimate += estimationToComplete;
        pubUltimate += ultimate;
        pubVariance += variance;
        budget = 0;
        committedToDate = 0;
        estimationToComplete = 0;
        ultimate = 0;
        variance = 0;
      }

      if (result.groupTotal == 7) {
        budget = budget + result.budget;
        committedToDate = committedToDate + result.committedToDate;
        estimationToComplete = estimationToComplete + result.estimationToComplete;
        ultimate = result.ultimate + ultimate;
        variance = result.variance + variance;
      }

      if (result.groupName == 'TOTAL PRESS MATERIALS') {
        result.budget = budget;
        result.committedToDate = committedToDate;
        result.estimationToComplete = estimationToComplete;
        result.ultimate = ultimate;
        result.variance = variance;
        pubBudget += budget;
        pubCommitted += committedToDate;
        pubEstimate += estimationToComplete;
        pubUltimate += ultimate;
        pubVariance += variance;
        budget = 0;
        committedToDate = 0;
        estimationToComplete = 0;
        ultimate = 0;
        variance = 0;
      }

      if (result.groupName == 'TOTAL PUBLICITY') {
        result.budget = pubBudget;
        result.committedToDate = pubCommitted;
        result.estimationToComplete = pubEstimate;
        result.ultimate = pubUltimate;
        result.variance = pubVariance;
        pubpromoBudget += pubBudget;
        pubpromoCommitted += pubCommitted;
        pubpromoEstimate += pubEstimate;
        pubpromoUltimate += pubUltimate;
        pubpromoVariance += pubVariance;
        budget = 0;
        committedToDate = 0;
        estimationToComplete = 0;
        ultimate = 0;
        variance = 0;
      }

      if (result.groupTotal == 8) {
        budget = budget + result.budget;
        committedToDate = committedToDate + result.committedToDate;
        estimationToComplete = estimationToComplete + result.estimationToComplete;
        ultimate = result.ultimate + ultimate;
        variance = result.variance + variance;
      }

      if (result.groupName == 'TOTAL PROMOTION') {
        result.budget = budget;
        result.committedToDate = committedToDate;
        result.estimationToComplete = estimationToComplete;
        result.ultimate = ultimate;
        result.variance = variance;
        promoBudget += budget;
        promoCommitted += committedToDate;
        promoEstimate += estimationToComplete;
        promoUltimate += ultimate;
        promoVariance += variance;
        budget = 0;
        committedToDate = 0;
        estimationToComplete = 0;
        ultimate = 0;
        variance = 0;
      }

      if (result.groupTotal == 9) {
        budget = budget + result.budget;
        committedToDate = committedToDate + result.committedToDate;
        estimationToComplete = estimationToComplete + result.estimationToComplete;
        ultimate = result.ultimate + ultimate;
        variance = result.variance + variance;
      }

      if (result.groupName == 'TOTAL OTHER') {
        result.budget = budget;
        result.committedToDate = committedToDate;
        result.estimationToComplete = estimationToComplete;
        result.ultimate = ultimate;
        result.variance = variance;
        promoBudget += budget;
        promoCommitted += committedToDate;
        promoEstimate += estimationToComplete;
        promoUltimate += ultimate;
        promoVariance += variance;
        pubpromoBudget += promoBudget;
        pubpromoCommitted += promoCommitted;
        pubpromoEstimate += promoEstimate
        pubpromoUltimate += promoUltimate;
        pubpromoVariance += promoVariance;
        budget = 0;
        committedToDate = 0;
        estimationToComplete = 0;
        ultimate = 0;
        variance = 0;
      }

      if (result.groupName == 'TOTAL PUBLICITY/ PROMOTION') {
        //
        result.budget = pubpromoBudget;
        result.committedToDate = pubpromoCommitted;
        result.estimationToComplete = pubpromoEstimate;
        result.ultimate = pubpromoUltimate;
        result.variance = pubpromoVariance;
        totalBasicsBudget += pubpromoBudget;
        totalBasicsCommitted += pubpromoCommitted;
        totalBasicsEstimate += pubpromoEstimate;
        totalBasicsUltimate += pubpromoUltimate;
        totalBasicsVariance += pubpromoVariance;
      }

      if (result.groupTotal == 10) {
        //   
        budget = budget + result.budget;
        committedToDate = committedToDate + result.committedToDate;
        estimationToComplete = estimationToComplete + result.estimationToComplete;
        ultimate = result.ultimate + ultimate;
        variance = result.variance + variance;
      }
      if (result.groupName == 'TOTAL OTHER ') {

        result.budget = budget;
        result.committedToDate = committedToDate;
        result.estimationToComplete = estimationToComplete;
        result.ultimate = ultimate;
        result.variance = variance;
        otherbudget = budget;
        othercommitted = committedToDate;
        otherestimate = estimationToComplete;
        otherultimate = ultimate;
        othervariance = variance;
        totalBasicsBudget += otherbudget;
        totalBasicsCommitted += othercommitted;
        totalBasicsEstimate += otherestimate;
        totalBasicsUltimate += otherultimate;
        totalBasicsVariance += othervariance;
        budget = 0;
        committedToDate = 0;
        estimationToComplete = 0;
        ultimate = 0;
        variance = 0;
      }
      if (result.groupName == 'TOTAL BASICS') {
        //
        result.budget = totalBasicsBudget;
        result.committedToDate = totalBasicsCommitted;
        result.estimationToComplete = totalBasicsEstimate;
        result.ultimate = totalBasicsUltimate;
        result.variance = totalBasicsVariance;
        totalmarketingBudget += totalBasicsBudget;
        totalmarketingCommitted += totalBasicsCommitted;
        totalmarketingEstimate += totalBasicsEstimate;
        totalmarketingUltimate += totalBasicsUltimate;
        totalmarketingVariance += totalBasicsVariance;
      }

      if (result.groupName == 'TOTAL MARKETING') {
        //
        result.budget = totalmarketingBudget;
        result.committedToDate = totalmarketingCommitted;
        result.estimationToComplete = totalmarketingEstimate;
        result.ultimate = totalmarketingUltimate;
        result.variance = totalmarketingVariance;
      }

    });
  }


  getUltimateTotal(val: any) {
    let ultimate = 0;
    let variance = 0;
    let totalcreativeulti = 0;
    let totalcreativevar = 0;
    let totalulti = 0;
    let totalvar = 0;
    let ult = 0;
    let ulti = 0;
    let totul = 0;
    let totva = 0;
    this.reportValues.forEach(element => {
      const result = element;

      if (result.groupTotal == 3) {
        if (result.groupName == val.groupName) {
          ultimate = element.ultimate;
          element.variance = element.budget - element.ultimate;
          ult += ultimate;
        }
        if (result.groupName != val.groupName) {
          ultimate = element.ultimate;
          element.variance = element.budget - element.ultimate;
          ult += ultimate;
        }
      }

      if (result.groupName == 'TOTAL PRINT CAMPAIGN') {
        totalcreativeulti = element.ultimate;
        totalcreativevar = element.variance;
      }

      if (result.groupName == 'TOTAL A/V CAMPAIGN') {
        element.ultimate = ult;
        element.variance = element.budget - element.ultimate;
        totalcreativeulti += element.ultimate;
        totalcreativevar += element.variance;
      }

      if (result.groupName == 'TOTAL CREATIVE ADVERTISING') {
        result.ultimate = totalcreativeulti;
        result.variance = totalcreativevar;
        totalulti += totalcreativeulti;
        totalvar += totalcreativevar;
      }

      if (result.groupTotal == 10) {

        if (result.groupName == val.groupName) {
          ultimate = element.ultimate;
          element.variance = element.budget - element.ultimate;
          ulti += ultimate;
        }
        if (result.groupName != val.groupName) {
          ultimate = element.ultimate;
          element.variance = element.budget - element.ultimate;
          ulti += ultimate;
        }
      }
      if (result.groupName == 'TOTAL OTHER ') {
        element.ultimate = ulti;
        element.variance = element.budget - element.ultimate;
        totalulti += element.ultimate;
        totalvar += element.variance;
      }

      if (result.groupName == 'TOTAL PUBLICITY/ PROMOTION') {
        totalulti += element.ultimate;
        totalvar += element.variance;
      }
      if (result.groupName == 'TOTAL MARKET RESEARCH') {
        totalulti += element.ultimate;
        totalvar += element.variance;
      }
      if (result.groupName == 'TOTAL BASICS') {
        element.ultimate = totalulti;
        element.variance = totalvar;
      }

      if (result.groupName == 'TOTAL MEDIA') {
        totul = element.ultimate;
        totva = element.variance;
      }


      if (result.groupName == 'TOTAL MARKETING') {
        element.ultimate = totalulti + totul;
        element.variance = totalvar + totva;
      }

    });
  }

  getVarianceTotal(val: any) {    
    let variance = 0;    
    let totalcreativevar = 0;    
    let totalvar = 0;
    let vari = 0;
    let varia = 0;    
    let totva = 0;
    this.reportValues.forEach(element => {
      const result = element;

      if (result.groupTotal == 3) {
        if (result.groupName == val.groupName) {
          variance = element.variance;
          vari += variance;
        }
        if (result.groupName != val.groupName) {
          variance = element.variance;
          vari += variance;
        }
      }

      if (result.groupName == 'TOTAL PRINT CAMPAIGN') {        
        totalcreativevar = element.variance;
      }

      if (result.groupName == 'TOTAL A/V CAMPAIGN') {        
        element.variance = vari;        
        totalcreativevar += element.variance;
      }

      if (result.groupName == 'TOTAL CREATIVE ADVERTISING') {        
        result.variance = totalcreativevar;        
        totalvar += totalcreativevar;
      }

      if (result.groupTotal == 10) {

        if (result.groupName == val.groupName) {          
          element.variance = element.variance;
          varia += element.variance;
        }
        if (result.groupName != val.groupName) {
          element.variance = element.variance;
          varia += element.variance;
        }
      }
      if (result.groupName == 'TOTAL OTHER ') {
        element.variance = varia;       
        totalvar += element.variance;
      }

      if (result.groupName == 'TOTAL PUBLICITY/ PROMOTION') {        
        totalvar += element.variance;
      }
      if (result.groupName == 'TOTAL MARKET RESEARCH') {        
        totalvar += element.variance;
      }
      if (result.groupName == 'TOTAL BASICS') {        
        element.variance = totalvar;
      }

      if (result.groupName == 'TOTAL MEDIA') {        
        totva = element.variance;
      }


      if (result.groupName == 'TOTAL MARKETING') {        
        element.variance = totalvar + totva;
      }

    });
  }

  exportexcelClick(fileFormat) {
    this.isShow = true;
    this.apiService.getFlashSummaryReportById(fileFormat)
      .subscribe(response => {

        if (fileFormat == 'Excel') {
          const a = document.createElement('a');
          a.href = response.res;
          a.target = "blank";
          a.download = 'FlashSummaryReport' + Date.now + '.xlsx';
          a.click();
          this.isShow = false;
          this.toastr.success('Flash Summary Downloaded Successfully');
        }
        else {
          const a = document.createElement('a');
          a.href = response.res;
          a.target = "blank";
          a.download = 'FlashSummaryReport' + Date.now + '.xlsx';
          a.click();
          this.isShow = false;
          this.toastr.success('Flash Summary Downloaded Successfully');
        }
      });
  }

}


