import { NgModule } from '@angular/core';

import {PickListModule} from 'primeng/picklist';
import {FileUploadModule} from 'primeng/fileupload';
import {DialogModule, Dialog} from 'primeng/dialog';
import {MenubarModule} from 'primeng/menubar';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {InputTextModule} from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { TreeTableModule, SharedModule, ConfirmationService } from 'primeng/primeng';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
@NgModule({
  exports: [
    TableModule,
    TreeTableModule,
    SharedModule,    
    PickListModule,
    InputTextModule,
    FileUploadModule,
    DialogModule,
    DropdownModule,
    MultiSelectModule,
    MenubarModule,
    OverlayPanelModule,
    ConfirmDialogModule
  ],
  providers: [
    ConfirmationService ],
})
export class PrimeNgModule {}
