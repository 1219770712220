import { Routes, CanActivate, RouterModule } from '@angular/router';

import { ModuleWithProviders } from '@angular/core';
import {AppHeaderComponent } from './components/app-header/app-header.component';
import { GenredashboardResultComponent } from './modules/genredashboardResult/genredashboardResult.component';
import { AddUserComponent } from '../app/modules/adduser/adduser.component';
import { ManageUsersComponent} from '../app/modules/manageusers/manageusers.component';
import { ManageGroupsComponent} from '../app/modules/managegroups/managegroups.component';
import { PopupGroupsComponent } from './modules/popupgroups/popupgroups.component';
import { ReportGenerationComponent } from './modules/Reportgeneration/reportgeneration.component';
import { DomestictheatricalComponent } from './modules/domestictheatrical/domestictheatrical.component';
import { AddUserClickComponent } from '../app/modules/adduserclick/adduserclick.component';
import { ExecutivereportComponent } from './modules/executivereport/executivereport.component';
import { MediaSummaryReportComponent } from './modules/mediasummaryreport/mediasummaryreport.component';
import { DepartmentSummaryReportComponent} from './modules/departmentsummary/departmentsummary.component';
import { authManager } from './service/authManager';
import { SchemacategoryComponent } from '../app/modules/schemacategory/schemacategory.component'
import {ViewAllReportComponent} from './modules/viewallreport/viewallreport.component';
import { UserAuthErrorComponent } from './modules/loginerror/user.autherror.component';
import { PublishReportTestComponent } from './modules/publishreportstest/publishreportstest.component';

export const routes: Routes = [
    { path: '', redirectTo:'flashreport',pathMatch: 'full' },
    //{ path: '', component: GenredashboardResultComponent },
    { path: 'flashreport', component: GenredashboardResultComponent,canActivate:[authManager] },
    { path: 'adduser', component: AddUserComponent,canActivate:[authManager] },
    { path: 'manageusers', component: ManageUsersComponent,canActivate:[authManager]},
    { path: 'managegroups', component: ManageGroupsComponent,canActivate:[authManager]},
    { path: 'adhocreports', component: SchemacategoryComponent,canActivate:[authManager]},
    { path: 'popupgroups', component: PopupGroupsComponent,canActivate:[authManager]},
    { path: 'viewflashreport', component: ReportGenerationComponent,canActivate:[authManager]},
    { path: 'adduserclick', component: AddUserClickComponent,canActivate:[authManager]},
    { path: 'slatesummary', component: DomestictheatricalComponent,canActivate:[authManager]},
    { path: 'flashsummary',component:ExecutivereportComponent,canActivate:[authManager]},
    { path: 'mediasummary', component:MediaSummaryReportComponent,canActivate:[authManager]},
    { path: 'departmentsummary', component:DepartmentSummaryReportComponent,canActivate:[authManager]},
    { path: 'viewreports', component: ViewAllReportComponent,canActivate:[authManager] },
    { path: 'publishreportstest', component: PublishReportTestComponent,canActivate:[authManager] },
    { path: 'autherror', component: UserAuthErrorComponent, pathMatch: 'full'},
    { path: 'flash-report', loadChildren: () => import('./modules/flash-report/flash-report.module').then(m => m.FlashReportModule) },
    // otherwise redirect to home

    { path: '**', redirectTo: '' }
];
export const routing: ModuleWithProviders = RouterModule.forChild(routes);
