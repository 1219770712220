import { Component, OnInit, ViewChild, ElementRef, TemplateRef, Output, EventEmitter } from '@angular/core';
import { Http } from '@angular/http';
import { MatDialogRef } from '@angular/material';
import { ApiService } from '../../service/apiCall.service';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { AwsCommonService } from '../../service/aws-common.service';
import { element } from 'protractor';

@Component({
  selector: 'add-userclick',
  templateUrl: './adduserclick.component.html',
  styleUrls: ['./adduserclick.component.scss']
})

export class AddUserClickComponent {
  showGrid: any;
  showErrorDialogPopup: boolean = false;
  validationMsg: any = [];
  searchValue0: string = '';
  searchValue1: string = '';
  searchValue2: string = '';
  searchValue3: string = '';
  searchValue4: number = 0;
  reportValues: any = [];
  users: any = [];
  userDetails: any = [];
  searchFilter: any = [];
  SearchSSO: any;
  SearchFullName: any;
  SearchBusinessUnit: any;
  ManageUsers: any = [];
  isShow = false;
  result: any;
  constructor(private _http: Http, private apiService: ApiService, private toastr: ToastrService,
    public dialogRef: MatDialogRef<AddUserClickComponent>,
    private awsCommonService: AwsCommonService) {
    this.ManageUsers = [];
  }

  clearSearch() {
    this.searchValue0 = "";
    this.searchValue1 = "";
    this.searchValue2 = "";
    this.searchValue3 = "";
    this.searchValue4 = 0;
    this.validationMsg = [];
    this.ManageUsers = [];
  }

  saveUser() {
    this.isShow = true;
    let userAccess = 0;
    let finalUser = [];
    let user = [];
    user = this.users.filter(x => x.selected === true);
    if (this.searchValue4) {
      userAccess = 1;
    }
    if (user.length == 0) {
      this.validationMsg = [];
      this.validationMsg.push('Select option to save');
      this.isShow = false;
    }
    user.forEach(element => {
      finalUser.push({
        SSO: element.SearchSSO,
        RoleId: 1,
        FullName: element.SearchFullName,
        FirstName: element.GivenName,
        LastName: element.FamilyName,
        BusinessUnitId: 1,
        Email: element.SearchEmail,
        ActiveFlag: 1,
        UserAccess: userAccess
      });
    });
    this.apiService.userAlreadyExist(finalUser[0].SSO).subscribe(res => {
      this.result = res;
      if (this.result == 1) {
        this.validationMsg = [];
        this.isShow = false;
        this.validationMsg.push('User Already Exist');
      }
      else {
        if (finalUser[0].Email != "") {
          this.apiService.AddusersintoNDIGroup(this.userDetails.access_token, finalUser[0].SSO, finalUser[0].UserAccess).subscribe(res => {            
          },
          err => {
            this.isShow = false;
            this.showErrorDialogPopup = true;          
          },
          () => {
          }
        );
          this.apiService.saveUser(finalUser).subscribe(x => {
            this.isShow = false;
            this.toastr.success('User Added Successfully');
          },
          err => {
            this.isShow = false;
            this.showErrorDialogPopup = true;          
          },
          () => {
          }
        );
        }
        else {
          this.isShow = false;
          this.toastr.error('User - ' + finalUser[0].SSO + ' not added. Mailbox not configured.');
        }
      }
    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }

  saveUsers() {
    this.isShow = true;
    let userAccess = 0;
    let finalUser = [];
    let user = [];
    user = this.users.filter(x => x.selected === true);
    if (this.searchValue4) {
      userAccess = 1;
    }
    if (user.length == 0) {
      this.validationMsg = [];
      this.validationMsg.push('Select option to save');
      this.isShow = false;
    }
    user.forEach(element => {
      finalUser.push({
        SSO: element.SearchSSO,
        RoleId: 1,
        FullName: element.SearchFullName,
        FirstName: element.GivenName,
        LastName: element.FamilyName,
        BusinessUnitId: 1,
        Email: element.SearchEmail,
        ActiveFlag: 1,
        UserAccess: userAccess
      });
    });
    this.apiService.userAlreadyExist(finalUser[0].SSO).subscribe(res => {
      this.result = res;
      if (this.result == 1) {
        this.isShow = false;
        this.validationMsg = [];
        this.validationMsg.push('User Already Exist');
      }
      else {
        if (finalUser[0].Email != "") {
          this.apiService.AddusersintoNDIGroup(this.userDetails.access_token, finalUser[0].SSO, finalUser[0].UserAccess).subscribe(res => {            
          },
          err => {
            this.isShow = false;
            this.showErrorDialogPopup = true;          
          },
          () => {
          }
        );
          this.apiService.saveUser(finalUser).subscribe(x => {
            this.isShow = false;
            this.dialogRef.close();
            this.toastr.success('User Added Successfully');
          },
          err => {
            this.isShow = false;
            this.showErrorDialogPopup = true;          
          },
          () => {
          }
        );
        }
        else {
          this.isShow = false;
          this.toastr.error('User - ' + finalUser[0].SSO + ' not added. Mailbox not configured.');
        }
      }
    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }

  checkIfSelected(manageUser) {
    if (manageUser.selected == true) {
      this.users = [];
      this.users.push(manageUser);
    }
    else {
      this.users = [];
      this.users = this.users.filter(x => x.SearchSSO != manageUser.SearchSSO);
    }
  }

  clearHide() {
    this.validationMsg = [];
    // if (this.searchValue0 == "" && this.searchValue1 == "" && this.searchValue2 == "" && this.searchValue3 == "") {
    //   this.validationMsg.push('Please provide the valid input');
    //   return true;
    // }
    this.saveUser();
    this.searchValue0 = "";
    this.searchValue1 = "";
    this.searchValue2 = "";
    this.searchValue3 = "";
  }

  saveclose() {
    this.validationMsg = [];
    this.saveUsers();
    this.searchValue0 = "";
    this.searchValue1 = "";
    this.searchValue2 = "";
    this.searchValue3 = "";
  }

  getUser() {
    this.isShow = true;

    this.apiService.getUsers().subscribe(x => {
      this.users = x;
      this.users.forEach(element => {
        if (element.userAccess == 1) {
          element.userAccess = 'Yes';
        }
        else {
          element.userAccess = 'No';
        }
      });
    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
    this.isShow = false;
  }

  getSearchResult() {
    this.isShow = true;
    let searchFilter: any = [];
    this.validationMsg = [];
    if (this.searchValue0 === '' && this.searchValue1 === '' && this.searchValue2 === '') {
      this.isShow = false;
      this.validationMsg.push('Enter atleast one field to search');
    }
    if (this.validationMsg.length > 0) {
      return true;
    }
    this.showGrid = true;

    if (this.searchValue0 == "") {
      this.searchValue0 = "NotApplicable";
    }
    if (this.searchValue1 == "") {
      this.searchValue1 = "NotApplicable";
    }
    if (this.searchValue2 == "") {
      this.searchValue2 = "NotApplicable";
    }

    this.apiService.getMasheryAccessToken().subscribe((result: any) => {
      this.userDetails = result;
      if (this.searchValue0 == "") {
        this.searchValue0 = "NotApplicable";
      }
      if (this.searchValue1 == "") {
        this.searchValue1 = "NotApplicable";
      }
      if (this.searchValue2 == "") {
        this.searchValue2 = "NotApplicable";
      }
      this.apiService.getUserDetailswithToken(this.userDetails.access_token, this.searchValue2, this.searchValue0, this.searchValue1).subscribe((res: any) => {
        this.reportValues = res;        
        this.validationMsg = [];
        this.ManageUsers = [];
        this.isShow = false;        
        if (this.reportValues.Resources != undefined) {
          if (this.reportValues.Resources.length == 0) {
            this.toastr.error('User not found');
            if (this.searchValue0 == "NotApplicable") {
              this.searchValue0 = "";
            }
            if (this.searchValue1 == "NotApplicable") {
              this.searchValue1 = "";
            }
            if (this.searchValue2 == "NotApplicable") {
              this.searchValue2 = "";
            }
          }         
        }
        else if(this.reportValues.Resources == undefined && this.reportValues.id == undefined) 
        {
          this.toastr.error('User not found');
          if (this.searchValue0 == "NotApplicable") {
            this.searchValue0 = "";
          }
          if (this.searchValue1 == "NotApplicable") {
            this.searchValue1 = "";
          }
          if (this.searchValue2 == "NotApplicable") {
            this.searchValue2 = "";
          }
        }
        else
        {          
          if (this.searchValue0 == "NotApplicable") {
            this.searchValue0 = "";
          }
          if (this.searchValue1 == "NotApplicable") {
            this.searchValue1 = "";
          }
          if (this.searchValue2 == "NotApplicable") {
            this.searchValue2 = "";
          }
        }
        if (this.reportValues.errorCode === undefined) {
          // if(this.reportValues.emails === undefined)
          // {
          //   this.validationMsg.push('No Email ID found for your SSO. Please config and search again');
          // }
          if (this.searchValue2 != 'NotApplicable' && this.searchValue2 != "") {
            this.ManageUsers = [];
            this.ManageUsers.push(
              {
                SearchSSO: this.reportValues.userName,
                SearchFullName: this.reportValues.name.formatted,
                FamilyName: this.reportValues.name.familyName,
                GivenName: this.reportValues.name.givenName,
                SearchBusinessUnit: this.reportValues.custom_hrdepartment,
                SearchEmail: this.reportValues.emails != undefined ? this.reportValues.emails[0].value : '',
              });
            if (this.searchValue0 == "NotApplicable") {
              this.searchValue0 = "";
            }
            if (this.searchValue1 == "NotApplicable") {
              this.searchValue1 = "";
            }
            if (this.searchValue2 == "NotApplicable") {
              this.searchValue2 = "";
            }
          }
          else {
            if (this.reportValues.Resources.length > 0) {
              this.ManageUsers = [];
              this.validationMsg = [];
              // if(this.reportValues.emails === undefined)
              // {
              //   this.validationMsg.push('No EmailId found for your SSO. Please config and search again');
              // }
              this.reportValues.Resources.forEach(element => {
                // if(element.emails === undefined)
                // {
                //   //this.validationMsg = [];
                //   this.validationMsg.push('No Email ID found for this ' + element.userName + '. Please config and search again');
                // }
                // if(element.emails != undefined)
                // {
                this.ManageUsers.push(
                  {
                    SearchSSO: element.userName,
                    SearchFullName: element.name.formatted,
                    FamilyName: element.name.familyName,
                    GivenName: element.name.givenName,
                    SearchBusinessUnit: element.custom_hrdepartment,
                    SearchEmail: element.emails != undefined ? element.emails[0].value : '',
                  });
                // }
              });
              if (this.searchValue0 == "NotApplicable") {
                this.searchValue0 = "";
              }
              if (this.searchValue1 == "NotApplicable") {
                this.searchValue1 = "";
              }
              if (this.searchValue2 == "NotApplicable") {
                this.searchValue2 = "";
              }
            }
          }
        }
      },
      err => {
        this.isShow = false;
        this.showErrorDialogPopup = true;          
      },
      () => {
      }
    );
    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }

  FilterResult(reportValues) {
    this.searchFilter = [];

    this.searchFilter = reportValues;
    this.searchFilter.forEach(element => {
      this.ManageUsers.push(
        {
          SearchSSO: element.userName,
          SearchFullName: element.name.formatted,
          SearchBusinessUnit: element.custom_hrdepartment
        });
    });

  }
  settings = {
    "mode": "inline",
    "hideSubHeader": true,
    "actions":
    {
      "position": "right",
    },
    "add": {
      "inputClass": "form-control input-sm",
      "addButtonContent": "<i class='material-icons'>person_add</i>",
      "createButtonContent": "<i class='material-icons'>save</i>",
      "cancelButtonContent": "<i class='material-icons'>cancel</i>",
      //"confirmCreate": true
    }, "edit": {
      "inputClass": "form-control input-sm",
      "editButtonContent": "<i class='material-icons'>edit</i>",
      "saveButtonContent": "<i class='material-icons'>save</i>",
      "cancelButtonContent": "<i class='material-icons'>cancel</i>",
      //"confirmSave": true
    },
    "delete": {
      "deleteButtonContent": "<i class='material-icons'>delete</i>",
      //"confirmDelete": true
    },
    columns: {
      Fullname: {
        title: 'FullName',
        class: "{color:white}"
      },
      SSO: {
        title: 'SSO',
        class: "{color:white}"
      },
      BusinessUnit: {
        title: 'Business Unit',
        class: "{color:white}"
      },
    }
  };
}