import { Injectable } from '@angular/core';
import { HttpRequest } from 'aws-sdk';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import * as aws4 from 'ngx-aws4';
import { authManager } from '../service/authManager';
import { Buffer } from 'buffer';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AwsSignonService {
  secretAccessKey: any;
  accessKeyId: any;
  sessionToken: any;
  public hostname: any;
  constructor(private http: HttpClient) {
  }

  apiGetRequest(apiURL: any, method: any, params?, options?) {      
    this.hostname = 'https://' + environment.hostName;
    const path = apiURL.split(this.hostname)[1];
    let request = {
      hostname: environment.hostName,
      service: environment.service,
      region: environment.region,
      method: method,
      url: apiURL, // this field is not recommended in the document.
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options),
      params: params,
      path: path
    }    
    this.secretAccessKey = sessionStorage.getItem('secretAccessKey');
    this.accessKeyId = sessionStorage.getItem('accessKeyId');
    this.sessionToken = sessionStorage.getItem('sessionToken');
    let signedRequest = aws4.sign(request, {
      secretAccessKey: this.secretAccessKey,
      accessKeyId: this.accessKeyId,
      sessionToken: this.sessionToken
    });    
    delete signedRequest.headers['Host'];
    if (method == 'GET') {
      return this.http.get(signedRequest.url, signedRequest);
    } else if (method == 'POST') {
      return this.http.post(signedRequest.url, signedRequest.body, signedRequest);
    } else if (method == 'PUT') {
      return this.http.put(signedRequest.url, signedRequest.body, signedRequest);
    } else if (method == 'DELETE') {
      return this.http.delete(signedRequest.url, signedRequest);
    }
  }

  apiRequest(apiURL: any, method: any, options?) {       
    this.hostname = 'https://' + environment.hostName;
    const path = apiURL.split(this.hostname)[1];
    let request = {
      hostname: environment.hostName,
      service: environment.service,
      region: environment.region,
      verb: method,
      url: apiURL,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(options),
      path: path
    }
    this.secretAccessKey = sessionStorage.getItem('secretAccessKey');
    this.accessKeyId = sessionStorage.getItem('accessKeyId');
    this.sessionToken = sessionStorage.getItem('sessionToken');
    let signedRequest = aws4.sign(request, {
      secretAccessKey: this.secretAccessKey,
      accessKeyId: this.accessKeyId,
      sessionToken: this.sessionToken
    });    
    delete signedRequest.headers['Host'];
    if (method === 'GET') {
      return this.http.get(signedRequest.url, signedRequest);
    } else if (method === 'POST') {
      return this.http.post(signedRequest.url, signedRequest.body, signedRequest);
    } else if (method === 'PUT') {
      return this.http.put(signedRequest.url, signedRequest.body, signedRequest);
    } else if (method === 'DELETE') {
      return this.http.delete(signedRequest.url, signedRequest.body);
    }
  }
}
