import { Directive, Input, HostListener } from '@angular/core';
import { Table } from 'primeng/table'
@Directive({
  selector: '[pAddRow]'
})
export class PAddRowDirective {
  @Input() table: Table;
  @Input() newRow: any;

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    
    let index: number = 0;

    //cloning the new row, so that everytime a new row is added
    let row= {...this.newRow};

    //inserting a new row
    this.table.value.splice(index, 0, row);

    //set the new row in edit mode
    this.table.initRowEdit(this.newRow);
    event.preventDefault();
  }

}
