
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, EventEmitter, Output } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { AwsCommonService } from '../service/aws-common.service';
import { race } from 'rxjs';
import { group } from '@angular/animations';

@Injectable()

export class ApiService {
    @Output()
    public change = new EventEmitter();
    constructor(private http: HttpClient, private awsCommonService: AwsCommonService) { }

    apiUrl = environment.apiUrl;
    getUsers() {
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/GetUser', 'GET');
    }
    getUsersById(ids: any) {        
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/GetUserById/' + ids, 'GET');
    }
    getTitles() {
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/Title', 'GET');
    }

    getModifiedTitle(){
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/ModifiedTitles', 'GET');
    }

    getWBSElement(val: any) {        
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/WBS/' + val, 'GET');
    }

    getFlashReportDataById(titleId: any) {                
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/Flash/Title/' + titleId, 'GET');
    }
    getTitlesPermission() {
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/GetTitlePermission', 'GET');
    }
    saveTitlePermission(titlePermission: any) {        
        return this.awsCommonService.postRequest(this.apiUrl + 'Report/SaveTitlePermission/', 'POST', titlePermission);
    }
    getGroup() {
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/GetGroup', 'GET');
    }
    getDepartment() {
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/Department', 'GET');
    }
    saveGroupPermission(groupPermission: any) {        
        return this.awsCommonService.postRequest(this.apiUrl + 'Report/SaveGroupPermission', 'POST', groupPermission);
    }
    getGroupPermission(groupID: any) {        
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/GetGroupPermission/' + groupID, 'GET');
    }
    getSourceGroupPermission(groupID: any) {        
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/GetSourceGroupPermission/' + groupID, 'GET');
    }
    saveUser(users: any) {        
        return this.awsCommonService.postRequest(this.apiUrl + 'Report/SaveUser', 'POST', users);
    }
    deleteUser(users: any) {        
        return this.awsCommonService.postRequest(this.apiUrl + 'Report/DeleteUser','POST', users);
    }
    updateUser(users: any) {        
        return this.awsCommonService.postRequest(this.apiUrl + 'Report/UpdateUser','POST', users);
    }
    saveFinalReport(finalResult: any) {        
        return this.awsCommonService.postRequest(this.apiUrl + 'Report/SaveFinalResult','POST', finalResult);
    }
 getFlashReportLastSavedDateById(titleId: any) {                
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/Flash/Title/LastSavedDate/' + titleId, 'GET');
    }
   
    getFlashReportById(titleId: any) {        
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/Flash/Title/Export/' + titleId, 'GET');
    }
    importSlateSummaryReport(file: any) {        
        return this.awsCommonService.postRequest(this.apiUrl + 'Report/ImportSlateSummary','POST', file);
    }
    publishedSlateSummaryReport(slatesummaryresult: any) {        
        return this.awsCommonService.postRequest(this.apiUrl + 'Report/PublishSlateSummaryResult','POST', slatesummaryresult);
    }
    publishedReport(finalResult: any) {        
        return this.awsCommonService.postRequest(this.apiUrl + 'Report/PublishResult','POST', finalResult);
    }
    importMediaSummary(file: any) {        
        return this.awsCommonService.postRequest(this.apiUrl + 'Report/uploadmedia','POST', file);
    }
    publishedMediaSummaryReport(mediasummaryresult: any) {        
        return this.awsCommonService.postRequest(this.apiUrl + 'Report/PublishMediaSummaryResult','POST', mediasummaryresult);
    }
    saveTitleSummaryReport(titleSummary: any) {        
        return this.awsCommonService.postRequest(this.apiUrl + 'Report/SaveTitleSummary','POST', titleSummary);
    }
    publishedTitleSummaryReport(titleSummary: any) {        
        return this.awsCommonService.postRequest(this.apiUrl + 'Report/PublishTitleSummaryResult','POST', titleSummary);
    }
     getFlashSummaryLastSavedDateById(titleId: any) {                
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/FlashSummary/Title/LastSavedDate/' + titleId, 'GET');
    }
    getMediaSummaryReportTitle() {
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/GetMediaSummaryTitle', 'GET');
    }
    saveDepartmentSummaryReport(departmentSummary: any) {        
        return this.awsCommonService.postRequest(this.apiUrl + 'Report/SaveDepartmentSummary', 'POST',departmentSummary);
    }
    publishedDepartmentSummaryReport(departmentSummary: any) {        
        return this.awsCommonService.postRequest(this.apiUrl + 'Report/PublishDepartmentSummaryResult','POST', departmentSummary);
    }
    getSlateSummary() {
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/GetSlateSummary', 'GET');
    }
    getMediaSummary() {
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/GeMediaSummary', 'GET');
    }

    publishAll() {
        return this.awsCommonService.postRequest(this.apiUrl + 'Report/PublishAll', 'POST','');
    }

    publishSlateNMedia() {
        return this.awsCommonService.postRequest(this.apiUrl + 'Report/publishSlateNMedia', 'POST','');
    }

    publishTitle() {
        return this.awsCommonService.postRequest(this.apiUrl + 'Report/publishTitle', 'POST','');
    }

    publishDepartment(){
        return this.awsCommonService.postRequest(this.apiUrl + 'Report/publishDepartment', 'POST',''); 
    }

    publishSlateNMediaFiles() {
        return this.awsCommonService.postRequest(this.apiUrl + 'Report/publishSlateNMediaFiles', 'POST','');
    }

    getSchemaCategory() {
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/GetSchemaCategory','GET');
    }

    getSchemaCategorybyId(groupId) {
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/GetSchemaCategorybyId/' + groupId,'GET');
    }

  

    getGroupDetailsById(id) {
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/GetGroupDetails/' + id, 'GET');
    }

    getgroupSchema(){
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/GetGroupSchema', 'GET')
    }

    saveSchemaCategory(schemaCateogry: any) {        
        return this.awsCommonService.postRequest(this.apiUrl + 'Report/SaveSchemaCategory','POST', schemaCateogry);
    }
    deleteSchemaCategory(schemaCateogry: any) {        
        return this.awsCommonService.postRequest(this.apiUrl + 'Report/DeleteSchemaCategory', 'POST', schemaCateogry);
    }
    updateSchemaCategory(schemaCateogry: any) {        
        return this.awsCommonService.postRequest(this.apiUrl + 'Report/UpdateSchemaCategory', 'POST', schemaCateogry);
    }
    saveGroupName(groupName: any) {             
        return this.awsCommonService.postRequest(this.apiUrl + 'Report/SaveGroupName/groupName', 'POST', groupName);
    }

    editGroupName(groupName: any, groupId: any){
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/EditGroupName/'+ groupName + '/' + groupId, 'GET');
    }

    getFinalCommitmentDate() {
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/GetFinalCommitmentDate', 'GET');
    }

    getReportById(titleId: any, fileFormat: any) {             
        return this.awsCommonService.getApiRequest(this.apiUrl + 'View/FlashReport/' + titleId + '/' + fileFormat, 'GET')       
    }

    getSlateSummaryReportById(fileFormat: any) {
        return this.awsCommonService.getApiRequest(this.apiUrl + 'View/Slate/' + fileFormat, 'GET')           
    }

    getMediaSummaryReportById(fileFormat: any) {
        return this.awsCommonService.getApiRequest(this.apiUrl + 'View/Media/' + fileFormat, 'GET')
    }

    getFlashSummaryReportById(fileFormat: any) {
        return this.awsCommonService.getApiRequest(this.apiUrl + 'View/Flash/' + fileFormat, 'GET')          
    }

    getDepartmentSummaryReportById(fileFormat: any) {
        return this.awsCommonService.getApiRequest(this.apiUrl + 'View/Department/' + fileFormat,'GET')         
    }

    getNonAdminReportById(fileFormat: any, date: any) {

        return this.awsCommonService.getApiRequest(this.apiUrl + 'View/All/' + fileFormat + "/" + sessionStorage.getItem('SSO') + "/" + date, 'GET')           
    }

    getGeneratedPDF(path: any) {
        return this.awsCommonService.getApiRequest(this.apiUrl + 'View/GeneratePDF/' + path, 'GET')        
    }

    getGeneratedPDFMail(path: any) {
        return this.awsCommonService.getApiRequest(this.apiUrl + 'View/GeneratePDF/Mail/' + path, 'GET')        
    }

    getNonAdminInsertedDateById() {
        return this.awsCommonService.getApiRequest(this.apiUrl + 'View/InsertedDate/', 'GET')
    }

    getNonAdminInsertedMonthById(Years: any) {        
        return this.awsCommonService.getApiRequest(this.apiUrl + 'View/InsertedMonth/' + Years,'GET')
    }
    getNonAdminInsertedDayById(Months: any, Years: any) {
        return this.awsCommonService.getApiRequest(this.apiUrl + 'View/InsertedDay/' + Months + "/" + Years, 'GET')
    }
    getReleaseDate(date: any, titleId: any) {
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/ReleaseDate/' + date + "/" + titleId, 'GET')
    }
    getRefreshedData() {
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/RefreshedData/', 'GET')
    }

    refresh(){
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/Refresh/', 'GET')
    }

    refreshvaluecheck(){
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/RefreshValueCheck/', 'GET')
    }

    publishvaluecheck(){
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/PublishValueCheck/', 'GET')
    }

    getPendingConfirmationMailList() {
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/PendingSubscriptionMail', 'GET')
    }

    getMasheryAccessToken(){
        return this.awsCommonService.postRequest(this.apiUrl + 'Report/Users/AccessToken', 'POST' ,'')
    }

    getUserDetailswithToken(accessToken : any, sso: any, firstName: any, lastName: any){
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/Users/GetUserDetails/' + accessToken + "/" + sso + "/" + firstName + "/" + lastName , 'GET')
    }

    AddusersintoNDIGroup(accessToken: any, SSO: any, AdminAccess: any){
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/Users/AddUserGroup/' + accessToken + "/" + SSO + "/" + AdminAccess, 'GET')
    }

    RemoveusersfromNDIGroup(accessToken: any, SSO: any, AdminAccess: any){
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/Users/RemoveUserGroup/' + accessToken + "/" + SSO + "/" + AdminAccess, 'GET')
    }

    userAlreadyExist(SSO: any){
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/Users/Exists/' + SSO, 'GET')
    }

    deleteGroup(table : any){
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/DeleteGroup/' + table, 'GET')
    }

    getVendorGLList(){
        return this.awsCommonService.getApiRequest(this.apiUrl + 'Report/VendorGLList','GET')
    }

    gettestNonAdminReportById(fileFormat: any, date: any) {

        return this.awsCommonService.getApiRequest(this.apiUrl + 'View/test/All/' + fileFormat + "/" + sessionStorage.getItem('SSO') + "/" + date, 'GET')           
    }

    gettestGeneratedPDF(path: any) {
        return this.awsCommonService.getApiRequest(this.apiUrl + 'View/test/GeneratePDF/' + path, 'GET')        
    }

    gettestGeneratedPDFMail(path: any) {
        return this.awsCommonService.getApiRequest(this.apiUrl + 'View/test/GeneratePDF/Mail/' + path, 'GET')        
    }

    gettestNonAdminInsertedDateById() {
        return this.awsCommonService.getApiRequest(this.apiUrl + 'View/test/InsertedDate/', 'GET')
    }

    gettestNonAdminInsertedMonthById(Years: any) {        
        return this.awsCommonService.getApiRequest(this.apiUrl + 'View/test/InsertedMonth/' + Years,'GET')
    }
    gettestNonAdminInsertedDayById(Months: any, Years: any) {
        return this.awsCommonService.getApiRequest(this.apiUrl + 'View/test/InsertedDay/' + Months + "/" + Years, 'GET')
    }

    testpublishAll() {
        return this.awsCommonService.postRequest(this.apiUrl + 'Report/test/PublishAll', 'POST','');
    }

    testpublishSlateNMedia() {
        return this.awsCommonService.postRequest(this.apiUrl + 'Report/test/publishSlateNMedia', 'POST','');
    }

    testpublishTitle() {
        return this.awsCommonService.postRequest(this.apiUrl + 'Report/test/publishTitle', 'POST','');
    }

    testpublishDepartment(){
        return this.awsCommonService.postRequest(this.apiUrl + 'Report/test/publishDepartment', 'POST',''); 
    }

    testpublishSlateNMediaFiles() {
        return this.awsCommonService.postRequest(this.apiUrl + 'Report/test/publishSlateNMediaFiles', 'POST','');
    }

}
