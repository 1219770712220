import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export class ExportService {

  constructor() { }

  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';

  public exportExcel(jsonData: any[], fileName: string, loopData: any): void {
    
    var wb = { SheetNames: [], Sheets: {} };
    loopData.forEach(element=> {         
      
      let  fiterdata = jsonData.filter(x=>x.titleId == element.titleId); 
      let sheetName: string;
      let newSheetName: string;      
      sheetName = element.titlename;
      if(element.titlename.length > 29)
      {
        newSheetName = sheetName.substring(0,29);
        wb.SheetNames.push(newSheetName);
        wb.Sheets[newSheetName] =XLSX.utils.json_to_sheet(fiterdata);
      }
      else
      {
        newSheetName =sheetName;
        wb.SheetNames.push(newSheetName);
        wb.Sheets[element.titlename] =XLSX.utils.json_to_sheet(fiterdata);
      }     
     
    
    });        
    const  excelBuffer: any = XLSX.write(wb, {bookType: 'xlsx', type: 'array' });
    
    this.saveExcelFile(excelBuffer,fileName);       
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    
    const data: Blob = new Blob([buffer], {type: this.fileType});
    FileSaver.saveAs(data, fileName + this.fileExtension);
    
  }
}