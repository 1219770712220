import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../../../../src/app/service/apiCall.service';
import * as XLSX from 'xlsx';
import { HttpClient } from '@angular/common/http';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { PublishDataService } from '../../service/publish-data.service';
import { Http } from '@angular/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'ngbd-modal-media',
  template: `
    <div id="myModal5" class="modal-header">
    <label style="margin-right:250px">Proceed to Publish report</label>
      <button type="button"  class="glyphicon glyphicon-remove" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      </button>      
      
    </div>
    <div class="modal-body">                
    <h6 class="modal-title">Do you want to publish and distribute ?</h6>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-success" routerLinkActive="active" data-dismiss="myModal" (click)="publish()">Publish</button>      
    </div>   
  `
})

export class NgbdModalContentMediaSummary {
  @Input() name;

  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal, private toastr: ToastrService,
    private publishmediaService: PublishDataService) {
    this.publishmediaService.listen().subscribe((m: any) => {

      if (m == 'ClosePopup') {
        activeModal.dismiss('Publish');
      }
    })
  }

  publish() {

    this.activeModal.close();
    this.publishmediaService.filter('Publish');
  }
}

type AOA = any[][];

@Component({
  selector: 'app-mediasummaryreport',
  templateUrl: './mediasummaryreport.component.html',
  styleUrls: ['./mediasummaryreport.component.scss']
})
export class MediaSummaryReportComponent implements OnInit {
  /**Variable Declaration */
  fileData: File = null;
  title: any = '';  
  mediasummaryReport: any = [];
  data: AOA = [];
  excelNames: any[];
  titles: any = [];
  showErrorDialogPopup: boolean = false;
  constructor(private apiService: ApiService, private _http: Http, private http: HttpClient, private toastr: ToastrService
    , private publishmediaService: PublishDataService, private spinner: NgxSpinnerService, private modalService: NgbModal,
    private router: Router, private datePipe: DatePipe) {
    this.mediasummaryReport = [];
  }

  storeData: any;
  jsonData: any;
  filterdata: any;
  fileUploaded: File;
  worksheet: any;
  titleList: any;
  selectedVal: any;
  wbsElement: any;
  titleValues: any = [];
  isShow = false;
  secret: any;
  accessKey: any;
  secretKey: any;
  showMSErrorDialogPopup = false;
  private apiUrl = environment.apiUrl;
  private titleurl = this.apiUrl + 'Report/Title/';

  ngOnInit() {
    this.getMediaSummary();
  }

  getTitleList() {
    this.apiService.getMediaSummaryReportTitle().subscribe(x => {
      this.titleList = x;
    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }

  getSecretValue(file)
  {
    var AWS = require('aws-sdk'),
    region = environment.region,
    secretName = environment.Secret,
    decodedBinarySecret;

// Create a Secrets Manager client
var client = new AWS.SecretsManager({
    region: region,
    secretAccessKey : sessionStorage.getItem('secretAccessKey'),
    accessKeyId : sessionStorage.getItem('accessKeyId'), 
    sessionToken :  sessionStorage.getItem('sessionToken')
});


client.getSecretValue({SecretId: secretName}, function(err, data) {
    if (err) {
        if (err.code === 'DecryptionFailureException')           
            throw err;
        else if (err.code === 'InternalServiceErrorException')           
            throw err;
        else if (err.code === 'InvalidParameterException')          
            throw err;
        else if (err.code === 'InvalidRequestException')           
            throw err;
        else if (err.code === 'ResourceNotFoundException')           
            throw err;
            console.log(err);
    }
    else {
        // Decrypts secret using the associated KMS CMK.
        // Depending on whether the secret is a string or binary, one of these fields will be populated.
        if ('SecretString' in data) {
            this.secret = data.SecretString;
            let secretValue =  JSON.parse(this.secret);            
            this.accessKey = secretValue.accessKey;
            this.secretKey = secretValue.secretKey;
            if(this.secret != undefined)
    {
    const bucket = new S3(
      {
        accessKeyId: this.accessKey,
        secretAccessKey: this.secretKey,
        region: environment.region
      }
    );

    const params = {
      Bucket: environment.mediabucket,
      Key: "Media Summary.xlsx",
      Body: file.target.files[0],
      ACL: 'public-read',
      ContentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    bucket.upload(params, function (err, data) {

      if (err) {
        console.log('There was an error uploading your file: ', err);
        return false;
      }
      console.log('Successfully uploaded file.', data);
      return true;
    });
  }
        } else {
            let buff = new Buffer(data.SecretBinary, 'base64');
            decodedBinarySecret = buff.toString('ascii');
        }
    }
       
});
  }

  uploadFile(file) {
    const contentType = file.type;
    this.getSecretValue(file);
    
  }

  fileProgress(fileInput: any) {        
    this.uploadFile(fileInput);
    this.isShow = true;
    let validWBS:any = [];
    let valid = [];
    this.fileData = <File>fileInput.target.files[0];    
    const target: DataTransfer = <DataTransfer>(fileInput.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */

      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellDates: true });
      /* grab first sheet */
      const sheetCount = wb.Workbook.Sheets;
      let Finalres = [];
      let displayorder = 1;
      
      for (let i = 0; i < sheetCount.length; i++) {        
        const wsname: string = wb.SheetNames[i];
        const ws: any = wb.Sheets[wsname];
        /* save data */
        this.data = (<AOA>(XLSX.utils.sheet_to_json(ws, { dateNF: 'YYYY-MM-DD', header: 1 })));
        const Title = this.data[0];
        const wbsElement = (this.data[2])[0];
        validWBS.push(wbsElement);        
        const TitleId = 0;
        this.data.forEach(element => {               
          if (element.length > 0 && element[0] !== 'Description' 
          && element[1]  !== 'Budget' 
          && element[3]  !== 'Estimate To Complete' 
          && element[4]  !== 'Ultimate') {
            Finalres.push({
              ID: 0,
              Department: element[0],
              Budget: element[1],
              Actuals: element[2],
              EstimateToComplete: element[3],
              Ultimate: element[4],
              Variance: element[5],
              DisplayOrder: displayorder,
              Title: Title[0],
              WbsElement: wbsElement,
              TitleId: 0

            });
            displayorder = displayorder + 1;
          }
        });        
      }      
      validWBS.forEach(element => {                            
        if (element == undefined)
        {
          valid.push(element);          
        }   
        else
        {
          valid.push(element);
        }           
      });      
      if (this.fileData.name == "Media Summary.xlsx") { 
        
        let isValid:boolean = false;            
        for(let i=0;i< valid.length;i++)
        {
          if(valid[i] != undefined)
          {
            isValid = true;
          }
          else{
            isValid = false;
            break;
          }
        }
        if(isValid == true)
        {
        this.apiService.importMediaSummary(Finalres)
          .subscribe(res => {            
            this.jsonData = res;
            this.filterdata = this.jsonData;
            this.mediasummaryReport = this.jsonData;

            this.getTitleList();
            
            this.toastr.clear();
            this.toastr.success('Media Summary Report Imported Successfully');
			fileInput.target.value = '';
            if(this.toastr.previousToastMessage == 'Media Summary Report Imported Successfully')
            {
            this.apiService.getModifiedTitle().subscribe(res =>
              {                
                this.isShow = false;
              },
              err => {
                this.isShow = false;
                this.showErrorDialogPopup = true;          
              },
              () => {
              }
            );   
            } else {
              this.isShow = false;
            }
          },
          err => {
            this.isShow = false;
            this.showErrorDialogPopup = true;          
          },
          () => {
          }
        ); 
        }
        else {
          this.showMSErrorDialogPopup = true;
          this.isShow = false;
          fileInput.target.value = '';
        }
            
      }
      
      else {
        this.showMSErrorDialogPopup = true;
        this.isShow = false;
        fileInput.target.value = '';
      }

      
    };
    reader.readAsBinaryString(target.files[0]);
  }

  showMSErrorDialogPopupClose() {

    this.showMSErrorDialogPopup = false;
    this.fileData = null;    
    //window.location.reload();
  }

  onSubmit() {
    this.spinner.show();
    const formData = new FormData();
    formData.append('file', this.fileData);

  }

  open() {
    const modalRef = this.modalService.open(NgbdModalContentMediaSummary, { centered: true });
  }
  filtertitle(val) {    
    if (val == -1) {
      this.filterdata = null;
    }
    else {
      this.filterdata = this.jsonData.filter(x => x.title === val);      
      this.apiService.getWBSElement(val.replace('&', "-")).subscribe(result => {        
        this.wbsElement = result;
      });
    }
  }
  getMediaSummary() {
    this.apiService.getMediaSummary()
      .subscribe(res => {

        this.jsonData = res;
        (this.jsonData);
        if (this.jsonData.length > 0) {
          this.filterdata = this.jsonData;
          this.mediasummaryReport = this.jsonData;

          this.getTitleList();
        }
        else {
          this.jsonData = undefined;
        }
      });
  }

  publishData() {

    this.isShow = true;
    var data = [];
    this.jsonData.forEach(element => {
      data.push(
        {
          ID: element.id,
          Title: element.title,
          TitleId: element.titleID,
          GLCode: element.glCode,
          Department: element.department,
          Budget: element.budget,
          Actuals: element.actuals,
          EstimateToComplete: element.estimateToComplete,
          Ultimate: element.ultimate,
          Variance: element.variance,
          Order: element.order
        });
    });

    this.apiService.publishedMediaSummaryReport(this.jsonData).subscribe(x => {

      this.spinner.hide();
      this.getMediaSummary();
      this.titleList = [];
      this.wbsElement = '';
      this.jsonData = [];
      this.isShow = false;

      this.toastr.clear();
      this.toastr.success('Media Summary Report Published Successfully');
    },
      err => {
        this.isShow = false;
        this.toastr.success('Media Summary Report Published Successfully');
      },
      () => {
      }
    );
  }

  exportexcelClick(fileFormat) {
    this.isShow = true;
    this.apiService.getMediaSummaryReportById(fileFormat)
      .subscribe(response => {

        if (fileFormat == 'Excel') {
          const a = document.createElement('a');
          a.href = response.res;
          a.target = "blank";
          a.download = 'MediaSummary_' + Date.now + '.xlsx';
          a.click();
          this.isShow = false;
          this.toastr.success('Media Summary Downloaded Successfully');
        }
        else {
          const a = document.createElement('a');
          a.href = response.res;
          a.target = "blank";
          a.download = 'MediaSummary_' + Date.now + '.pdf';
          a.click();
          this.isShow = false;
          this.toastr.success('Media Summary Downloaded Successfully');
        }
      });
  }

}


