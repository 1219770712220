import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
import { ApiService } from '../../service/apiCall.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'popupgroups',
  templateUrl: './popupgroups.component.html',
  styleUrls: ['./popupgroups.component.scss']
})
export class PopupGroupsComponent {
  groupName: string;
  groupId: any;
  sourceCars: any[];
  targetCars: any;
  sourceuser: any[];
  validationMsg: any[] = [];
  showErrorDialogPopup: boolean = false;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService, private toastr: ToastrService
  ) {

    this.groupName = this.route.snapshot.queryParams['groupName'];
    this.groupId = this.route.snapshot.queryParams['groupId'];
  }
  ngOnInit() {
    this.getUser();

  }
  getUser() {

    this.apiService.getSourceGroupPermission(this.groupId).subscribe(x => {

      this.sourceuser = x as any;
      this.getGroupPermission();
    },
    err => {      
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }
  getGroupPermission() {

    this.apiService.getGroupPermission(this.groupId).subscribe(x => {
      this.targetCars = x;
    },
    err => {      
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }
  save() {

    this.validationMsg = [];

    if (this.targetCars === undefined || this.targetCars.length === 0) {
      this.validationMsg.push('Target should not be Empty');
    }
    if (this.validationMsg.length > 0) {
      return true;
    }

    const groupPermission = [];
    this.targetCars.forEach(element => {


      groupPermission.push({
        Id: 0,
        GroupId: this.groupId,
        UserId: element.userId,
        ActiveFlag: 1
      });
    });

    this.apiService.saveGroupPermission(groupPermission).subscribe(x => {
      this.toastr.success('Group Permission Added Successfully');
    },
    err => {      
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );

    this.router.navigate(['/managegroups']);
  }
}
