import { NgModule } from '@angular/core';

import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import {NgxToggleModule} from 'ngx-toggle';
import {PopoverModule} from 'ngx-popover';
import { NgxLoadingModule } from 'ngx-loading';
import { CookieService } from 'ngx-cookie-service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalModule } from 'ngx-bootstrap/modal';
@NgModule({
  imports:[
    NgxLoadingModule.forRoot({}),
    ToastrModule.forRoot({ progressBar:true, positionClass: 'toast-top-center', autoDismiss:true, timeOut: 3000, maxOpened:1, preventDuplicates:true}),
    ModalModule.forRoot(),
  ],
  exports: [
    NgxToggleModule,
    PopoverModule,
    NgxSpinnerModule,
  ],
  providers: [
    CookieService,
    BsModalService]
})
export class NGXModule {}
