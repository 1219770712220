import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mathematicalFormula'
})
export class MathematicalFormulaPipe implements PipeTransform {

  // To verify, if it is valid mathematical formula or not
  transform(value: any, ...args: any[]): any {
    let modifiedValue:String=String(value).replace(/[a-zA-z]+/g,"");
    modifiedValue=modifiedValue.replace(/[^0-9.(\\)+-]+/g,"");
    return modifiedValue;
  }

}
