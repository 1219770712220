import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../../src/app/service/apiCall.service';
import * as XLSX from 'xlsx';
import { HttpClient } from '@angular/common/http';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { PublishDataService } from '../../service/publish-data.service';
import { formatDate, DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import * as FileSaver from 'file-saver';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { FileUpload } from 'primeng/primeng';
@Component({
  selector: 'ngbd-modal',
  template: `
    <div id="myModal4" class="modal-header">
    <label style="margin-right:250px">Proceed to Publish report</label>
      <button type="button"  class="glyphicon glyphicon-remove" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      </button>      
      
    </div>
    <div class="modal-body">                
    <h6 class="modal-title">Do you want to publish and distribute ?</h6>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-success" routerLinkActive="active" data-dismiss="myModal" (click)="publish()">Publish</button>      
    </div>   
  `
})

export class NgbdModalContentSlateSummary {
  @Input() name;

  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal, private toastr: ToastrService,
    private publishslateService: PublishDataService) {
    this.publishslateService.listen().subscribe((m: any) => {

      if (m == 'ClosePopup') {
        activeModal.dismiss('Publish');
      }
    })
  }

  publish() {

    this.activeModal.close();
    this.publishslateService.filter('Publish');
  }
}
type AOA = any[][];
@Component({
  selector: 'app-domestictheatrical',
  templateUrl: './domestictheatrical.component.html',
  styleUrls: ['./domestictheatrical.component.scss']
})
export class DomestictheatricalComponent implements OnInit {
  /**Variable Declaration */
  fileData: File = null;
  header: any = '';
  headerDate: any = '';
  slatesummaryReport: any = [];
  data: AOA = [];
  isShow = false;
  reportValues: any;
  showDTErrorDialogPopup = false;
  showErrorDialogPopup: boolean = false;
  constructor(private apiService: ApiService, private http: HttpClient, private toastr: ToastrService
    , private publishslateService: PublishDataService, private spinner: NgxSpinnerService, private modalService: NgbModal,
    private router: Router,private datePipe: DatePipe) {
    this.slatesummaryReport = [];
  }

  storeData: any;
  jsonData: any;
  fileUploaded: File;
  worksheet: any;

  ngOnInit() {
    this.getSlateSummary();
  }

  getSecretValue(file)
  {
    var AWS = require('aws-sdk'),
    region = environment.region,
    secretName = environment.Secret,
    decodedBinarySecret;

// Create a Secrets Manager client
var client = new AWS.SecretsManager({
    region: region,
    secretAccessKey : sessionStorage.getItem('secretAccessKey'),
    accessKeyId : sessionStorage.getItem('accessKeyId'), 
    sessionToken :  sessionStorage.getItem('sessionToken')
});


client.getSecretValue({SecretId: secretName}, function(err, data) {
    if (err) {
        if (err.code === 'DecryptionFailureException')           
            throw err;
        else if (err.code === 'InternalServiceErrorException')           
            throw err;
        else if (err.code === 'InvalidParameterException')          
            throw err;
        else if (err.code === 'InvalidRequestException')           
            throw err;
        else if (err.code === 'ResourceNotFoundException')           
            throw err;
            console.log(err);
    }
    else {
        // Decrypts secret using the associated KMS CMK.
        // Depending on whether the secret is a string or binary, one of these fields will be populated.
        if ('SecretString' in data) {
            this.secret = data.SecretString;
            let secretValue =  JSON.parse(this.secret);            
            this.accessKey = secretValue.accessKey;
            this.secretKey = secretValue.secretKey;
            if(this.secret != undefined)
    {
    const bucket = new S3(
      {
        accessKeyId: this.accessKey,
        secretAccessKey: this.secretKey,
        region: environment.region
      }
    );

    const params = {
      Bucket: environment.slatebucket,
      Key: "Slate Summary.xlsx",
      Body: file.target.files[0],
      ACL: 'public-read',
      ContentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    bucket.upload(params, function (err, data) {

      if (err) {
        console.log('There was an error uploading your file: ', err);
        return false;
      }
      console.log('Successfully uploaded file.', data);
      return true;
    });
  }
        } else {
            let buff = new Buffer(data.SecretBinary, 'base64');
            decodedBinarySecret = buff.toString('ascii');
        }
    }
       
});
  }

  uploadFile(file) {    
    const contentType = file.type;
    this.getSecretValue(file);        
  }

  fileProgress(fileInput: any) {

    this.uploadFile(fileInput);
    let FinalRes = [];
    this.fileData = <File>fileInput.target.files[0];
    const target: DataTransfer = <DataTransfer>(fileInput.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      //
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellDates: true });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: any = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { dateNF: 'YYYY-MM-DD', header: 1 }));

      this.isShow = true;
      let Order = 1;
      this.data.forEach(element => {
        let key = 0;
        if (element[0] === 'No Changes from Last Week') {
          key = 1;

        }

        if (element.length > 0) {

          let date;
          try {
            date = new Date(element[0]);

            if (date.getTimezoneOffset() == 480 || date.getTimezoneOffset() == -480) { date.setDate(date.getDate() + 1); }
            date = Date.parse(date)
            date = new Date(date);
            date = formatDate(date, 'MM/dd', 'en');
          }
          catch (e) {
            date = element[0];
          }

          FinalRes.push({
            ID: 0,
            Date: date,
            Title: element[1],
            MediaGlight: element[2],
            MediaBudget: element[3],
            MediaLE: element[4],
            MediaDiff: element[5],

            BasicGlight: element[7],
            BasicBudget: element[8],
            BasicLE: element[9],
            BasicDiff: element[10],

            MarketingGlight: element[12],
            MarketingBudget: element[13],
            MarketingLE: element[14],
            MarketingDiff: element[15],

            BOGlight: key === 0 ? element[17] : null,
            BOBudget: key === 0 ? element[18] : null,
            BOLE: key === 0 ? element[19] : null,
            BODiff: key === 0 ? element[22] : null,

            Comments: key === 1 ? element[17] : null,
            DisplayOrder: Order
          });
          Order = Order + 1;
        }
      });
      if (this.fileData.name == "Slate Summary.xlsx") {
        this.apiService.importSlateSummaryReport(FinalRes).subscribe(res => {

          this.jsonData = res;
          this.slatesummaryReport = this.jsonData;
          this.header = this.jsonData[1].title;
          this.headerDate = this.data[0];
          this.isShow = false;
          this.toastr.clear();
          this.toastr.success('Slate Summary Report Imported Successfully');
          fileInput.target.value = '';
        },
        err => {
          this.isShow = false;
          this.showErrorDialogPopup = true;          
        },
        () => {
        }
      );
      }
      else {
        this.isShow = false;
        this.showDTErrorDialogPopup = true;
        fileInput.target.value = '';
      }
    };

    reader.readAsBinaryString(target.files[0]);



  }
  showDTErrorDialogPopupClose() {
    this.showDTErrorDialogPopup = false;
    //window.location.reload();
  }
  getSlateSummary() {
    this.apiService.getSlateSummary().subscribe(res => {

      this.jsonData = res;
      if (this.jsonData.length > 0) {
        this.slatesummaryReport = this.jsonData;
        //
        this.header = this.jsonData[1].title;
        //this.headerDate = this.jsonData[0].date;
      } else {
        this.jsonData = undefined;
      }
    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }

  open() {
    const modalRef = this.modalService.open(NgbdModalContentSlateSummary, { centered: true });
  }

  publishData() {

    this.isShow = true;
    var data = [];
    this.slatesummaryReport.forEach(element => {
      data.push(
        {
          Date: element.date,
          Title: element.title,
          mediaGlight: element.mediaGlight,
          mediaBudget: element.mediaBudget,
          mediaLE: element.mediaLE,
          mediaDiff: element.mediaDiff,
          basicGlight: element.basicGlight,
          basicBudget: element.basicBudget,
          basicLE: element.basicLE,
          basicDiff: element.basicDiff,
          marketingGlight: element.marketingGlight,
          marketingBudget: element.marketingBudget,
          marketingLE: element.marketingLE,
          marketingDiff: element.marketingDiff,
          boGlight: element.boGlight,
          boBudget: element.boBudget,
          bole: element.bole,
          boDiff: element.boDiff,
          comments: element.comments,
          displayOrder: element.displayOrder,
          createdDate: element.createdDate

        });
    });
    this.apiService.publishedSlateSummaryReport(data).subscribe(x => {

      this.spinner.hide();
      this.jsonData = undefined;
      this.header = '';
      this.headerDate = '';
      this.isShow = false;
      this.toastr.clear();
      this.toastr.success('Slate Summary Report Published Successfully');
    },
      err => {
        this.isShow = false;
        this.toastr.success('Slate Summary Report Published Successfully');
      },
      () => {
      }
    );
  }

  exportexcelClick(fileFormat) {
    this.isShow = true;
    this.apiService.getSlateSummaryReportById(fileFormat)
      .subscribe(response => {

        if (fileFormat == 'Excel') {
          const a = document.createElement('a');
          a.href = response.res;
          a.target = "blank";
          a.download = 'SlateSummary' + Date.now + '.xlsx';
          a.click();
          this.isShow = false;
          this.toastr.success('Slate Summary Downloaded Successfully');
        }
        else {
          const a = document.createElement('a');
          a.href = response.res;
          a.target = "blank";
          a.download = 'SlateSummary' + Date.now + '.xlsx';
          a.click();
          this.isShow = false;
          this.toastr.success('Slate Summary Downloaded Successfully');
        }

      });
  }

}
