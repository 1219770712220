import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'commaSeperatedStr' })
export class CommaSeperatedStr implements PipeTransform {
    constructor(private _sanitizer: DomSanitizer) { }
    transform(value: any): SafeHtml {
        let newStr: string = "";
        let stringVal = "";

        if (!(!value || value == undefined || value == "" || value.length == 0)) {
            let calcValStrings = [];
            value.forEach(d => {
                stringVal = stringVal + "<li>" + d.Name + "</li>";
                calcValStrings.push(d.Name)
            });
            if (calcValStrings && calcValStrings.length > 0) { newStr = calcValStrings.join('<br>') }
            return this._sanitizer.bypassSecurityTrustHtml('<ul style="list-style-type: circle;display: block;overflow-y: scroll;height: 33px;">'
             + stringVal + '</ul>');
        }
    }
}