import { Component, Input, OnInit, Output, EventEmitter, ViewChild, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { MatRadioChange } from '@angular/material/radio';
import { SessionStorageService } from 'ngx-webstorage';
import { AppHeaderComponent } from '../../components/app-header/app-header.component';
import { Options } from 'ng5-slider';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TreeTableModule } from 'primeng/treetable';
import { Headers, Http, Response, RequestOptions } from '@angular/http';
import { TreeNode } from '../../component/api';
import { ApiService } from '../../service/apiCall.service'
import { SaveFinalDataService } from '../../service/save-final-data.service'
import { SaveFinalDataGenerateService } from '../../service/save-final-data.generate.service'
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { variable } from '@angular/compiler/src/output/output_ast';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';
import { AwsCommonService } from '../../service/aws-common.service';
import { count } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { interval } from 'rxjs/observable/interval';
import { NumericScrollLockDirective } from '../../directives/numeric-scroll-lock.directive';

declare const $;

@Component({
  selector: 'ngbd-modal',
  template: `
    <div id="myModal2" class="modal-header">
     <label style="margin-right:250px">Proceed to Generate report</label>
     <button type="button"  class="glyphicon glyphicon-remove" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
     </button>    
    </div>
    <div class="modal-body">                
    <h6 class="modal-title">Click save and view to view the report or cancel to go back.</h6>
    </div>
    <div class="modal-footer">
    <button type="button" class="btn btn-success" routerLinkActive="active" routerLink='#' (click)="clickFilter()">Save</button>
      <button type="button" class="btn btn-success" routerLinkActive="active" (click)="clickFilterGenerate()">Save & View</button>
      <button type="button" class="btn btn-danger" routerLinkActive="active" (click)="activeModal.dismiss('Cancel')">Cancel</button>
    </div> 
  `,
})


export class NgbdModal {
  @Input() name;

  constructor(public activeModal: NgbActiveModal,
    private apiService: ApiService,
    private _messageService: SaveFinalDataService,
    private _messageServiceGenerate: SaveFinalDataGenerateService,
    private toastr: ToastrService) {
    this._messageService.listen().subscribe((t: any) => {
      if (t == 'ClosePopup') {
        activeModal.dismiss('Save');
      }
    })

    this._messageServiceGenerate.listen().subscribe((r: any) => {
      //
      if (r == 'ClosePopup') {
        activeModal.dismiss('Save & View');

      }
    })
  }

  clickFilter() {
    this._messageService.filter('Save');
  }

  clickFilterGenerate() {
    //    
    this._messageServiceGenerate.filter('Save & View');
  }
}

@Component({
  selector: 'app-genredashboard',
  templateUrl: './genredashboardResult.component.html',
  styleUrls: ['./genredashboardResult.component.scss'],
})

export class GenredashboardResultComponent implements OnInit {
  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    this.changeHeight();
  }

  @HostListener('window:resize', ['$event'])
  resize(event) {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    this.changeHeight();
  }

  changeHeight() {
    if (this.innerHeight <= 568) {
      this.tableheight = '200px';

    } else if (this.innerHeight > 569 && this.innerHeight <= 667) {
      this.tableheight = '250px'

    } else if (this.innerHeight > 668 && this.innerHeight <= 759) {
      this.tableheight = '270px'

    } else if (this.innerHeight > 760 && this.innerHeight <= 812) {
      this.tableheight = '340px'

    } else if (this.innerHeight > 813 && this.innerHeight <= 896) {
      this.tableheight = '380px'

    } else if (this.innerHeight > 897 && this.innerHeight <= 1100) {
      this.tableheight = '400px'

    } else if (this.innerHeight > 1101) {
      this.tableheight = '400px'

    } else {
      this.tableheight = '350px'
    }
  }

  innerWidth: any;
  innerHeight: any;
  tableheight: string = '350px';
  public columnDefs;
  public rowData;
  public defaultColDef;
  reportValues: TreeNode[];
  selectedVal = 0;
  validations: any = [];
  titleValues: any;
  public dropDownValues: any;
  GroupName: any = [];
  reportValue: any[];
  vendorGLList: any[];
  totpromobudgettotal: any = 0;
  totpromoultimatetotal: any = 0;
  totpromovariancetotal: any = 0;
  totpromoestimatetocompletetotal: any = 0;
  totpromonotcommittedinsaptotal: any = 0;
  totpromototalcommittedtotal: any = 0;
  totalRecords: number;
  releaseDate: Date;
  showErrorDialogPopup: boolean = false;
  loading: boolean;
  isShow = true;
  lastSavedDate: Date;

  cols: any[];
  showGrid = false;
  files: TreeNode[];
  report: any;
  dataelement: any;
  wbsElement: any = '';
  finalCommitmentDate: string = "";
  subscription: Subscription;
  sub: Subscription;
  countCheck: Subscription;
  cancel: Subscription;
  selectedyear: any;
  selectedmonth: any;
  selecteddate: any;
  dateValues: any;
  yearValues: any;
  monthValues: any;
  message: any;
  selectedValdate: any;
  titlePermission: any;
  userDetails: any;
  showAlertDialogPopup : boolean = false;
  userHasTitle : any = [];
  userTitles: any;
  titles:any;
  constructor(private _http: Http,
    private router: Router,
    private modalService: NgbModal,
    private apiService: ApiService,
    private _messageService: SaveFinalDataService,
    private spinner: NgxSpinnerService,
    private _messageServiceGenerate: SaveFinalDataGenerateService,
    private toastr: ToastrService, private snackbar: MatSnackBar, private datePipe: DatePipe,
    private awsCommonService: AwsCommonService) {
      this.loading = true; 
      this.isShow = true; 

    this.apiService.change.subscribe((m: any) => {
      this.saveData();
    });

    this.sub = this._messageService.listen().subscribe((m: any) => {
      if (m == 'Save') {
        this.saveData();
        this.router.navigate(['flashreport']);
      }
    })
    this.subscription = this._messageServiceGenerate.listen().subscribe((m: any) => {
      if (m == 'Save & View') {
        this.saveDataGenerate();
        
      }
    });
    this.getFinalCommitmentDate();
  }

  ngOnInit() {  
    this.loading = true; 
    this.isShow = true; 
      this.countCheck = interval(1000).subscribe((val) => { this.apiService.refreshvaluecheck().subscribe(count => {
        let result = count;
        console.log(count);
        if(parseInt(count) > 0)
        {
          this.toastr.info('Currently data is being refreshed from SAP, please wait until you receive "Data Refresh completed" email notification', 'Alert', { timeOut: 900000, extendedTimeOut: 0, tapToDismiss: false });
          this.isShow = true;
        }
        else
        {
          this.isShow = false;
          this.countCheck.unsubscribe();
          this.toastr.clear();
        }
      }); 
      });
    this.apiService.getTitles().subscribe(result => {
      this.titleValues = result;
    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
    this.loading = true;
  }
  
    ngOnDestroy() {
    this.subscription.unsubscribe();
    this.sub.unsubscribe();
}

  getFinalCommitmentDate() {
    this.apiService.getFinalCommitmentDate().subscribe(res => {
      this.finalCommitmentDate = res as string;
    });
  }
  searchClick() {
    //const val = {'selectedVal': this.selectedVal}
    this.isShow = true;
    this.awsCommonService.getApiRequest(environment.preparereporturl + this.selectedVal, 'GET')
      .subscribe(result => {
        this.reportValues = result;
        this.GenerateFlashReport(this.reportValues);
        this.apiService.getFlashReportLastSavedDateById(this.selectedVal).subscribe(result => {
          this.lastSavedDate = result;
        });
      },
      err => {
        this.isShow = false;
        this.showErrorDialogPopup = true;          
      },
      () => {
      }
    );



    this.validations = [];
    if (this.selectedVal === 0) {
      this.isShow = false;
      this.validations.push('Please Select Title');
    }
    if (this.validations.length > 0) {
      return;
    }
    this.showGrid = true;
  }
  alert() {
    this.isShow = false;
  }

  GenerateFlashReport(reportValues) {
    var report: any[];
    this.GroupName = [];
    this.apiService.getgroupSchema().subscribe(x => {
      // x.forEach(e => {        
      this.GroupName = x;
      // })   
      this.dataelement = [];
      this.report = [];
      this.reportValue = [];
      let i = 0;
      for (let grp in this.GroupName) {
        report = reportValues[this.GroupName[grp]];
        this.report.push({
          data: { groupName: this.GroupName[grp] },
          expanded: true,

        })        
        var childrens: any = [];
        for (let key in report) {

          let value = report[key];
          this.reportValue.push(value);          
          childrens.push({
            data: {
              id: value.id,
              glCode: value.glCode,
              description: value.description,
              budget: Math.round(value.budget),
			        decimalultimate: Math.round(value.ultimate),
              ultimate: value.ultimate,
              variance: value.variance,
              actualpos: value.actualsPO,
              actualnonpos: value.actualsNonPO,
              openpos: value.openPO,
              committedinsap: value.committedInSAP,
              notcommittedinsap: Math.round(value.notCommittedInSAP),
              totalcommitted: value.totalCommitted,
              estimatetocomplete: value.estimationToComplete,
              comments: value.comments,
              summary: "",
              uiedittotal: value.uIeditTotal,
              titleId: value.titleId,
              totalgroup: value.totalGroup,
              calcId: value.calcId,
              calculationLink: value.calculationLink,
              type: value.type,
              category: value.category
            }
          })
        }        
        this.report[i]['children'] = childrens;
        i = i + 1;
      }
      this.alert();
    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
    //this.isShow = false;
    // this.GroupName = [
    //   'PRINT DEVELOPMENT',
    //   'ONE SHEET PRODUCTION',
    //   'BILLBOARD / OUTDOOR',
    //   'NEWSPAPER / MAGAZINE PRODUCTION',
    //   'IN THEATRE / CONCESSION',
    //   'TRAILER PRODUCTION - TEASER',
    //   'TRAILER PRODUCTION - REGULAR',
    //   'TV SPOT PRODUCTION',
    //   'MARKET RESEARCH',
    //   'PUBLICITY',
    //   'PUBLICITY / PROMOTION',
    //   'BRAND MARKETING',
    //   'DIGITAL MARKETING',
    //   'EXHIBITOR PROMOTIONS'
    // ];  

    this.getvendorglList();
  }

  saveData() {
    let releaseTitleDate: any;
    this._messageService.filter('ClosePopup');
    this.isShow = true;
    let finalResul = [];
    let finalResulSigle = [];
    let finalResulSigleFinall = [];	
    for (let grp in this.GroupName) {
      const dd = this.report.filter(x => x.data.groupName == this.GroupName[grp]);	       
      finalResul.push(dd[0].children);
    }

    for (let i = 0; i < finalResul.length; i++) {
      for (let j = 0; j < finalResul[i].length; j++) {
        finalResulSigle.push(
          {
            GLCode: finalResul[i][j].data.glCode,
            Description: finalResul[i][j].data.description,
            Budget: finalResul[i][j].data.budget != null ? finalResul[i][j].data.budget : 0,
            Ultimate: finalResul[i][j].data.ultimate != null ? finalResul[i][j].data.ultimate : 0,
            Variance: finalResul[i][j].data.variance,
            ActualsPO: finalResul[i][j].data.actualpos,
            ActualsNonPO: finalResul[i][j].data.actualnonpos,
            OpenPO: finalResul[i][j].data.openpos,
            CommittedInSAP: finalResul[i][j].data.committedinsap,
            NotCommittedInSAP: finalResul[i][j].data.notcommittedinsap != null ? finalResul[i][j].data.notcommittedinsap : 0,
            TotalCommitted: finalResul[i][j].data.totalcommitted,
            EstimationToComplete: finalResul[i][j].data.estimatetocomplete,
            Budget_string: finalResul[i][j].data.budget_string,
            Ultimate_string: finalResul[i][j].data.ultimate_string,
            Variance_string: finalResul[i][j].data.variance_string,
            APO_string: finalResul[i][j].data.actualpos_string,
            ANPO_string: finalResul[i][j].data.actualnonpos_string,
            OPO_string: finalResul[i][j].data.openpos_string,
            CommitedInSAP_string: finalResul[i][j].data.committedinsap_string,
            NotCommittedInSAP_string: finalResul[i][j].data.notcommittedinsap_string,
            TotalCommitted_string: finalResul[i][j].data.totalcommitted_string,
            EstimateToComplete_String: finalResul[i][j].data.estimatetocomplete_string,
            Comments: finalResul[i][j].data.comments,
            TitleId: finalResul[i][j].data.titleId,
            TotalGroup: finalResul[i][j].data.totalgroup,
            Id: finalResul[i][j].data.id,
          }
        );

      }
    }

    if (this.releaseDate == undefined) {
      releaseTitleDate = '';
    }
    else {
      releaseTitleDate = this.datePipe.transform(this.releaseDate, 'MM-dd-yyyy');
      this.apiService.getReleaseDate(releaseTitleDate, finalResulSigle[0].TitleId).subscribe(x => {
      },
      err => {
        this.isShow = false;
        this.showErrorDialogPopup = true;          
      },
      () => {
      }
    );
    }

    this.apiService.saveFinalReport(finalResulSigle).subscribe(x => {
      this.isShow = false;
      this.toastr.success('Flash Report Saved Successfully');
      this.apiService.getFlashReportLastSavedDateById(this.selectedVal).subscribe(result => {
        this.lastSavedDate = result;
      });
    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
    );

  }
  saveDataGenerate() {
    let releaseTitleDate: any;
    this._messageServiceGenerate.filter('ClosePopup');
    this.isShow = true;
    let finalResul = [];
    let finalResulSigle = [];
    let finalResulSigleFinall = [];	
    for (let grp in this.GroupName) {
      const dd = this.report.filter(x => x.data.groupName == this.GroupName[grp]);	  
      finalResul.push(dd[0].children);
    }

    for (let i = 0; i < finalResul.length; i++) {
      for (let j = 0; j < finalResul[i].length; j++) {
        finalResulSigle.push(
          {
            GLCode: finalResul[i][j].data.glCode,
            Description: finalResul[i][j].data.description,
            Budget: finalResul[i][j].data.budget != null ? finalResul[i][j].data.budget : 0,
            Ultimate: finalResul[i][j].data.ultimate != null ? finalResul[i][j].data.ultimate : 0,
            Variance: finalResul[i][j].data.variance,
            ActualsPO: finalResul[i][j].data.actualpos,
            ActualsNonPO: finalResul[i][j].data.actualnonpos,
            OpenPO: finalResul[i][j].data.openpos,
            CommittedInSAP: finalResul[i][j].data.committedinsap,
            NotCommittedInSAP: finalResul[i][j].data.notcommittedinsap != null ? finalResul[i][j].data.notcommittedinsap : 0,
            TotalCommitted: finalResul[i][j].data.totalcommitted,
            EstimationToComplete: finalResul[i][j].data.estimatetocomplete,
            Budget_string: finalResul[i][j].data.budget_string,
            Ultimate_string: finalResul[i][j].data.ultimate_string,
            Variance_string: finalResul[i][j].data.variance_string,
            APO_string: finalResul[i][j].data.actualpos_string,
            ANPO_string: finalResul[i][j].data.actualnonpos_string,
            OPO_string: finalResul[i][j].data.openpos_string,
            CommitedInSAP_string: finalResul[i][j].data.committedinsap_string,
            NotCommittedInSAP_string: finalResul[i][j].data.notcommittedinsap_string,
            TotalCommitted_string: finalResul[i][j].data.totalcommitted_string,
            EstimateToComplete_String: finalResul[i][j].data.estimatetocomplete_string,
            Comments: finalResul[i][j].data.comments,
            TitleId: finalResul[i][j].data.titleId,
            Id: finalResul[i][j].data.id
          }
        );

      }
    }

    if (this.releaseDate == undefined) {
      releaseTitleDate = '';
    }
    else {
      releaseTitleDate = this.datePipe.transform(this.releaseDate, 'MM-dd-yyyy');
      this.apiService.getReleaseDate(releaseTitleDate, finalResulSigle[0].TitleId).subscribe(x => {
      },
      err => {
        this.isShow = false;
        this.showErrorDialogPopup = true;          
      },
      () => {
      }
    );
    }
    this.apiService.saveFinalReport(finalResulSigle).subscribe(x => {
      this.isShow = false;
      this.flashConfirm();
      this.apiService.getFlashReportLastSavedDateById(this.selectedVal).subscribe(result => {
        this.lastSavedDate = result;
      });
    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }

  routetonextpage() {
    this.router.navigate(['viewflashreport']);
  }
  flashConfirm() {
    this.toastr.success('Flash Report Saved Successfully');
    setTimeout(() => {
      this.routetonextpage();
    },
      3000);
  }
  open() {
    const modalRef = this.modalService.open(NgbdModal, { centered: true });
  }

  revert() {
    this.isShow = true;
    this.awsCommonService.getApiRequest(environment.preparereporturl + this.selectedVal, 'GET')
      .subscribe(result => {
        this.reportValues = result;
        this.GenerateFlashReport(this.reportValues);
        this.isShow = false;
      },
      err => {
        this.isShow = false;
        this.showErrorDialogPopup = true;          
      },
      () => {
      }
    );
  }

  loadNodes(event) {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }

  onNodeExpand(event) {
    this.loading = true;

    setTimeout(() => {
      this.loading = false;
      const node = event.node;
    }, 250);

  }
  getWbsElement() {
    this.wbsElement = this.titleValues.find(x => x.titleId == this.selectedVal).wbsElem;
    this.apiService.getTitles().subscribe(result => {
      this.titleValues = result;
    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );

    this.releaseDate = this.titleValues.find(x => x.titleId == this.selectedVal).releaseDate;
  }

  trigger() {
    this.isShow = true;
    
    this.apiService.getRefreshedData().subscribe(x => {
 this.countCheck = interval(1000).subscribe((val) => { this.apiService.refreshvaluecheck().subscribe(count => {
        let result = count;
        console.log(count);
        if(parseInt(count) > 0)
        {
          this.isShow = true;
          this.toastr.info('Currently data is being refreshed from SAP, please wait until you receive "Data Refresh completed" email notification', 'Alert', { timeOut: 900000, extendedTimeOut: 0, tapToDismiss: false });
        }
        else
        {
          this.isShow = false;
          this.countCheck.unsubscribe();
          this.toastr.clear();
        }
      });      
      });  
    },
      err => {
        this.isShow = false;
        this.showErrorDialogPopup = true;
      },
      () => {
      }
    );
  }

  getvendorglList() {
    this.apiService.getVendorGLList().subscribe(x => {
      this.vendorGLList = [];
      this.vendorGLList = x;
    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }

  getBudgetTotal(val: any) {
    val.node.data.totalcommitted = val.node.data.committedinsap + val.node.data.notcommittedinsap;
  	val.node.data.decimalultimate = val.node.data.budget > Math.round(val.node.data.totalcommitted) ? val.node.data.budget : Math.round(val.node.data.totalcommitted);
    val.node.data.ultimate = val.node.data.budget > val.node.data.totalcommitted ? val.node.data.budget : val.node.data.totalcommitted;
    val.node.data.estimatetocomplete = val.node.data.ultimate - val.node.data.totalcommitted;
    val.node.data.variance = val.node.data.budget - val.node.data.ultimate;
    for (var i = 0; i < val.node.parent.children.length; i++) {
      if (val.node.parent.children[i].data.calculationLink != null && val.node.parent.children[i].data.calculationLink != "") {
        if (this.vendorGLList.indexOf(val.node.parent.children[i].data.glCode) != -1 || val.node.parent.children[i].data.glCode == "") {

          var calcIds = val.node.parent.children[i].data.calculationLink;
          var calcId = calcIds.split(',');
          var values = [];
          for (var j = 0; j < calcId.length; j++) {
            for (var k = 0; k < val.node.parent.children.length; k++) {
              if (val.node.parent.children[k].data.calcId == calcId[j]) {
                values.push(val.node.parent.children[k].data.budget);
              }
            }
          }
          var totVal = 0;
          for (var tt = 0; tt < values.length; tt++) {
            totVal = totVal + values[tt];
          }
          val.node.parent.children[i].data.budget = totVal;
        }
      }
    }

    //Estimatetocomplete calculation
    for (var i = 0; i < val.node.parent.children.length; i++) {

      if (val.node.parent.children[i].data.calculationLink != null && val.node.parent.children[i].data.calculationLink != "") {
        if (this.vendorGLList.indexOf(val.node.parent.children[i].data.glCode) != -1 || val.node.parent.children[i].data.glCode == "") {
          var calcIds = val.node.parent.children[i].data.calculationLink;
          var calcId = calcIds.split(',');

          var values = [];
          for (var j = 0; j < calcId.length; j++) {
            for (var k = 0; k < val.node.parent.children.length; k++) {
              if (val.node.parent.children[k].data.calcId == calcId[j]) {
                values.push(val.node.parent.children[k].data.estimatetocomplete);
              }
            }
          }
          var totVal = 0;
          for (var tt = 0; tt < values.length; tt++) {
            totVal = totVal + values[tt];
          }

          val.node.parent.children[i].data.estimatetocomplete = totVal;
        }
      }
    }

    //Variance Calculation
    for (var i = 0; i < val.node.parent.children.length; i++) {
      if (val.node.parent.children[i].data.calculationLink != null && val.node.parent.children[i].data.calculationLink != "") {
        if (this.vendorGLList.indexOf(val.node.parent.children[i].data.glCode) != -1 || val.node.parent.children[i].data.glCode == "") {
          var calcIds = val.node.parent.children[i].data.calculationLink;
          var calcId = calcIds.split(',');
          var values = [];
          for (var j = 0; j < calcId.length; j++) {
            for (var k = 0; k < val.node.parent.children.length; k++) {
              if (val.node.parent.children[k].data.calcId == calcId[j]) {
                values.push(val.node.parent.children[k].data.variance);
              }
            }
          }
          var totVal = 0;
          for (var tt = 0; tt < values.length; tt++) {
            totVal = totVal + values[tt];
          }
          val.node.parent.children[i].data.variance = totVal;
        }
      }
    }
    for (var i = 0; i < val.node.parent.children.length; i++) {
      if (val.node.parent.children[i].data.description == "Total Publicity") {

        this.totpromobudgettotal = val.node.parent.children[i].data.budget;

        this.totpromovariancetotal = val.node.parent.children[i].data.variance;

        this.report[10].children[56].data.budget = this.totpromobudgettotal + this.report[10].children[24].data.budget + this.report[10].children[55].data.budget;
        this.report[10].children[56].data.variance = this.totpromovariancetotal + this.report[10].children[24].data.variance + this.report[10].children[55].data.variance;
        
      }

      if (val.node.parent.children[i].data.description == "Total Publicity/Promotion") { 
        
        this.totpromobudgettotal = this.report[9].children[50].data.budget;
        this.totpromovariancetotal = this.report[9].children[50].data.variance;

        this.report[10].children[56].data.budget = this.totpromobudgettotal + this.report[10].children[24].data.budget + this.report[10].children[55].data.budget;
        this.report[10].children[56].data.variance = this.totpromovariancetotal + this.report[10].children[24].data.variance + this.report[10].children[55].data.variance;
        
      }
    }

    //Ultimate Calculation
    for (var i = 0; i < val.node.parent.children.length; i++) {

      if (val.node.parent.children[i].data.calculationLink != null && val.node.parent.children[i].data.calculationLink != "") {
        if (this.vendorGLList.indexOf(val.node.parent.children[i].data.glCode) != -1 || val.node.parent.children[i].data.glCode == "") {
          var calcIds = val.node.parent.children[i].data.calculationLink;
          var calcId = calcIds.split(',');

          var values = [];
          for (var j = 0; j < calcId.length; j++) {
            for (var k = 0; k < val.node.parent.children.length; k++) {
              if (val.node.parent.children[k].data.calcId == calcId[j]) {
                values.push(val.node.parent.children[k].data.ultimate);
              }
            }
          }
          var totVal = 0;
          for (var tt = 0; tt < values.length; tt++) {
            totVal = totVal + values[tt];
          }

          val.node.parent.children[i].data.ultimate = totVal;
        }
      }
    }

    for (var i = 0; i < val.node.parent.children.length; i++) {
     if (val.node.parent.children[i].data.description == "Total Publicity") {
        this.totpromoultimatetotal = val.node.parent.children[i].data.ultimate;
        this.totpromoestimatetocompletetotal = val.node.parent.children[i].data.estimatetocomplete;

        this.report[10].children[56].data.ultimate = this.totpromoultimatetotal + this.report[10].children[24].data.ultimate + this.report[10].children[55].data.ultimate;
        this.report[10].children[56].data.estimatetocomplete = this.totpromoestimatetocompletetotal + this.report[10].children[24].data.estimatetocomplete + this.report[10].children[55].data.estimatetocomplete;
      }

      if (val.node.parent.children[i].data.description == "Total Publicity/Promotion") { 
        
        this.totpromoultimatetotal = this.report[9].children[50].data.ultimate;
        this.totpromoestimatetocompletetotal = this.report[9].children[50].data.estimatetocomplete;

        this.report[10].children[56].data.ultimate = this.totpromoultimatetotal + this.report[10].children[24].data.ultimate + this.report[10].children[55].data.ultimate;
        this.report[10].children[56].data.estimatetocomplete = this.totpromoestimatetocompletetotal + this.report[10].children[24].data.estimatetocomplete + this.report[10].children[55].data.estimatetocomplete;
        
      }
    }

  }
  getUltimateTotal(val: any) {
    val.node.data.ultimate = val.node.data.decimalultimate;
    val.node.data.variance = val.node.data.budget - val.node.data.ultimate;
    val.node.data.estimatetocomplete = val.node.data.ultimate - val.node.data.totalcommitted;
    for (var i = 0; i < val.node.parent.children.length; i++) {

      if (val.node.parent.children[i].data.calculationLink != null && val.node.parent.children[i].data.calculationLink != "") {
        if (this.vendorGLList.indexOf(val.node.parent.children[i].data.glCode) != -1 || val.node.parent.children[i].data.glCode == "") {
          var calcIds = val.node.parent.children[i].data.calculationLink;
          var calcId = calcIds.split(',');

          var values = [];
          for (var j = 0; j < calcId.length; j++) {
            for (var k = 0; k < val.node.parent.children.length; k++) {
              if (val.node.parent.children[k].data.calcId == calcId[j]) {
                values.push(val.node.parent.children[k].data.ultimate);
              }
            }
          }
          var totVal = 0;
          for (var tt = 0; tt < values.length; tt++) {
            totVal = totVal + values[tt];
          }

          val.node.parent.children[i].data.ultimate = totVal;
        }
      }
    }
    for (var i = 0; i < val.node.parent.children.length; i++) {

      if (val.node.parent.children[i].data.calculationLink != null && val.node.parent.children[i].data.calculationLink != "") {
        if (this.vendorGLList.indexOf(val.node.parent.children[i].data.glCode) != -1 || val.node.parent.children[i].data.glCode == "") {
          var calcIds = val.node.parent.children[i].data.calculationLink;
          var calcId = calcIds.split(',');

          var values = [];
          for (var j = 0; j < calcId.length; j++) {
            for (var k = 0; k < val.node.parent.children.length; k++) {
              if (val.node.parent.children[k].data.calcId == calcId[j]) {
                values.push(val.node.parent.children[k].data.variance);
              }
            }
          }
          var totVal = 0;
          for (var tt = 0; tt < values.length; tt++) {
            totVal = totVal + values[tt];
          }

          val.node.parent.children[i].data.variance = totVal;
        }
      }
    }
    //Estimate To Complete
    for (var i = 0; i < val.node.parent.children.length; i++) {

      if (val.node.parent.children[i].data.calculationLink != null && val.node.parent.children[i].data.calculationLink != "") {
        if (this.vendorGLList.indexOf(val.node.parent.children[i].data.glCode) != -1 || val.node.parent.children[i].data.glCode == "") {
          var calcIds = val.node.parent.children[i].data.calculationLink;
          var calcId = calcIds.split(',');

          var values = [];
          for (var j = 0; j < calcId.length; j++) {
            for (var k = 0; k < val.node.parent.children.length; k++) {
              if (val.node.parent.children[k].data.calcId == calcId[j]) {

                if (val.node.parent.children[k].data.estimatetocomplete == undefined) {
                  val.node.parent.children[k].data.estimatetocomplete = 0;
                }
                values.push(val.node.parent.children[k].data.estimatetocomplete);
              }
            }
          }
          var totVal = 0;
          for (var tt = 0; tt < values.length; tt++) {
            totVal = totVal + values[tt];
          }

          val.node.parent.children[i].data.estimatetocomplete = totVal;
        }
      }
    }
    for (var i = 0; i < val.node.parent.children.length; i++) {
      if (val.node.parent.children[i].data.description == "Total Publicity") {

        this.totpromoultimatetotal = val.node.parent.children[i].data.ultimate;
        this.totpromovariancetotal = val.node.parent.children[i].data.variance;
        this.totpromoestimatetocompletetotal = val.node.parent.children[i].data.estimatetocomplete;

        this.report[10].children[56].data.ultimate = this.totpromoultimatetotal + this.report[10].children[24].data.ultimate + this.report[10].children[55].data.ultimate;
        this.report[10].children[56].data.variance = this.totpromovariancetotal + this.report[10].children[24].data.variance + this.report[10].children[55].data.variance;
        this.report[10].children[56].data.estimatetocomplete = this.totpromoestimatetocompletetotal + this.report[10].children[24].data.estimatetocomplete + this.report[10].children[55].data.estimatetocomplete;

      }

      if (val.node.parent.children[i].data.description == "Total Publicity/Promotion") { 
        
        this.totpromoultimatetotal = this.report[9].children[50].data.ultimate;
        this.totpromovariancetotal = this.report[9].children[50].data.variance;
        this.totpromoestimatetocompletetotal = this.report[9].children[50].data.estimatetocomplete;

        this.report[10].children[56].data.ultimate = this.totpromoultimatetotal + this.report[10].children[24].data.ultimate + this.report[10].children[55].data.ultimate;
        this.report[10].children[56].data.variance = this.totpromovariancetotal + this.report[10].children[24].data.variance + this.report[10].children[55].data.variance;
        this.report[10].children[56].data.estimatetocomplete = this.totpromoestimatetocompletetotal + this.report[10].children[24].data.estimatetocomplete + this.report[10].children[55].data.estimatetocomplete;
        
      }
    }
  }

  getNotCommittedInSapTotal(val: any) {

    val.node.data.totalcommitted = val.node.data.committedinsap + val.node.data.notcommittedinsap;
	  val.node.data.decimalultimate = val.node.data.budget > Math.round(val.node.data.totalcommitted) ? val.node.data.budget : Math.round(val.node.data.totalcommitted);
    val.node.data.ultimate = val.node.data.budget > val.node.data.totalcommitted ? val.node.data.budget : val.node.data.totalcommitted;
    val.node.data.estimatetocomplete = val.node.data.ultimate - val.node.data.totalcommitted;
    val.node.data.variance = val.node.data.budget - val.node.data.ultimate;
    for (var i = 0; i < val.node.parent.children.length; i++) {
      if (val.node.parent.children[i].data.calculationLink != null && val.node.parent.children[i].data.calculationLink != "") {
        if (this.vendorGLList.indexOf(val.node.parent.children[i].data.glCode) != -1 || val.node.parent.children[i].data.glCode == "") {
          var calcIds = val.node.parent.children[i].data.calculationLink;
          var calcId = calcIds.split(',');

          var values = [];
          for (var j = 0; j < calcId.length; j++) {
            for (var k = 0; k < val.node.parent.children.length; k++) {
              if (val.node.parent.children[k].data.calcId == calcId[j]) {
                values.push(val.node.parent.children[k].data.notcommittedinsap);
              }
            }
          }
          var totVal = 0;
          for (var tt = 0; tt < values.length; tt++) {
            totVal = totVal + values[tt];
          }

          val.node.parent.children[i].data.notcommittedinsap = totVal;
        }
      }
    }
    //Totalcommitted calculation
    for (var i = 0; i < val.node.parent.children.length; i++) {

      if (val.node.parent.children[i].data.calculationLink != null && val.node.parent.children[i].data.calculationLink != "") {
        if (this.vendorGLList.indexOf(val.node.parent.children[i].data.glCode) != -1 || val.node.parent.children[i].data.glCode == "") {
          var calcIds = val.node.parent.children[i].data.calculationLink;
          var calcId = calcIds.split(',');

          var values = [];
          for (var j = 0; j < calcId.length; j++) {
            for (var k = 0; k < val.node.parent.children.length; k++) {
              if (val.node.parent.children[k].data.calcId == calcId[j]) {
                values.push(val.node.parent.children[k].data.totalcommitted);
              }
            }
          }
          var totVal = 0;
          for (var tt = 0; tt < values.length; tt++) {
            totVal = totVal + values[tt];
          }

          val.node.parent.children[i].data.totalcommitted = totVal;
        }
      }
    }
    //Estimatetocomplete calculation
    for (var i = 0; i < val.node.parent.children.length; i++) {

      if (val.node.parent.children[i].data.calculationLink != null && val.node.parent.children[i].data.calculationLink != "") {
        if (this.vendorGLList.indexOf(val.node.parent.children[i].data.glCode) != -1 || val.node.parent.children[i].data.glCode == "") {
          var calcIds = val.node.parent.children[i].data.calculationLink;
          var calcId = calcIds.split(',');

          var values = [];
          for (var j = 0; j < calcId.length; j++) {
            for (var k = 0; k < val.node.parent.children.length; k++) {
              if (val.node.parent.children[k].data.calcId == calcId[j]) {
                values.push(val.node.parent.children[k].data.estimatetocomplete);
              }
            }
          }
          var totVal = 0;
          for (var tt = 0; tt < values.length; tt++) {
            totVal = totVal + values[tt];
          }

          val.node.parent.children[i].data.estimatetocomplete = totVal;
        }
      }
    }
    for (var i = 0; i < val.node.parent.children.length; i++) {
      if (val.node.parent.children[i].data.description == "Total Publicity") {

        this.totpromonotcommittedinsaptotal = val.node.parent.children[i].data.notcommittedinsap;

        this.totpromototalcommittedtotal = val.node.parent.children[i].data.totalcommitted;

        this.report[10].children[56].data.notcommittedinsap = this.totpromonotcommittedinsaptotal + this.report[10].children[24].data.notcommittedinsap + this.report[10].children[55].data.notcommittedinsap;
        this.report[10].children[56].data.totalcommitted = this.totpromototalcommittedtotal + this.report[10].children[24].data.totalcommitted + this.report[10].children[55].data.totalcommitted;        
      }

      if (val.node.parent.children[i].data.description == "Total Publicity/Promotion") { 
        
        this.totpromonotcommittedinsaptotal = this.report[9].children[50].data.notcommittedinsap;
        this.totpromototalcommittedtotal = this.report[9].children[50].data.totalcommitted;        

        this.report[10].children[56].data.notcommittedinsap = this.totpromonotcommittedinsaptotal + this.report[10].children[24].data.notcommittedinsap + this.report[10].children[55].data.notcommittedinsap;
        this.report[10].children[56].data.totalcommitted = this.totpromototalcommittedtotal + this.report[10].children[24].data.totalcommitted + this.report[10].children[55].data.totalcommitted;        
        
      }
    }

    //Variance Calculation
    for (var i = 0; i < val.node.parent.children.length; i++) {
      if (val.node.parent.children[i].data.calculationLink != null && val.node.parent.children[i].data.calculationLink != "") {
        if (this.vendorGLList.indexOf(val.node.parent.children[i].data.glCode) != -1 || val.node.parent.children[i].data.glCode == "") {
          var calcIds = val.node.parent.children[i].data.calculationLink;
          var calcId = calcIds.split(',');
          var values = [];
          for (var j = 0; j < calcId.length; j++) {
            for (var k = 0; k < val.node.parent.children.length; k++) {
              if (val.node.parent.children[k].data.calcId == calcId[j]) {
                values.push(val.node.parent.children[k].data.variance);
              }
            }
          }
          var totVal = 0;
          for (var tt = 0; tt < values.length; tt++) {
            totVal = totVal + values[tt];
          }
          val.node.parent.children[i].data.variance = totVal;
        }
      }
    }
    for (var i = 0; i < val.node.parent.children.length; i++) {
      if (val.node.parent.children[i].data.description == "Total Publicity") {

        this.totpromoestimatetocompletetotal = val.node.parent.children[i].data.estimatetocomplete;

        this.totpromovariancetotal = val.node.parent.children[i].data.variance;
                
        this.report[10].children[56].data.estimatetocomplete = this.totpromoestimatetocompletetotal + this.report[10].children[24].data.estimatetocomplete + this.report[10].children[55].data.estimatetocomplete;
        this.report[10].children[56].data.variance = this.totpromovariancetotal + this.report[10].children[24].data.variance + this.report[10].children[55].data.variance;

      }

      if (val.node.parent.children[i].data.description == "Total Publicity/Promotion") { 
        
        this.totpromoestimatetocompletetotal = this.report[9].children[50].data.estimatetocomplete;
        this.totpromovariancetotal = this.report[9].children[50].data.variance;        

        this.report[10].children[56].data.estimatetocomplete = this.totpromoestimatetocompletetotal + this.report[10].children[24].data.estimatetocomplete + this.report[10].children[55].data.estimatetocomplete;
        this.report[10].children[56].data.variance = this.totpromovariancetotal + this.report[10].children[24].data.variance + this.report[10].children[55].data.variance;        
        
      }
    }

    //Ultimate Calculation
    for (var i = 0; i < val.node.parent.children.length; i++) {

      if (val.node.parent.children[i].data.calculationLink != null && val.node.parent.children[i].data.calculationLink != "") {
        if (this.vendorGLList.indexOf(val.node.parent.children[i].data.glCode) != -1 || val.node.parent.children[i].data.glCode == "") {
          var calcIds = val.node.parent.children[i].data.calculationLink;
          var calcId = calcIds.split(',');

          var values = [];
          for (var j = 0; j < calcId.length; j++) {
            for (var k = 0; k < val.node.parent.children.length; k++) {
              if (val.node.parent.children[k].data.calcId == calcId[j]) {
                values.push(val.node.parent.children[k].data.ultimate);
              }
            }
          }
          var totVal = 0;
          for (var tt = 0; tt < values.length; tt++) {
            totVal = totVal + values[tt];
          }

          val.node.parent.children[i].data.ultimate = totVal;
        }
      }
    }

    for (var i = 0; i < val.node.parent.children.length; i++) {
      if (val.node.parent.children[i].data.description == "Total Publicity") {
        this.totpromoultimatetotal = val.node.parent.children[i].data.ultimate;

        this.report[10].children[56].data.ultimate = this.totpromoultimatetotal + this.report[10].children[24].data.ultimate + this.report[10].children[55].data.ultimate;  
      }

      if (val.node.parent.children[i].data.description == "Total Publicity/Promotion") { 
        
        this.totpromoultimatetotal = this.report[9].children[50].data.ultimate;        

        this.report[10].children[56].data.ultimate = this.totpromoultimatetotal + this.report[10].children[24].data.ultimate + this.report[10].children[55].data.ultimate;        
        
      }
    }
  }
  
  PreviewPublishReport() {
    this.isShow = true;
    this.getUserById();
}

  getMonth() {
    this.apiService.gettestNonAdminInsertedMonthById(this.selectedyear).subscribe(result => {
      this.monthValues = result;
      this.selectedmonth = this.monthValues[0].month;
      this.getDay();
    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }

  getDay() {
    this.apiService.gettestNonAdminInsertedDayById(this.selectedmonth, this.selectedyear).subscribe(result => {
      this.dateValues = result;
      this.selecteddate = this.dateValues[0].date;
      
      // if(this.cookieService.get('URL') == environment.appurl + "/publishreportstest"){
         this.exportPDFFromMail('Pdf');
      // }
      
      
    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }

  exportPDFFromMail(fileFormat){ 
    var DateObj = new Date();

    this.isShow = true;
    this.validations = [];
    this.message = undefined;
    this.selectedValdate = this.selectedyear + "-" + this.selectedmonth + "-" + this.selecteddate;
    this.apiService.gettestNonAdminReportById(fileFormat, this.selectedValdate)
        .subscribe(response => {
          this.apiService.gettestGeneratedPDFMail(response.res).subscribe(res => {
            this.message = undefined;
            const a = document.createElement('a');
            a.href = res.res;
            a.target ="blank";
            a.download = 'ConsolidatedReport_' + Date.now + '.pdf';
            a.click();
            this.isShow = false;
          }),
            err => {
              this.isShow = false;
              this.message = "No Report Available";
            },
            () => {
            }
        },
        err => {
          this.isShow = false;
          this.showErrorDialogPopup = true;
        },
        () => {
        }
        );
  }

  getUserById()
  {
    this.apiService.getUsersById(sessionStorage.getItem('SSO')).subscribe(result => {
      this.userDetails = result;
      console.log(this.userDetails);
      this.getTitles(this.userDetails);
    });
  }

  getTitlePermission(userDetails) {
    this.apiService.getTitlesPermission().subscribe(res => {
      this.titlePermission = res;
        this.titles.forEach(element => {
          let dd = this.titlePermission.filter(x => x.titleId === element.titleId && x.userId === this.userDetails.userId && x.activeFlag === 1);
          if(dd.length>0)
          {
          this.userHasTitle = dd;
            return;
          }
        });
        if(this.userHasTitle.length<1)
        {
          this.isShow = false;
          this.showAlertDialogPopup = true;
          return;
        }
        else
        {
        this.isShow = true;
        this.apiService.testpublishAll().subscribe(x => {        
            this.apiService.testpublishSlateNMedia().subscribe(x => {                   
            });
          },
          err => {   
            this.isShow = false;       
          },
          () => 
          {     
            this.isShow = true; 
            this.apiService.gettestNonAdminInsertedDateById().subscribe(result => {
                this.yearValues = result;
                if(this.yearValues.length > 0)
                {
                this.selectedyear = this.yearValues[0].year;
                this.getMonth();
                }
              },
              err => {
                this.isShow = false;
                this.showErrorDialogPopup = true;          
              },
              () => {
              }
            );
          }
          );     
        }
    },
    err => {      
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  } 

  getTitles(userDetails) {

    this.apiService.getTitles().subscribe(res => {
      this.titles = res;
      this.getTitlePermission(this.userDetails);

    },
    err => {      
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }
}
 


export interface TreeNode {
  data?: any;
  children?: TreeNode[];
  leaf?: boolean;
  expanded?: boolean;
}
