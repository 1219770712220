import { Component, Input, OnInit, ViewChild, ElementRef, TemplateRef, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportService } from '../../service/export.service';
import { ApiService } from '../../service/apiCall.service'
import { Observable } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { PublishDataService } from '../../service/publish-data.service';
import 'rxjs/add/observable/timer';
import { Subscription } from 'rxjs/Subscription';
import * as FileSaver from 'file-saver';
declare const $;
@Component({
  selector: 'ngbd-modal',
  template: `
    <div id="myModal6" class="modal-header">
    <label style="margin-right:250px">Proceed to Publish report</label>
      <button type="button"  class="glyphicon glyphicon-remove" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      </button>      
      
    </div>
    <div class="modal-body">                
    <h6 class="modal-title">Do you want to publish and distribute ?</h6>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-success" routerLinkActive="active" data-dismiss="myModal" (click)="publish()">Publish</button>
      <button type="button" class="btn btn-danger" routerLinkActive="active" (click)="activeModal.dismiss('Cancel')">Cancel</button>
    </div>   
  `
})

export class NgbdModalContent1 {
  @Input() name;

  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal, private toastr: ToastrService,
    private publishService: PublishDataService) {
    this.publishService.listen().subscribe((m: any) => {
      if (m == 'ClosePopup') {
        activeModal.dismiss('Publish');
      }
    })
  }

  publish() {
    this.publishService.filter('Publish');
  }
}
@Component({
  selector: 'reportgeneration',
  templateUrl: './reportgeneration.component.html',
  styleUrls: ['./reportgeneration.component.scss']
})
export class ReportGenerationComponent {
  public columnDefs;
  public rowData;
  public defaultColDef;
  names: any[];
  excelNames: any[];
  selectedAll: any;
  validations: any = [];
  customers: any = [];
  titles: any = [];
  flashReport: any = [];
  flashReportAll: any = [];
  filterExcelName: any = [];
  isShow = false;
  private timer: Observable<any>;
  private subscription: Subscription;


  constructor(private dialog: MatDialog, private modalService: NgbModal, private exportService: ExportService, private apiService: ApiService, private spinner: NgxSpinnerService, private toastr: ToastrService
    , private publishService: PublishDataService) {
    this.publishService.listen().subscribe((m: any) => {
      if (m == 'Publish') {
        this.publishData();
      }
    })
    this.flashReportAll = [];
    this.excelNames = [];

  }

  ngOnInit() {
    this.getTitles();
  }


  selectAll(event: any) {
    this.titles = [];

    for (var i = 0; i < this.names.length; i++) {
      this.names[i].selected = this.selectedAll;

    }
    for (var i = 0; i < this.names.length; i++) {
      this.titles.push({ titleId: this.names[i].titleId, titleName: this.names[i].titlename });
    }
  }

  checkIfAllSelected(event: any) {

    if (event.selected == true) {
      let titleId = event.titleId;
      this.titles.push({ titleId: event.titleId, titleName: event.titlename });

    }
    else {

      let titleId = event.titleId;
      this.titles = this.titles.filter(obj => obj.titleId !== titleId);

    }
  }

  open() {
    this.validations = [];
    const dd = this.names.find(x => x.selected === true);
    if (dd === undefined) {
      this.validations.push('Please Select Title');
    }
    if (this.validations != 'Please Select Title') {
      const modalRef = this.modalService.open(NgbdModalContent1, { centered: true });
      return;
    }

  }

  openDialog() {

    this.validations = [];
    const dd = this.names.find(x => x.selected === true);
    if (dd === undefined) {
      this.validations.push('Please Select Title');
    }
    if (this.validations.length > 0) {
      return;
    }


  }
  publishData() {
    this.isShow = true;
    this.publishService.filter('ClosePopup');
    this.validations = [];
    const dd = this.names.find(x => x.selected === true);
    if (dd === undefined) {
      this.validations.push('Please Select Title');
    }
    if (this.validations.length > 0) {
      return;
    }
    var data = [];
    this.flashReportAll.forEach(element => {
      data.push(
        {
          TitleName: element.titleName,
          TitleId: element.titleId,
          GroupName: element.groupName,
          GroupId: element.groupId,
          GLCode: element.glCode,
          Description: element.description,
          Budget: element.budget,
          ActualsPO: element.apo,
          ActualsNonPO: element.anpo,
          OpenPO: element.opo,
          CommittedInSAP: element.commitedInSAP,
          NotCommittedInSAP: element.notCommittedInSAP,
          TotalCommitted: element.totalCommitted,
          EstimationToComplete: element.estimateToComplete,
          Ultimate: element.ultimate,
          Variance: element.variance,
          Comments: element.comments,
          HierachyLevel4: element.hierachyLevel4,
          HierachyLevel5: element.hierachyLevel5,
          HierachyLevel6: element.hierachyLevel6
        });
    });
    this.apiService.publishedReport(data).subscribe(x => {
      this.isShow = false;
      this.toastr.clear();
      this.flashPublish();

    },
      err => {
        this.isShow = false;
        this.toastr.success('Flash Report Published Successfully');
      },
      () => {
      }
    );

  }

  flashPublish() {
    this.toastr.success('Flash Report Published Successfully');
  }

  exportexcelClick(fileFormat) {
    this.isShow = true;
    this.excelNames = [];
    this.validations = [];
    this.titles.forEach(element => {
      this.excelNames.push(
        {
          titleId: element.titleId,
          titlename: element.titleName
        });
    });

    let titleIds = "";
    for (var i = 0; i < this.titles.length; i++) {
      if (i == 0) {
        titleIds = this.titles[i].titleId;
      }
      else {
        titleIds = titleIds + "-" + this.titles[i].titleId;
      }
    }
    if (this.excelNames.length > 0) {
      this.apiService.getReportById(titleIds, fileFormat)
        .subscribe(response => {          
          if (fileFormat == 'Excel') {
            const a = document.createElement('a');
            // const file = new Blob([response.res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            // a.href = URL.createObjectURL(file);
            a.href = response.res;
            a.target ="blank";
            a.download = 'FlashReport_' + Date.now + '.xlsx';
            a.click();
            this.isShow = false;
            this.toastr.success('Flash Report Downloaded Successfully');
          }
          else {
            const a = document.createElement('a');            
            a.href = response.res;
            a.target = "blank";
            a.download = 'FlashReport_' + Date.now + '.pdf';
            a.click();
            this.isShow = false;
            this.toastr.success('Flash Report Downloaded Successfully');
          }

        });
    }
    else {
      this.isShow = false;
      this.validations.push('Please Select Title');
    }
  }

  export() {

    this.isShow = true;
    this.validations = [];
    this.excelNames = [];
    this.titles.forEach(element => {
      this.excelNames.push(
        {
          titleId: element.titleId,
          titlename: element.titleName
        });
    });

    var titleIds;
    for (var i = 0; i < this.titles.length; i++) {
      if (i == 0) {
        titleIds = this.titles[i].titleId;
      }
      else {
        titleIds = titleIds + "," + this.titles[i].titleId;
      }
    }

    this.apiService.getFlashReportById(titleIds).subscribe(x => {
      this.flashReport = [];
      this.filterExcelName = [];
      this.flashReport = x;
      this.flashReportAll = this.flashReport;
      if (this.excelNames.length > 0) {
        var data = [];
        this.flashReportAll.forEach(element => {
          data.push(
            {
              titleId: element.titleId,
              GroupName: element.groupName,
              GLCode: element.glCode,
              Description: element.description,
              Budget: this.formatNumber(element.budget),
              Actuals_Pos: this.formatNumber(element.apo),
              Actuals_Non_Pos: this.formatNumber(element.anpo),
              Open_Pos: this.formatNumber(element.opo),
              Committed_In_SAP: this.formatNumber(element.commitedInSAP),
              Committed_Not_In_SAP: this.formatNumber(element.notCommittedInSAP),
              Total_Committed: this.formatNumber(element.totalCommitted),
              Estimate_To_Complete: this.formatNumber(element.estimateToComplete),
              Ultimate: this.formatNumber(element.ultimate),
              Variance: this.formatNumber(element.variance),
              Comments: element.comments
            });
        });
        this.exportService.exportExcel(data, 'Titles', this.excelNames);
        this.isShow = false;
        this.toastr.success('Flash Report Downloaded Successfully');
      }
      else {
        this.isShow = false;
        this.validations.push('Please Select Title');
      }
    });
    if (this.validations.length > 0) {
      return;
    }
  }

  getTitles() {
    this.apiService.getTitles().subscribe(x => {
      this.names = x as any;
    })
  }

  formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }


}