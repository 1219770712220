import { Component, OnInit, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { Table, TableModule } from 'primeng/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../../service/apiCall.service';
import { ToastrService } from 'ngx-toastr';
import { bool } from 'aws-sdk/clients/signer';
import { FormBuilder, FormGroup, Validators, ValidatorFn } from '@angular/forms';
import { SortEvent } from 'primeng/primeng';

export interface GLModel {
  id?;
  groupId?;
  groupName?;
  glCode?;
  description?;
  type?;
  category?;
  uiEdit?;
  isVendor?;
  calculationLink?;
  sequentialOrder?;
  recordStatus?;
  active?;
}

@Component({
  selector: 'app-schemacategory',
  templateUrl: './schemacategory.component.html',
  styleUrls: ['./schemacategory.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SchemacategoryComponent implements OnInit {
  gridShow: bool = false;
  isSequentialOrderCahnged: number = 0;
  isSequentialOrderEdit: bool = true;
  glGridDataSrc: any;
  glGridFullDataSrc: any;
  glGridDeletedDataSrc: any = [];
  groupDetailsSrc: any[];
  cols: any[];
  grpId: any;
  isShow = false;
  categoryDataSrc: any[];
  uiGLTypeDataSrc: any[];
  uiEditDataSrc: any[];
  isVendorDataSrc: any[];
  calculationLinkDataSrc: any[];
  clonedSchema: { [s: string]: any; } = {};
  headerGroupName: any;
  isRowEdit: bool = false;
  schemaForm: FormGroup;
  submitted = false;
  disableEdit = false;
  frozenCols: any[];
  showDialogPopup: boolean = false;
  showDeleteDialogPopup: boolean = false;
  groupName: string = "";
  groupId: any;
  public grp: any;
  isGroupNameTouched: bool = false;
  isGLCodeDuplicate: bool = false;
  showErrorDialogPopup: boolean = false;
  @ViewChild('dt', { static: false }) dt: Table;

  constructor(private apiService: ApiService, private spinner: NgxSpinnerService,
    private toastr: ToastrService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    localStorage.removeItem("schemaTemp");
    this.getSchemaCategory();
    this.schemaForm = this.formBuilder.group({
      fcGLCode: [''],
      fcDescription: ['', Validators.required],
      fcType: ['', Validators.required],
      fcCategory: ['', Validators.required],
      fcUIEdit: [''],
      fcISVendor : [''],
      fcCalculationLink: [''],
      fcSequentialOrder: ['', Validators.required],
    });

    this.cols = [
      { header: 'Action', width: '90px' },
      { header: 'GLCode', width: '100px' },
      { header: 'Description', width: '170px' },
      { header: 'Type', width: '120px' },
      { header: 'Hierarchy', width: '120px' },
      { header: 'isVendor', width: '120px' },
      { header: 'CalculationLink', width: '250px' },
      { header: 'Order', width: '120px' }
    ];

    this.categoryDataSrc = [
      { label: 'Main', value: { Id: 'M', Name: 'Main' } },
      { label: 'Total', value: { Id: 'T', Name: 'Total' } },
      { label: 'SubCategory', value: { Id: 'S', Name: 'SubCategory' } },
      { label: 'GroupTotal', value: {Id: 'G', Name:'GroupTotal'}},
      { label: 'GrandTotal', value: {Id: 'GT', Name:'GrandTotal'}},
    ];

    this.uiEditDataSrc = [
      { label: 'Yes', value: { Id: 1, Name: 'Yes' } },
      { label: 'No', value: { Id: 0, Name: 'No' } }
    ];

    this.isVendorDataSrc = [
      { label: 'Yes', value: { Id: 1, Name: 'Yes' } },
      { label: 'No', value: { Id: 0, Name: 'No' } }
    ];

    this.uiGLTypeDataSrc = [
      { label: 'Static', value: { Id: 'S', Name: 'Static' } },
      // { label: 'Dynamic', value: { Id: 'D', Name: 'Dynamic' } },
      { label: 'Static Header', value: { Id: 'SH', Name: 'Static Header' } },
    ];
  }

  onGLCodeOrDescriptionChange(rowdata: any) {
    const glCode = this.schemaForm.get('fcGLCode');
    let glCodeExsist = this.glGridFullDataSrc.filter(f => parseInt(f.glCode) > 0 && f.glCode === rowdata.glCode && f.id != rowdata.id);

    if (rowdata.description.toLocaleLowerCase() != 'total' || Array.isArray(glCodeExsist) && glCodeExsist.length > 0) {
      glCode.setValidators(null);
      //if (!(rowdata.glCode) || Array.isArray(glCodeExsist) && glCodeExsist.length > 0) { glCode.setErrors({ "glCode is Required": true }); }
    }
    else {
      glCode.setValidators(null);
    }
    if (!(rowdata.glCode)) glCode.updateValueAndValidity();

    if (Array.isArray(glCodeExsist) && glCodeExsist.length > 0) { this.isGLCodeDuplicate = true } else { this.isGLCodeDuplicate = false }
  }

  customSort(event: SortEvent) {    
    event.data.sort((data1, data2) => {      
        let value1 = data1["sequentialOrder"];
        let value2 = data2["sequentialOrder"];
        let result = null;

        if (value1 == null && value2 != null)
            result = -1;
        else if (value1 != null && value2 == null)
            result = 1;
        else if (value1 == null && value2 == null)
            result = 0;
        else if (typeof value1 === 'string' && typeof value2 === 'string')
            result = value1.localeCompare(value2);
        else
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

        return (event.order * result);
    });
  }

  onAddNew(table: Table) {
    // Insert a new row
    this.disableEdit = true;
    let initiateRow = this.newRow();
    table.value.push(initiateRow);

    table.value = table.value.sort((c1, c2) => {
      if (c1.sequentialOrder > c2.sequentialOrder) { return 1; }
      if (c1.sequentialOrder < c2.sequentialOrder) { return -1; }
      return 0;
    });
    this.schemaForm.reset();
    table.initRowEdit(initiateRow);
  }

  onDelete(table: Table){    
    this.showDeleteDialogPopup = false;
    this.isShow = true;
    this.apiService.deleteGroup(this.headerGroupName.id).subscribe(e => {
      this.isShow = false;      
    
      this.toastr.success('Group has been Removed Successfully');
      this.gridShow = false;
      this.getSchemaCategory();      
      this.dt.reset();
    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }

  get f() { return this.schemaForm.controls; }

  onRowEditInit(schema: any) {       
    this.isRowEdit = true;
    this.clonedSchema[schema.id] = schema;
    localStorage.setItem("schemaTemp", JSON.stringify(this.clonedSchema));
    this.isSequentialOrderEdit = false;
    this.disableEdit = true;
  }

  onRowEditCancel(schema: any, index: number) {       
    this.isRowEdit = false;
    this.submitted = false;
    this.disableEdit = false;
    this.isSequentialOrderCahnged = 0;
    if (!(schema.id > 0) && !(schema.recordStatus > 0)) {
      this.glGridDataSrc = this.glGridDataSrc.slice(0, index).concat(this.glGridDataSrc.slice(index + 1));
    }
    else {
      this.clonedSchema = JSON.parse(localStorage.getItem("schemaTemp"));
      this.glGridDataSrc[index] = this.clonedSchema[schema.id];
      delete this.clonedSchema[schema.id];
    }
    this.schemaForm.updateValueAndValidity()
  }

  onRowEditSave(schemaCategory: any, event: Event) {      
    this.submitted = true;
    if (!(this.schemaForm.valid)) {

      return false;
    }
    else {
      this.isRowEdit = false;
      this.disableEdit = false;
      delete this.clonedSchema[schemaCategory.id];
      let schema = [];
      if (this.isSequentialOrderCahnged) {
        if (this.glGridDataSrc.filter(f => parseInt(f.sequentialOrder) == parseInt(schemaCategory.sequentialOrder)
          && f.id != schemaCategory.id).length > 0) {

          this.glGridDataSrc.filter(f => parseInt(f.sequentialOrder) >= parseInt(schemaCategory.sequentialOrder) && f.id != schemaCategory.id)
            .forEach(element => {
              element.sequentialOrder = parseInt(element.sequentialOrder) + 1,
                element.recordStatus = 1
            });

        }
      }
      this.glGridDataSrc = this.glGridDataSrc.sort((c1, c2) => {
        if (c1.sequentialOrder > c2.sequentialOrder) { return 1; }
        if (c1.sequentialOrder < c2.sequentialOrder) { return -1; }
        return 0;
      });
      this.glGridDataSrc.filter(f => parseInt(f.id) == parseInt(schemaCategory.id))
        .forEach(element => {
          element.recordStatus = 1
        });

      this.refreshGrid();
    }

    this.isSequentialOrderCahnged = 0;

  }

  refreshGrid() {
    this.glGridDataSrc = this.glGridDataSrc.filter(f => parseInt(f.recordStatus) != 0);
  }

  onRowDelete(schemaCategory: any, index: number) {    
    this.glGridDataSrc.filter(f => parseInt(f.id) == parseInt(schemaCategory.id))
      .forEach(element => {
        element.recordStatus = 0
        this.glGridDeletedDataSrc.push(element);
      });

    this.glGridDataSrc.filter(f => parseInt(f.sequentialOrder) >= parseInt(schemaCategory.sequentialOrder) && f.id != schemaCategory.id)
      .forEach(element => {
        element.sequentialOrder = parseInt(element.sequentialOrder) - 1,
          element.recordStatus = 1
      });

    this.refreshGrid();
  }
  newRow() {

    let idVal = 0;
    let src = this.glGridDataSrc.filter(f => parseInt(f.id) < 0);
    if (src.length > 0) { idVal = (src[src.length - 1].id - 1); }

    return {
      id: idVal < 0 ? idVal : -1,
      groupId: this.headerGroupName.id,
      groupName: this.headerGroupName.name,
      glCode: '',
      description: '',
      type: '',
      category: '',
      uiEdit: '',
      isVendor: '',
      calculationLink: '',
      sequentialOrder: ''
    };


  }
  getSchemaCategory() {
    this.isShow = true;

    this.apiService.getSchemaCategory().subscribe(x => {      
      let data = x as any[];
      data.forEach(e => {               
        e.calculationLink = this.getCalculationLinkCommaSeperatedValue(e.calculationLink, data)
        e.uiEdit = (e.uiEdit != null) ? (e.uiEdit == '1' ? { Id: 1, Name: 'Yes' } : { Id: 0, Name: 'No' }) : { Id: '', Name: '' }
        e.isVendor = (e.isVendor != null) ? (e.isVendor == '1' ? { Id: 1, Name: 'Yes' } : { Id: 0, Name: 'No' }) : { Id: '', Name: '' }
        e.category = (e.category != null) ?
          (e.category == 'M' ? { Id: 'M', Name: 'Main' }
            : e.category == 'T' ? { Id: 'T', Name: 'Total' }
              : e.category == 'S' ? { Id: 'S', Name: 'SubCategory' }
                : e.category == 'G' ? { Id: 'G', Name: 'GroupTotal' }
                  : e.category == 'GT' ? { Id: 'GT', Name: 'GrandTotal' } : "")
          : ""
        e.type = (e.type != null) ? (e.type == 'S' ? { Id: 'S', Name: 'Static' }
          : e.type == 'D' ? { Id: 'D', Name: 'Dynamic' } : e.type == 'SH' ? {Id: 'SH', Name: 'Static Header'}
            : "") : ""
        

      });
      this.glGridDataSrc = data.filter(f => f.active === 1);
      this.glGridFullDataSrc = data.filter(f => f.active === 1);

      this.glGridDataSrc = this.glGridDataSrc.sort((c1, c2) => {
        if (c1.sequentialOrder > c2.sequentialOrder) { return 1; }
        if (c1.sequentialOrder < c2.sequentialOrder) { return -1; }
        return 0;
      });
      this.isShow = false;
    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );

  }

  getSchemaCategoryId(groupId) {
    this.isShow = true;    
    this.apiService.getSchemaCategory().subscribe(x => {      
      let data = x as any[];
      data.forEach(e => {                      
        e.calculationLink = this.getCalculationLinkCommaSeperatedValue(e.calculationLink, data)
        e.uiEdit = (e.uiEdit != null) ? (e.uiEdit == '1' ? { Id: 1, Name: 'Yes' } : { Id: 0, Name: 'No' }) : { Id: '', Name: '' }
        e.isVendor = (e.isVendor != null) ? (e.isVendor == '1' ? { Id: 1, Name: 'Yes' } : { Id: 0, Name: 'No' }) : { Id: '', Name: '' }
        e.category = (e.category != null) ?
          (e.category == 'M' ? { Id: 'M', Name: 'Main' }
            : e.category == 'T' ? { Id: 'T', Name: 'Total' }
              : e.category == 'S' ? { Id: 'S', Name: 'SubCategory' }
                : e.category == 'G' ? { Id: 'G', Name: 'GroupTotal' }
                  : e.category == 'GT' ? { Id: 'GT', Name: 'GrandTotal' } : "")
          : ""
        e.type = (e.type != null) ? (e.type == 'S' ? { Id: 'S', Name: 'Static' }
          : e.type == 'D' ? { Id: 'D', Name: 'Dynamic' } : e.type == 'SH' ? {Id: 'SH', Name: 'Static Header'}
            : "") : ""
        

      });
      //this.glGridDataSrc = data.filter(f => f.active === 1);
      this.glGridFullDataSrc = data.filter(f => f.active === 1);

      // this.glGridDataSrc = this.glGridDataSrc.sort((c1, c2) => {
      //   if (c1.sequentialOrder > c2.sequentialOrder) { return 1; }
      //   if (c1.sequentialOrder < c2.sequentialOrder) { return -1; }
      //   return 0;
      //});
      
    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
    this.apiService.getSchemaCategorybyId(groupId).subscribe(x => {           
      let data = x as any[];
      data.forEach(e => {                    
        e.calculationLink = this.getCalculationLinkCommaSeperatedValue(e.calculationLink, data)
        e.uiEdit = (e.uiEdit != null) ? (e.uiEdit == '1' ? { Id: 1, Name: 'Yes' } : { Id: 0, Name: 'No' }) : { Id: '', Name: '' }
        e.isVendor = (e.isVendor != null) ? (e.isVendor == '1' ? { Id: 1, Name: 'Yes' } : { Id: 0, Name: 'No' }) : { Id: '', Name: '' }
        e.category = (e.category != null) ?
          (e.category == 'M' ? { Id: 'M', Name: 'Main' }
            : e.category == 'T' ? { Id: 'T', Name: 'Total' }
              : e.category == 'S' ? { Id: 'S', Name: 'SubCategory' }
                : e.category == 'G' ? { Id: 'G', Name: 'GroupTotal' }
                  : e.category == 'GT' ? { Id: 'GT', Name: 'GrandTotal' } : "")
          : ""
        e.type = (e.type != null) ? (e.type == 'S' ? { Id: 'S', Name: 'Static' }
          : e.type == 'D' ? { Id: 'D', Name: 'Dynamic' } : e.type == 'SH' ? {Id: 'SH', Name: 'Static Header'}
            : "") : ""
        

      });
      this.glGridDataSrc = data.filter(f => f.active === 1);
      

        this.glGridDataSrc = this.glGridDataSrc.sort((c1, c2) => {
        if (c1.sequentialOrder > c2.sequentialOrder) { return 1; }
        if (c1.sequentialOrder < c2.sequentialOrder) { return -1; }
        return 0;
      });
      this.isShow = false;
      this.getCalculationLinkData(); 
    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
 

  }

  getCalculationLinkCommaSeperatedValue(calcLink: any, data: any) {    
    let valueList = [];
    if (calcLink != null) {      
      let values = calcLink.replace(/, +/g, ",").split(",").map(Number);
      let schemaGridData = data.filter(f => f.active === 1);

      for (let val of values) {        
        let filtertedSchema = schemaGridData.find(ele => ele.id == val);
        if (filtertedSchema != null) {
          valueList.push({ Id: filtertedSchema.id, Name: filtertedSchema.description });
        }
      }
      return valueList;
    }
    else { return valueList }
  }

  getCalculationLinkData() {  
    this.calculationLinkDataSrc = [];
    this.glGridDataSrc.forEach(e => {      
      this.calculationLinkDataSrc.push({ label: e.description, value: { Id: e.id, Name: e.description } });
    });
  }

  
  onGroupnameChange($event) {        
    this.gridShow = true;
    if (this.dt) this.dt.reset();
    this.glGridDataSrc = this.glGridFullDataSrc.filter(f => f.groupId === this.headerGroupName.id);
    this.glGridDataSrc = this.glGridDataSrc.sort((c1, c2) => {
      if (c1.sequentialOrder > c2.sequentialOrder) { return 1; }
      if (c1.sequentialOrder < c2.sequentialOrder) { return -1; }
      return 0;
    });
    this.getCalculationLinkData();

    this.grp = $event.value.name;

  }

  onSequentialChanges(rowData: any) {

    let item = this.glGridDataSrc.find(e => e.id === rowData.id);
    this.isSequentialOrderCahnged = rowData.id;

  }
  onSave() {              
    this.isShow = true; 
    let schema = [];
    let deleteRow = [];
    this.glGridDeletedDataSrc.forEach(data => {      
      deleteRow.push({
        Id: data.id,
        GroupId: data.groupId,
        GroupName: data.groupName,
        GLCode: data.glCode,
        Description: data.description,
        Category: data.category.Id,
        UIEdit: data.uiEdit.Id,
        isVendor: data.isVendor.Id,
        Type: data.type.Id,
        CalculationLink: data.calculationLink != null && data.calculationLink != "" ? data.calculationLink.map(({ Id }) => Id).toString() : "",
        SequentialOrder: data.sequentialOrder,
        recordStatus: data.recordStatus,
        Active: data.recordStatus == 0 ? 0 : 1
      });
      this.apiService.deleteSchemaCategory(deleteRow).subscribe(x =>{        
      },
      err => {
        this.isShow = false;
        this.showErrorDialogPopup = true;          
      },
      () => {
      }
    );
    });

    this.glGridDataSrc.filter(f => f.recordStatus === 0 || f.recordStatus === 1).forEach(data => {      
      schema.push({
        Id: data.id,
        GroupId: data.groupId,
        GroupName: data.groupName,
        GLCode: data.glCode,
        Description: data.description,
        Category: data.category.Id,
        UIEdit: data.uiEdit.Id,
        isVendor: data.isVendor.Id,
        Type: data.type.Id,
        CalculationLink: data.calculationLink != null && data.calculationLink != "" ? data.calculationLink.map(({ Id }) => Id).toString() : "",
        SequentialOrder: data.sequentialOrder,
        recordStatus: data.recordStatus,
        Active: data.recordStatus == 0 ? 0 : 1
      });
    });

    this.apiService.saveSchemaCategory(schema).subscribe(e => {        
      this.isShow = false;    
      this.toastr.success('Schema Category Saved Sucessfully');
      this.getSchemaCategoryId(schema[0].GroupId);          
      this.dt.reset();
    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }

  showDialog() {
    this.showDialogPopup = true;
    this.groupName = "";
    this.isGroupNameTouched = false
  }
  
  showDeleteDialog(){    
    this.showDeleteDialogPopup = true;        
  }

  
  EditGroupName(table: Table){        
    this.showDeleteDialogPopup = false;
    this.isShow = true;
    this.apiService.editGroupName(this.grp, this.headerGroupName.id).subscribe(t => {
      this.grpId = this.headerGroupName.id;
      
      this.isShow = false;
      this.toastr.success("GroupName Changed Successfully"); 
      //this.gridShow = false;     
    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }
  saveGroupName() {    
    this.showDialogPopup = false;
    this.isShow = true;
    this.apiService.saveGroupName(this.groupName).subscribe(e => {      
      
      this.isShow = false;
      this.toastr.success('GroupName Saved Successfully');
      this.groupName = "";
    },
    err => {
      this.isShow = false;
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }
  cancelGroupName() {
    this.showDialogPopup = false;
    this.groupName = "";
  }
  cancelDeleteGroupname(){
    this.showDeleteDialogPopup = false;
  }
  onGroupNameEnter() {
    this.isGroupNameTouched = true
  }

  globalFilter(val: any) {

    if (val) {
      this.glGridDataSrc = this.glGridDataSrc.filter(function (element, index) {
        if (element.glCode) {
          return (element.glCode.indexOf(val) >= 0 || element.description.toLowerCase().indexOf(val.toLowerCase()) >= 0);
        }
        else return (element.description.toLowerCase().indexOf(val.toLowerCase()) >= 0);
      });
    }
    else
      this.glGridDataSrc = this.glGridFullDataSrc.filter(f => f.groupId === this.headerGroupName.id);
  }
}