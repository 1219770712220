import { Component,Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddUserClickComponent } from '../adduserclick/adduserclick.component';
import { ApiService } from '../../service/apiCall.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'add-user',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.scss']
})
@Injectable()
export class AddUserComponent {

  users: any;
  settings = {
    // "mode": "inline",
    "hideSubHeader": true,
    "pager": {
      display: true,
      perPage: 5
    },
    "actions":
    {
      "position": "right",
    },
    "add": {
      "inputClass": "form-control input-sm",
      "addButtonContent": "<i class='material-icons'>person_add</i>",
      "createButtonContent": "<i class='material-icons'>save</i>",
      "cancelButtonContent": "<i class='material-icons'>cancel</i>",
      "confirmCreate": true
    }, "edit": {
      "inputClass": "form-control input-sm",
      "editButtonContent": "<i class='material-icons'>edit</i>",
      "saveButtonContent": "<i class='material-icons'>save</i>",
      "cancelButtonContent": "<i class='material-icons'>cancel</i>",
      "confirmSave": true
    },
    "delete": {
      "deleteButtonContent": "<i class='material-icons'>delete</i>",
      "confirmDelete": true
    },

    columns: {
      fullName: {
        title: 'FullName',
        class: "{color:white}",
        width: '30%'
      },
      email:{
        title: 'Email',
        class: "{color:white}",
        width: '30%'
      },
      sso: {
        title: 'SSO',
        class: "{color:white}",
        width: '10%'
      },
      userAccess: {
        title: 'Admin Access',
        class: "{color:white}",
        width: '10%',
        editor: {
          type: 'checkbox',
          config: {
            true: 'Yes',
            false: 'No',
          },
        },
      },
      // mailConfirmation: {
      //   title: 'Mail Status',
      //   class: "{color:white}",
      //   width: '5%',
      //   editable: false,
      // },
    }
  };

  constructor(private router: Router, private toastr: ToastrService, private modalService: NgbModal, public dialog: MatDialog, private apiService: ApiService, private spinner: NgxSpinnerService) {
    this.getUser();
  }

  userDetails: any = [];
  showDeleteDialogPopup: boolean = false;
  showErrorDialogPopup: boolean = false;
  store: any;
  getUser() {
    this.spinner.show();

    this.apiService.getUsers().subscribe(x => {      
      this.users = x;
      this.users.forEach(element => {
        if (element.userAccess == 1) {
          element.userAccess = 'Yes';
        }
        else {
          element.userAccess = 'No';
        }
      });
    },
    err => {      
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
    this.spinner.hide();
  }

  openDialog() {
    const dialogRef = this.dialog.open(AddUserClickComponent, { disableClose: true });

    dialogRef.afterClosed().subscribe(result => {      
      this.getUser();
    },
    err => {      
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }

  sendPendingMail()
  {
    this.apiService.getPendingConfirmationMailList().subscribe(result =>
      {
        this.toastr.success('Mail has been sent to Pending Users');
      },
      err => {        
        this.showErrorDialogPopup = true;          
      },
      () => {
      }
    );
  }

  onSaveConfirm(event): void {        
    let finalUser = [];
    if (event.data.userAccess == 'No') {
      event.data.userAccess = 0;     
    }
    else {
      event.data.userAccess = 1;     
    }
    if (event.newData.userAccess == 'No') {
      event.newData.userAccess = 0;     
    }
    else {
      event.newData.userAccess = 1;     
    }
    finalUser.push({
      UserId: event.newData.userId,
      SSO: event.newData.sso,
      RoleId: 1,
      FullName: event.newData.fullName,
      FirstName: event.newData.firstName,
      LastName: event.newData.lastName,
      Email: event.newData.email,
      BusinessUnitId: 1,
      UserAccess: event.newData.userAccess
    });    

    this.apiService.updateUser(finalUser).subscribe(x => {
      this.toastr.success('User Updated Successfully');
      event.confirm.resolve();
    },
    err => {      
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );

    if(event.data.userAccess != event.newData.userAccess)
    {
	  const data = event.data.userAccess;
      this.apiService.getMasheryAccessToken().subscribe((result: any) => {        
        this.userDetails = result;
        this.apiService.RemoveusersfromNDIGroup(this.userDetails.access_token,finalUser[0].SSO, data).subscribe(res => {
		this.apiService.AddusersintoNDIGroup(this.userDetails.access_token,finalUser[0].SSO, finalUser[0].UserAccess).subscribe(res => {                        
        },
        err => {          
          this.showErrorDialogPopup = true;          
        },
        () => {
        }
      );
        },
        err => {          
          this.showErrorDialogPopup = true;          
        },
        () => {
        }
      );        
      },
      err => {        
        this.showErrorDialogPopup = true;          
      },
      () => {
      }
    );      
    }
  }

  showDeleteDialog($event){
    this.showDeleteDialogPopup = true;    
  }

  cancelDeleteGroupname(){
    this.showDeleteDialogPopup = false;
  }

  onDelete(event){    
    this.showDeleteDialogPopup = true;  
    this.store = event;
  }

  ondeleteuser(store): void {        
    if (store.data.userAccess == 'No') {
      store.data.userAccess = 0;     
    }
    else {
      store.data.userAccess = 1;     
    }

    let finalUser = [];
    finalUser.push({
      UserId: store.data.userId,
      SSO: store.data.sso,
      RoleId: 1,
      FullName: store.data.fullName,
      FirstName: store.data.firstName,
      LastName: store.data.lastName,
      Email: store.data.email,
      ActiveFlag: 1,
      BusinessUnitId: 1,
      UserAccess: store.data.userAccess
    });
    this.showDeleteDialogPopup = false;  
    this.apiService.deleteUser(finalUser).subscribe(x => {      
      this.toastr.success('User Deleted Successfully');      
      store.confirm.resolve();
    },
    err => {      
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );

    this.apiService.getMasheryAccessToken().subscribe((result: any) => {
      this.userDetails = result;
      this.apiService.RemoveusersfromNDIGroup(this.userDetails.access_token,finalUser[0].SSO, finalUser[0].UserAccess).subscribe(res => {              
      },
      err => {        
        this.showErrorDialogPopup = true;          
      },
      () => {
      }
    );
    },
    err => {      
      this.showErrorDialogPopup = true;          
    },
    () => {
    }
  );
  }
         
}