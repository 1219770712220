import { Component } from '@angular/core';
import { Injectable, Inject, HostListener } from '@angular/core';
import { AuthService } from './service/auth.service';
import { Subject, Observable, Subscription } from 'rxjs';
import { authManager } from '../app/service/authManager';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import { GenredashboardResultComponent } from '../app/modules/genredashboardResult/genredashboardResult.component';
import { ExecutivereportComponent } from '../app/modules/executivereport/executivereport.component';
import { ApiService } from '../app/service/apiCall.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
@Injectable()
export class AppComponent {
  title = 'Market Flash';
  currentUser: boolean;
  userActivity;
  ticks = 0;
  private timer;
  count = 0;
  setInterval = 13800000;  
  showDialogPopup: boolean = false;
  private sub: Subscription;
  userInactive: Subject<any> = new Subject();

  public localStorageItem(id: string): string {
    return sessionStorage.getItem(id);
  }

  constructor(private authService: AuthService, private authManager: authManager, private router: Router,
   private apiservice :ApiService) {
    this.setTimeout();
    this.userInactive.subscribe(() => this.authManager.logoutUser());
  }
  ngOnInit() {
    this.currentUser = sessionStorage.getItem('id_token') != null;
    if (this.count == 0) {
      this.timer = Observable.timer(this.setInterval, 1000);
      this.sub = this.timer.subscribe(t => this.timerFunc(t));
    }   
  }

  timerFunc(tick) {    
    this.ticks = tick
    if (tick == 0 || tick == 300 || tick == 540) {               
      tick = tick;           
      let componentname = this.router.routerState.snapshot.url;      
      if(componentname == "/flashreport")
      {
        this.apiservice.change.emit();
      }      
      else{
        
      }
      this.showDialogPopup = true;
      this.count++;
    }
    if(tick == 600)
    {      
      this.authManager.logoutUser();
    }
  }  

  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), environment.idleTime * 60000);
  }

  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  @HostListener('document:mousemove', ['$event'])
  refreshUserState(event) {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
  @HostListener("window:beforeunload", ["$event"])
  clearLocalStorage(event) {
    sessionStorage.clear();
    localStorage.clear();
  }
}

